import { Component } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ReglementairesService } from 'src/app/services/reglementaires.service';

@Component({
  selector: 'app-details-reglementaire',
  templateUrl: './details-reglementaire.component.html',
  styleUrl: './details-reglementaire.component.css'
})
export class DetailsReglementaireComponent {
  reglementaire: any;
  numero: string;
  isFormModified: boolean;
  reglementaireName: any;

  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{header: 1}, {header: 2}],
      [{list: 'ordered'}, {list: 'bullet'}],
      [{script: 'sub'}, {script: 'super'}],
      [{indent: '-1'}, {indent: '+1'}],
      [{direction: 'rtl'}],
      [{size: ['small', false, 'large', 'huge']}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      [{color: []}, {background: []}],
      [{font: []}],
      [{align: []}],
      ['clean'],
      ['link', 'image', 'video']
    ]
  };

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private reglementairesService: ReglementairesService,
    private message: NzMessageService,
    private router: Router,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService
  ) {}
  ngOnInit(): void {
    this.loadingService.upLoading();
    this.numero = this.route.snapshot.params['numero'];
    this.reglementairesService
      .getReglementaireByNumero(this.numero)
      .subscribe((reglementaire: any) => {
        this.reglementaire = reglementaire;
        this.loadingService.offLoading();
      });
  }
  async confirmDelete() {
    await this.reglementairesService.delete(this.numero);
    this.createDeleteMessage();
    this.router.navigate(['/reglementaire']);
  }
  createDeleteMessage(): void {
    this.message.success('La suppression a été effectuée avec succès.', {
      nzDuration: 3000,
    });
  }
  cancel() {}

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }
}
