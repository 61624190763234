import { Component, Input, OnInit } from '@angular/core';
import { ReglementairesService } from 'src/app/services/reglementaires.service';

import { labels } from './../../../utils/fiche-labels';

@Component({
  selector: 'fiche',
  templateUrl: './details-fiche.component.html',
  styleUrl: './details-fiche.component.css',
})
export class DetailsFicheComponent implements OnInit {
  constructor(private reglementaireService: ReglementairesService) {}

  @Input('fiche') fiche: any;
  fiche_object: any;
  fiche_labels: any;
  commun_fiche_labels: any;
  fiche_cee: any;
  is_zni: boolean = false;

  async ngOnInit(): Promise<void> {
    this.fiche_object = JSON.parse(this.fiche.operation.object);
    this.fiche_labels = labels[this.fiche.op_code];
    this.commun_fiche_labels = labels['all'];
    this.reglementaireService.getReglementaireByNumero(this.fiche.op_code).subscribe((res: any) => {
      this.fiche_cee = res;
    })
    this.is_zni = this.fiche.zip.startsWith('97') || this.fiche.zip.startsWith('20');
  }
}
