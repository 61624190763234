<nz-content>
  <div class="inner-content">
    <div class="search-panel" nz-row nzJustify="space-between">
      <form [formGroup]="queryForm" [nzSpan]="24" nz-col nz-form>
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          class="main-search"
          nzSize="large"
        >
          <input
            (keyup.enter)="queryStatus()"
            [nzAutocomplete]="auto"
            class="main-search-input"
            formControlName="search_text"
            nz-input
            placeholder="Rechercher dans les statuts MPR ..."
            type="text"
          />
          <nz-autocomplete #auto>
            <nz-auto-option
              *ngFor="let statut of filteredStatutsMpr"
              [nzValue]="statut.label"
            >
              {{ statut.label }}
            </nz-auto-option>
          </nz-autocomplete>
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span (click)="queryStatus()" nz-icon nzType="search"></span>
      </ng-template>
    </div>
    <br *ngIf="isLoadingData"/>

    <app-status-table [columns]="listOfColumns" [data]="filteredStatutsMpr" [isLoadingData]="isLoadingData"
                      [statutType]="statutType"></app-status-table>
  </div>
</nz-content>
