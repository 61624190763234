import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingService } from 'src/app/services/loading.service';
import { ManagerService } from 'src/app/services/manager.service';

@Component({
  selector: 'app-edit-role-manager',
  templateUrl: './edit-management.component.html',
  styleUrl: './edit-management.component.css',
})
export class EditManagementComponent {
  editForm: FormGroup;
  manager: any;
  manager_id: number;
  isFormModified: boolean;
  managedFullName: string;
  maangerFullName: String;
  etat_management: any;
  isFormSubmitting: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private roleManagerService: ManagerService,
    private message: NzMessageService,
    private router: Router,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.manager_id = this.route.snapshot.params['id'];
    this.editForm = this.fb.group({
      manager_id: [{ disabled: true, value: '' }],
      managed_id: [{ disabled: true, value: '' }],
      etat_management: ['', Validators.required],
    });
    this.roleManagerService
      .getManagerById(this.manager_id)
      .subscribe((manager: any) => {
        this.manager = manager;
        if (manager.is_refused) {
          this.etat_management = "-1";
        } else if (manager.is_active) {
          this.etat_management = "1";
        } else {
          this.etat_management = "0";
        }
        this.managedFullName = this.manager.managed
          ? `${this.manager.managed.firstname} ${this.manager.managed.lastname}`
          : '';
        this.maangerFullName = this.manager.manager
          ? `${this.manager.manager.firstname} ${this.manager.manager.lastname}`
          : '';
        this.loadingService.offLoading();
      });
  }

  createBasicMessage(): void {
    this.message.success('Vos modifications ont été enregistrées avec succès.', {
      nzDuration: 3000,
    });
  }

  editManager() {
    if (this.editForm.valid) {
      const payload = { ...this.manager };
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });
      this.roleManagerService
        .editManager(this.manager_id, payload)
        .subscribe((updatedManager: any) => {
          this.isFormSubmitting = true;
          this.manager = updatedManager;
          this.createBasicMessage();
          this.goBack();
        });
    } else {
      console.log('Form is invalid');
    }
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    return !this.editForm.pristine;
  }

  goBack() {
    this.router.navigate(['/managers']);
  }
}
