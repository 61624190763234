<div class="header-container">
  <div class="user-info">
    <div class="titre">{{ data.titre }}</div>
    <div class="user-name">
      <a routerLink="/utilisateurs/{{ data.user.id }}">{{
        data.user.firstname + " " + data.user.lastname
      }}</a>
    </div>
  </div>
</div>
