import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { ModalService } from '../services/modal.service';

export interface ComponentCanDeactivate {
  checkFormChanges(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EditGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private modalService: ModalService) { }

  canDeactivate(
    component: ComponentCanDeactivate
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!component.checkFormChanges()) {
      return true;
    }

    return this.modalService.showConfirmModal(
      'Attention',
      'Vos changements ne sont pas enregistrés et les informations rentrées seront perdues, Voulez-vous vraiment quitter cette page ?'
    );
  }
}