import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminsService {
  constructor(private http: HttpClient) {}

  getAllAdmins(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/admins/page=${page}&limit=${limit}`,
      query
    );
  }

  getAllCommercials() {
    return this.http.post(`${environment.apiUrl}/admins/page=1&limit=100000`, {
      role: ['ROLE_CHARGE_AFFAIRES'],
    });
  }

  getAllControleurs() {
    return this.http.post(`${environment.apiUrl}/admins/page=1&limit=100000`, {
      role: ['ROLE_CONTROLEUR_CEE', 'ROLE_CONTROLEUR_CEE_MPR'],
    });
  }

  getAllAdminsOrderedById(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/admins/ordered/page=${page}&limit=${limit}`,
      query
    );
  }

  getAdminById(id: number) {
    return this.http.get(`${environment.apiUrl}/admins/${id}`);
  }

  editAdmin(id: number, payload: any) {
    return this.http.put(`${environment.apiUrl}/admins/${id}`, payload);
  }

  createAdmin(payload: any) {
    return this.http.post(`${environment.apiUrl}/admins/create`, payload);
  }

  deleteAdmin(admin_id: any) {
    return this.http.delete(`${environment.apiUrl}/admins/${admin_id}`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  exportAllAdmins(query: any) {
    return this.http.post(`${environment.apiUrl}/admins/export-all`, query, {
      responseType: 'blob',
    });
  }

  exportAdmins(admins_ids: number[]) {
    return this.http.post(
      `${environment.apiUrl}/admins/export`,
      { admins_ids },
      { responseType: 'blob' }
    );
  }

  deleteManyAdmins(ids: number[]) {
    return this.http
      .post(`${environment.apiUrl}/admins/bulk-delete`, { ids })
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }
}
