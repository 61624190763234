import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { debounceTime } from 'rxjs/operators';
import { AdminsService } from 'src/app/services/admins.service';
import { AuthService } from 'src/app/services/auth.service';

import { DepartementService } from '../../../services/departement.service';
import { listOfColumnsGestionCommerciale } from './data/data';

@Component({
  selector: 'app-list-gestion-commerciale',
  templateUrl: './list-gestion-commerciale.component.html',
  styleUrls: ['./list-gestion-commerciale.component.css'],
})
export class ListGestionCommercialeComponent implements OnInit {
  queryForm: FormGroup;
  listOfColumns = listOfColumnsGestionCommerciale;
  toogleFilters: boolean = false;
  isLoadingData: boolean = false;
  gestions: any;
  pageIndex = 1;
  pageSize = 20;
  totalGestions: number = 0;
  queryFormvalue : any = null;
  admins: any[];

  selected_resp_res_ind: string[] = [];
  selected_resp_res_coll: string[] = [];
  selected_resp_tertiaire: string[] = [];
  selected_resp_industrie: string[] = [];
  selected_resp_transport: string[] = [];
  selected_resp_agriculture: string[] = [];
  selected_resp_reseaux: string[] = [];
  nzShowArrow = true;

  searchedGestions: any[] = [];

  constructor(
    private fb: FormBuilder,
    private departService: DepartementService,
    private router: Router,
    private readonly authService: AuthService,
    private adminsService: AdminsService
  ) {
    this.queryForm = this.fb.group({
      search_text: [''],
      code: [''],
      nom: [''],
      responsable_residentiel_individuel: [''],
      responsable_residentiel_collectif: [''],
      responsable_tertiaire: [''],
      responsable_industrie: [''],
      responsable_transport: [''],
      responsable_agriculture: [''],
      responsable_reseaux: [''],
    });
  }

  ngOnInit(): void {
    this.queryData();
    this.loadAdmins();
  }

  private loadAdmins() {
    this.adminsService
      .getAllAdmins(1, 100000, {})
      .subscribe((admins: any) => {
        this.admins = admins.rows;
      });
  }

  queryGestion(): void {
    this.isLoadingData = true;
    this.queryData();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.departService
      .getDepartementsByQuery(
        this.pageIndex,
        this.pageSize,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.gestions = data.rows;
        this.totalGestions = data.count;
        this.isLoadingData = false;
        
      });
  }

  resetForm(): void {
    this.queryForm.reset();
    this.selected_resp_agriculture = [];
    this.selected_resp_res_coll = [];
    this.selected_resp_res_ind = [];
    this.selected_resp_industrie = [];
    this.selected_resp_reseaux = [];
    this.selected_resp_tertiaire = [];
    this.selected_resp_transport = [];
    this.queryData();
  }


  editGestion(code: any) {
    this.router.navigate([`/gestion-commerciale/edit/${code}`]).then((r) => r);
  }

  
  queryData() {
    this.isLoadingData = true;
    if (this.queryFormvalue !== this.queryForm.value) {
      this.queryFormvalue = this.queryForm.value;
      this.pageIndex = 1;
    }
    this.departService
      .getDepartementsByQuery(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.gestions = data.rows;
        this.totalGestions = data.count;
        this.isLoadingData = false;
      });
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }
}
