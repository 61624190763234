<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form nz-form [formGroup]="queryForm" nz-col [nzSpan]="16">
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Rechercher dans les discussions ..."
            (keyup.enter)="queryChats()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search" (click)="queryChats()"></span>
      </ng-template>
      <div class="filter-container" nz-col [nzSpan]="5">
        <button
          nz-button
          nzType="primary"
          class="filters-toogle prix-button"
          routerLink="/messagerie/new"
        >
          <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
          Nouvelle discussion
        </button>
      </div>
      <div class="filter-container" nz-col [nzSpan]="3">
        <button
          nz-button
          nzType="default"
          class="filters-toogle"
          (click)="toggleFilters = !toggleFilters"
        >
          <span nz-icon nzType="filter"></span>
          Filtres
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || chats.length === 0" />
    <div *ngIf="toggleFilters" class="filters-panel">
      <form nz-form [formGroup]="queryForm" class="ant-advanced-search-form">
        <div nz-row>
          <div nz-col nzSpan="20" nzSm="20" nzXs="24">
            <div nz-row class="filter-fields">
              <div nz-col [nzSpan]="5" nzSm="5" nzXs="24">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      nzSize="small"
                      nzShowSearch
                      formControlName="utilisateur"
                      nzPlaceHolder="Utilisateur"
                    >
                      <nz-option
                        *ngFor="let user of users"
                        [nzValue]="user.id"
                        [nzLabel]="user.firstname + ' ' + user.lastname"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5" nzSm="5" nzXs="24">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [nzDisabled]="projets.length === 0"
                      class="custom-filter-field"
                      nzPlaceHolder="projet"
                      formControlName="projet"
                      nzSize="small"
                      nzShowSearch
                    >
                      <nz-option
                        *ngFor="let projet of projets"
                        [nzValue]="projet.id"
                        [nzLabel]="projet.name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div nz-col [nzSpan]="4" nzSm="4" nzXs="24" nzJustify="center">
            <div nz-row nzJustify="center">
              <button
                nz-button
                nzBlock
                nzType="default"
                nzShape="round"
                nzSize="small"
                class="filter-button blue-grey-button-color"
                (click)="resetForm()"
              >
                Effacer
              </button>
            </div>
            <div nz-row nzJustify="center" nzXs="24">
              <button
                nz-button
                nzBlock
                nzType="primary"
                nzShape="round"
                nzSize="small"
                (click)="queryChats()"
                class="filter-button"
              >
                Appliquer
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <nz-table
      #fixedTable
      [nzData]="chats"
      [nzFrontPagination]="false"
      [nzTotal]="totalChats"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      nzPaginationPosition="both"
      [nzShowTotal]="totalDiscussions_template"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzLoading]="isLoadingData"
      class="discussion-list-table"
    >
      <ng-template #totalDiscussions_template>
        <button
          *ngIf="checkedChats.size > 0 && canDelete('MESSAGERIE')"
          nz-button
          nz-popconfirm
          nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement toutes les discussions sélectionnées ?"
          nzType="default"
          class="secondary-button mass-action-button"
          (nzOnConfirm)="confirmDelete()"
          (nzOnCancel)="cancel()"
          [nzIcon]="popConfirmIcon"
        >
          <span nz-icon nzType="delete"></span>
          Supprimer
        </button>
        <span>{{ totalChats }} {{ totalChats == 1 ? ' discussion' : ' discussions' }}</span>
        <ng-template #popConfirmIcon> </ng-template>
      </ng-template>

      <thead class="list-discussions-head">
        <tr>
          <th
            *ngIf="canDelete('MESSAGERIE')"
            nzWidth="30px"
            [(nzChecked)]="allChecked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th>Titre</th>
          <th>Utilisateur</th>
          <th>Aperçu du dernier message</th>
          <th>Dernier message</th>
          <th nzAlign="center">Vu ?</th>
          <th nzRight nzWidth="90px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data">
          <td
            *ngIf="canDelete('MESSAGERIE')"
            [nzChecked]="checkedChats.has(data.id)"
            (nzCheckedChange)="onItemChecked(data.id, $event)"
          ></td>
          <td>
            <a routerLink="/messagerie/{{ data.id }}">{{ data.titre }}</a>
          </td>
          <td>
            <a
              *ngIf="data.user"
              routerLink="/utilisateurs/{{ data.user.id }}"
              >{{ data.user.firstname }} {{ data.user.lastname }}</a
            >
          </td>
          <td>{{ data.messages[0]?.contenu }}</td>
          <td>{{ data.messages[0]?.createdAt | date : "dd/MM/YYYY HH:mm" }}</td>
          <td nzAlign="center">
            <nz-tag *ngIf="data.vu" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!data.vu" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </td>
          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="action-buttons"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item routerLink="/messagerie/{{ data.id }}">
                  <a
                    ><span nz-icon nzType="comment" nzTheme="outline"></span>
                    Discuter</a
                  >
                </li>
                <li
                  nz-menu-item
                  nz-popconfirm
                  nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement la discussion sélectionnée ?"
                  (nzOnConfirm)="deleteChat(data.id)"
                  (nzOnCancel)="cancel()"
                  *ngIf="canDelete('MESSAGERIE')"
                >
                  <a
                    ><span nz-icon nzType="delete" nzTheme="outline"></span>
                    Supprimer</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
