<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit></ng-template>
  <div class="form-container">
    <div class="title">Modification du Bandeau</div>
    <form
      [formGroup]="editForm"
      class="form-layout"
      nz-form
    >
      <div class="row-content" nz-row>
        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="4" nz-col nzLabelWrap nzRequired
              >Intitulé</nz-form-label
            >
            <nz-form-control
              [nzSpan]="17"
              nz-col
              nzErrorTip="Intitulé est obligatoire"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input formControlName="label" nz-input />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="4" nz-col nzLabelWrap nzRequired
              >Client</nz-form-label
            >
            <nz-form-control
              [nzSpan]="17"
              nz-col
              nzErrorTip="Le client est obligatoire"
            >
              <nz-select
                formControlName="plateforme"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  *ngFor="let client of clients"
                  [nzValue]="client.tag"
                  [nzLabel]="client.tag"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="4" nz-col nzLabelWrap nzRequired
              >Contenu</nz-form-label
            >
            <nz-form-control
              [nzSpan]="17"
              nz-col
              nzErrorTip="Le contenu du bandeau est obligatoire"
            >
              <nz-textarea-count [nzMaxCharacterCount]="260" class="edit-field">
                <textarea
                  formControlName="content"
                  nz-input
                  maxlength="260"
                ></textarea>
              </nz-textarea-count>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="4" nz-col nzLabelWrap nzRequired
              >Date de début</nz-form-label
            >
            <nz-form-control
              [nzSpan]="17"
              nz-col
              nzErrorTip="Date de début est obligatoire"
            >
              <nz-date-picker
                [nzFormat]="'dd/MM/yyyy HH:mm:ss'"
                formControlName="start_date"
                nzShowTime
                nzAllowClear="false"
                class="default-border-radius"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="4" nz-col nzLabelWrap nzRequired
              >Date de fin</nz-form-label
            >
            <nz-form-control
              [nzSpan]="17"
              nz-col
              nzErrorTip="Date de fin est obligatoire"
            >
              <nz-date-picker
                [nzFormat]="'dd/MM/yyyy HH:mm:ss'"
                formControlName="end_date"
                nzShowTime
                nzAllowClear="false"
                class="default-border-radius"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="4" nz-col nzLabelWrap nzRequired
              >Actif</nz-form-label
            >
            <nz-form-control
              [nzSpan]="17"
              nz-col
              nzErrorTip="Actif est obligatoire"
            >
              <nz-switch formControlName="is_active"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            [disabled]="editForm.invalid || !editForm.dirty"
            class="circle-button small save-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Sauvegarder"
            (click)="editBandeau()"
          >
            <span class="icon">
              <span nz-icon nzType="save"></span>
            </span>
          </button>
          <button
            (click)="goBack()"
            class="circle-button small cancel-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Annuler"
          >
            <span class="icon">
              <span nz-icon nzType="rollback"></span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
