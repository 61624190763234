<nz-content>
  <div class="inner-content">
    <div class="search-panel" nz-row nzJustify="space-between">
      <form [formGroup]="queryForm" [nzSpan]="24" nz-col nz-form>
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          class="main-search"
          nzSize="large"
        >
          <input
            (keyup.enter)="querylogs()"
            class="main-search-input"
            formControlName="search_text"
            nz-input
            placeholder="Rechercher dans la journalisation ..."
            type="text"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span (click)="querylogs()" nz-icon nzType="search"></span>
      </ng-template>
    </div>
    <br *ngIf="isLoadingData || projectlogs.length === 0"/>

    <nz-table
      #fixedTable
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzData]="projectlogs"
      [nzFrontPagination]="false"
      [nzLoading]="isLoadingData"
      [nzPageIndex]="pageIndex"
      [nzPageSize]="pageSize"
      [nzShowTotal]="total_logs_template"
      [nzTotal]="total_logs"
      class="log-list-table"
      nzPaginationPosition="both"
    >
      <ng-template #total_logs_template>
        <span>{{ total_logs }} {{ total_logs == 1 ? 'action' : 'actions'}}</span>
        <ng-template #popConfirmIcon></ng-template>
      </ng-template>

      <thead class="log-list-head">
      <tr>
        <th nzAlign="center">Projet</th>
        <th nzAlign="center">Utilisateur</th>
        <th nzAlign="center">Action</th>
        <th nzAlign="center">Date</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of fixedTable.data">
        <td nzAlign="center"><a routerLink="/projets/{{data.project.id}}">{{ data.project?.numeroDossier }}
          - {{ data.project?.name }}</a></td>
        <td nzAlign="center"><a
          routerLink="/utilisateurs/{{data.action_user.id}}">{{ data.action_user?.firstname }} {{ data.action_user?.lastname }}</a>
        </td>
        <td nzAlign="center">{{ data.action }}</td>
        <td nzAlign="center">{{ data.date | date : "dd/MM/YYYY" }}</td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
