import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NeoscopeService {
  constructor(private http: HttpClient) {}

  getAllClientsNeoscope(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/clients-neoscope/page=${page}&limit=${limit}`,
      query
    );
  }

  getAllClientsNeoscopeSync(page: number, limit: number, query: any) {
    return firstValueFrom(
      this.http.post(
        `${environment.apiUrl}/clients-neoscope/page=${page}&limit=${limit}`,
        query
      )
    );
  }

  getClientsNeoscopeByIDs(data: number) {
    return this.http.post(
      `${environment.apiUrl}/clients-neoscope/get-many-by-ids`,
      data
    );
  }

  getClientNeoscope(client_id: number) {
    return this.http.get(
      `${environment.apiUrl}/clients-neoscope/client_id=${client_id}`
    );
  }

  getClientsNeoscopeConfiguration(client_id: number) {
    return this.http.get(
      `${environment.apiUrl}/clients-neoscope/configuration/client_id=${client_id}`
    );
  }

  editNeoscopeConfiguration(client_id: number, config: any) {
    return this.http.put(
      `${environment.apiUrl}/clients-neoscope/configuration/edit/client_id=${client_id}`,
      config
    );
  }

  editNeoscopeClient(client_id: number, query: any) {
    return this.http.put(
      `${environment.apiUrl}/clients-neoscope/edit/client_id=${client_id}`,
      query
    );
  }
}
