import {Component} from '@angular/core';

@Component({
  selector: 'app-channel-members',
  templateUrl: './channel-members.component.html',
  styleUrl: './channel-members.component.css'
})
export class ChannelMembersComponent {
  listData = [
    {
      firstName: "John",
      lastName: "Doe",
    }, {
      firstName: "Jane",
      lastName: "Doe",
    }, {
      firstName: "John",
      lastName: "Smith",
    }, {
      firstName: "Jane",
      lastName: "Smith",
    }

  ]

}
