<nz-layout class="app-layout">
  <app-sidebar></app-sidebar>
  <nz-layout
    [ngClass]="
      sidebarService.isCollapsed ? 'collapsed-sidebar' : 'uncollapsed-sidebar'
    "
    class="app-main-component"
  >
    <div class="loader-container" *ngIf="loadingService.loading">
      <nz-spin [nzSize]="'large'" nzTip="Chargement..."></nz-spin>
    </div>
    <router-outlet></router-outlet>
  </nz-layout>
</nz-layout>
