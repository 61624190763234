import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-details-user',
  templateUrl: './details-user.component.html',
  styleUrl: './details-user.component.css',
})
export class DetailsUserComponent implements OnInit {
  user: any;
  user_id: any;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private readonly authService: AuthService,
    private router: Router,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadingService.upLoading();
    this.user_id = this.route.snapshot.params['id'];
    this.usersService.getUserById(this.user_id).subscribe((user: any) => {
      this.authService.canAccessClientData(user.source);
      this.user = user;
      this.loadingService.offLoading();
    });
  }
  getStatus() {
    switch (this.user.is_active) {
      case 0:
        return {
          color: 'gold',
          text: 'En attente',
        };

      case 1:
        return {
          color: 'green',
          text: 'Actif',
        };
      default:
        return {
          color: 'red',
          text: 'Refusé',
        };
    }
  }
  async confirmDelete(): Promise<void> {
    if (this.user) {
      await this.usersService.delete(this.user_id);
    }
    this.router.navigate(['/users']);
  }
  cancel(): void {}

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }
}
