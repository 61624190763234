import { Component, Input } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-channel-files',
  templateUrl: './channel-files.component.html',
  styleUrl: './channel-files.component.css'
})
export class ChannelFilesComponent {
  @Input() messages: any[] = [];

  constructor(
    private chatService: ChatService
  ) { }

  get fileCount(): number {
    return this.messages.filter(message => message.filename).length;
  }

  download(file: any) {
    this.chatService
      .downloadFile(JSON.parse(file).s3filename)
      .subscribe((data) => {
        importedSaveAs(data, JSON.parse(file).filename);
      });
  }

  extractName(file: any) {
    const filename = JSON.parse(file).filename;
    return filename;
  }

  extractUrl(file: any) {
    return JSON.parse(file).s3filename;
  }
  extractFileType(file: any): string {
    const filename = this.extractName(file);
    const extension = filename.split('.').pop().toLowerCase();

    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'doc':
      case 'docx':
        return 'doc';
      case 'xls':
      case 'xlsx':
        return 'xls';
      case 'ppt':
      case 'pptx':
        return 'ppt';
      default:
        return 'default';
    }
  }
}
