import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard } from './guards/auth.guard';
import { EditGuard } from './guards/edit.guard';
import { guestGuard } from './guards/guest.guard';
import { RoleGuard } from './guards/role.guard';
import { AccessDeniedComponent } from './layout/access-denied/access-denied.component';
import { AuthenticatedLayoutComponent } from './layout/authenticatedLayout/authenticated-layout/authenticated-layout.component';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { UnauthenticatedLayoutComponent } from './layout/unauthenticatedLayout/unauthenticated-layout/unauthenticated-layout.component';
import { CreateAdminComponent } from './pages/admins/create-admin/create-admin.component';
import { DetailsAdminComponent } from './pages/admins/details-admin/details-admin.component';
import { EditAdminComponent } from './pages/admins/edit-admin/edit-admin.component';
import { ListAdminsComponent } from './pages/admins/list-admins/list-admins.component';
import { ForgetPasswordComponent } from './pages/auth/forget-password/forget-password.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { TwoFactAuthComponent } from './pages/auth/two-fact-auth/two-fact-auth.component';
import { EditBandeauInfoComponent } from './pages/bandeau-info/edit-bandeau-info/edit-bandeau-info.component';
import { ListBandeauInfoComponent } from './pages/bandeau-info/list-bandeau-info/list-bandeau-info.component';
import { DetailsBeneficiaireComponent } from './pages/beneficiaires/details-beneficiaire/details-beneficiaire.component';
import { EditBeneficiaireComponent } from './pages/beneficiaires/edit-beneficiaire/edit-beneficiaire.component';
import { ListBeneficiairesComponent } from './pages/beneficiaires/list-beneficiaires/list-beneficiaires.component';
import { CreateCategorieComponent } from './pages/categories-prix/create-categorie/create-categorie.component';
import { DetailsPrixComponent } from './pages/categories-prix/details-prix/details-prix.component';
import { EditPrixComponent } from './pages/categories-prix/edit-prix/edit-prix.component';
import { ListPrixComponent } from './pages/categories-prix/list-prix/list-prix.component';
import { DetailsClientComponent } from './pages/clients-neoscope/details-client/details-client.component';
import { EditClientComponent } from './pages/clients-neoscope/edit-client/edit-client.component';
import { ListClientsComponent } from './pages/clients-neoscope/list-clients/list-clients.component';
import { AdminDashboardComponent } from './pages/dashboard/admin/admin.component';
import { EditGestionCommercialeComponent } from './pages/gestion-commerciale/edit-gestion-commerciale/edit-gestion-commerciale.component';
import { ListGestionCommercialeComponent } from './pages/gestion-commerciale/list-gestion-commerciale/list-gestion-commerciale.component';
import { ListJournalisationComponent } from './pages/journalisation/list-journalisation/list-journalisation.component';
import { ListMesProjetsComponent } from './pages/mes-projets/list-mes-projets/list-mes-projets.component';
import { ChatComponent } from './pages/messagerie/chat/chat.component';
import { CreateChatComponent } from './pages/messagerie/create-chat/create-chat.component';
import { ListDiscussionsComponent } from './pages/messagerie/list-discussions/list-discussions.component';
import { CreateObligesComponent } from './pages/obliges/create-obliges/create-obliges.component';
import { DetailsObligeComponent } from './pages/obliges/details-oblige/details-oblige.component';
import { EditObligesComponent } from './pages/obliges/edit-obliges/edit-obliges.component';
import { ListObligesComponent } from './pages/obliges/list-obliges/list-obliges.component';
import { PersonnalisationComponent } from './pages/personnalisation/personnalisation.component';
import { EditProfileComponent } from './pages/profile/edit-profile/edit-profile.component';
import { CorbeilleProjetsComponent } from './pages/projets/corbeille-projets/corbeille-projets.component';
import { DetailsProjetComponent } from './pages/projets/details-projet/details-projet.component';
import { EditProjetComponent } from './pages/projets/edit-projet/edit-projet.component';
import { ListProjetsComponent } from './pages/projets/list-projets/list-projets.component';
import { CreateReglementaireComponent } from './pages/reglementaires/create-reglementaire/create-reglementaire.component';
import { DetailsReglementaireComponent } from './pages/reglementaires/details-reglementaire/details-reglementaire.component';
import { EditReglementairesComponent } from './pages/reglementaires/edit-reglementaires/edit-reglementaires.component';
import { ListReglementairesComponent } from './pages/reglementaires/list-reglementaires/list-reglementaires.component';
import { CreateManagementComponent } from './pages/role-manager/create-management/create-management.component';
import { EditManagementComponent } from './pages/role-manager/edit-management/edit-management.component';
import { ListRoleManagersComponent } from './pages/role-manager/list-role-manager/list-role-manager.component';
import { CreateSocieteComponent } from './pages/societes/create-societe/create-societe.component';
import { DetailsSocieteComponent } from './pages/societes/details-societe/details-societe.component';
import { EditSocieteComponent } from './pages/societes/edit-societe/edit-societe.component';
import { ListSocietesComponent } from './pages/societes/list-societes/list-societes.component';
import { EditStatutsCeeComponent } from './pages/statuts-cee/edit-statuts-cee/edit-statuts-cee.component';
import { ListStatutsCeeComponent } from './pages/statuts-cee/list-statuts-cee/list-statuts-cee.component';
import { EditStatutsMprComponent } from './pages/statuts-mpr/edit-statuts-mpr/edit-statuts-mpr.component';
import { ListStatutsMprComponent } from './pages/statuts-mpr/list-statuts-mpr/list-statuts-mpr.component';
import { DetailsUserComponent } from './pages/users/details-user/details-user.component';
import { EditUsersComponent } from './pages/users/edit-users/edit-users.component';
import { ListUsersComponent } from './pages/users/list-users/list-users.component';
import { DetailsZohoBugsComponent } from './pages/zoho-bugs/details-zoho-bugs/details-zoho-bugs.component';
import { ListZohoBugsComponent } from './pages/zoho-bugs/list-zoho-bugs/list-zoho-bugs.component';

const routes: Routes = [
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'projets',
        children: [
          {
            path: '',
            component: ListProjetsComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
          {
            path: ':id',
            component: DetailsProjetComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
          {
            path: 'edit/:id',
            component: EditProjetComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
        ],
      },
      {
        path: 'mes-projets',
        children: [
          {
            path: '',
            component: ListMesProjetsComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
        ],
      },
      {
        path: 'societes',
        children: [
          {
            path: '',
            component: ListSocietesComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
          {
            path: 'create',
            component: CreateSocieteComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_CHARGE_AFFAIRES'],
            },
          },
          {
            path: 'edit/:id',
            component: EditSocieteComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
          {
            path: ':id',
            component: DetailsSocieteComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
        ],
      },
      {
        path: 'utilisateurs',
        children: [
          {
            path: '',
            component: ListUsersComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
          {
            path: ':id',
            component: DetailsUserComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
          {
            path: 'edit/:id',
            component: EditUsersComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
        ],
      },
      {
        path: 'statuts-cee',
        children: [
          {
            path: '',
            component: ListStatutsCeeComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            },
          },
          {
            path: 'edit/:id',
            component: EditStatutsCeeComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
        ],
      },
      {
        path: 'statuts-mpr',
        children: [
          {
            path: '',
            component: ListStatutsMprComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            },
          },
          {
            path: 'edit/:id',
            component: EditStatutsMprComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
        ],
      },
      {
        path: 'bandeau-info',
        children: [
          {
            path: '',
            component: ListBandeauInfoComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
          {
            path: 'edit/:id',
            component: EditBandeauInfoComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
        ],
      },
      {
        path: 'categories-prix',
        children: [
          {
            path: '',
            component: ListPrixComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_CHARGE_AFFAIRES'],
            },
          },
          {
            path: 'new',
            component: CreateCategorieComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            },
          },
          {
            path: ':code',
            component: DetailsPrixComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_CHARGE_AFFAIRES'],
            },
          },
          {
            path: 'edit/:code',
            component: EditPrixComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            },
          },
        ],
      },
      {
        path: 'gestion-commerciale',
        children: [
          {
            path: '',
            component: ListGestionCommercialeComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_CHARGE_AFFAIRES'],
            },
          },
          {
            path: 'edit/:id',
            component: EditGestionCommercialeComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            },
          },
        ],
      },
      {
        path: 'obliges',
        children: [
          {
            path: '',
            component: ListObligesComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
          {
            path: 'create',
            component: CreateObligesComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
          {
            path: 'edit/:id',
            component: EditObligesComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
          {
            path: ':id',
            component: DetailsObligeComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
        ],
      },
      {
        path: 'reglementaire',
        children: [
          {
            path: '',
            component: ListReglementairesComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
          {
            path: 'create',
            component: CreateReglementaireComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
          {
            path: 'edit/:numero',
            component: EditReglementairesComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
          {
            path: ':numero',
            component: DetailsReglementaireComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },

        ],
      },
      {
        path: 'managers',
        children: [
          {
            path: '',
            component: ListRoleManagersComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_CHARGE_AFFAIRES'],
            },
          },
          {
            path: 'edit/:id',
            component: EditManagementComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_CHARGE_AFFAIRES'],
            },
          },
          {
            path: 'create',
            component: CreateManagementComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_CHARGE_AFFAIRES'],
            },
          },
        ],
      },
      {
        path: 'bugs-zoho',
        children: [
          {
            path: '',
            component: ListZohoBugsComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
          {
            path: ':id',
            component: DetailsZohoBugsComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
        ],
      },
      {
        path: 'beneficiaires',
        children: [
          {
            path: '',
            component: ListBeneficiairesComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
          {
            path: ':id',
            component: DetailsBeneficiaireComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
          {
            path: 'edit/:id',
            component: EditBeneficiaireComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
                'ROLE_OBSERVATEUR',
              ],
            },
          },
        ],
      },
      {
        path: 'corbeille',
        component: CorbeilleProjetsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: [
            'ROLE_SUPER_ADMIN',
            'ROLE_ADMIN',
            'ROLE_CHARGE_AFFAIRES',
            'ROLE_CONTROLEUR_CEE',
            'ROLE_CONTROLEUR_CEE_MPR',
            'ROLE_OBSERVATEUR',
          ],
        },
      },
      {
        path: 'administrateurs',
        children: [
          {
            path: '',
            component: ListAdminsComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            },
          },
          {
            path: 'new',
            component: CreateAdminComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            },
          },
          {
            path: 'edit/:id',
            component: EditAdminComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            },
          },
          {
            path: ':id',
            component: DetailsAdminComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            },
          },
          
        ],
      },
      {
        path: 'clients-neoscope',
        children: [
          {
            path: '',
            component: ListClientsComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
          {
            path: 'details/:id',
            component: DetailsClientComponent,
            canActivate: [RoleGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
          {
            path: 'edit/:id',
            component: EditClientComponent,
            canActivate: [RoleGuard],
            canDeactivate: [EditGuard],
            data: {
              roles: ['ROLE_SUPER_ADMIN'],
            },
          },
        ],
      },
      {
        path: 'messagerie',
        children: [
          {
            path: '',
            component: ListDiscussionsComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
          {
            path: 'new',
            component: CreateChatComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
          {
            path: ':id',
            component: ChatComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                'ROLE_SUPER_ADMIN',
                'ROLE_ADMIN',
                'ROLE_CHARGE_AFFAIRES',
                'ROLE_CONTROLEUR_CEE',
                'ROLE_CONTROLEUR_CEE_MPR',
              ],
            },
          },
        ],
      },
      {
        path: 'personnaliser-neoscope',
        component: PersonnalisationComponent,
        canActivate: [RoleGuard],
        canDeactivate: [EditGuard],
        data: {
          roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
        },
      },
      {
        path: 'admin/dashboard',
        component: AdminDashboardComponent,
        canActivate: [RoleGuard],
        data: {
          roles: [
            'ROLE_SUPER_ADMIN',
            'ROLE_ADMIN',
            'ROLE_CHARGE_AFFAIRES',
            'ROLE_CONTROLEUR_CEE',
            'ROLE_CONTROLEUR_CEE_MPR',
            'ROLE_OBSERVATEUR',
          ],
        },
      },
      {
        path: 'journalisation',
        component: ListJournalisationComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMIN'],
        },
      },
      {
        path:'profile',
        canDeactivate: [EditGuard],
        component: EditProfileComponent,
      },
      { path: 'access-denied', component: AccessDeniedComponent },
      { path: '', pathMatch: 'full', redirectTo: '/projets' },
    ],
  },
  {
    path: '',
    component: UnauthenticatedLayoutComponent,
    canActivate: [guestGuard],
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'forget-password', component: ForgetPasswordComponent },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
      { path: 'verifyCode', component: TwoFactAuthComponent },
      { path: '**', component: NotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
