import {Component, OnInit} from '@angular/core';

import {SidebarService} from './services/sidebar.service';
import {NavigationEnd, Router} from "@angular/router";
import {AuthService} from "./services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor() {
  }

}
