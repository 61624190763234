import {Component} from '@angular/core';
import {FormControl, FormGroup, FormRecord, NonNullableFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ObligesService} from 'src/app/services/obliges.service';

@Component({
  selector: 'app-create-obliges',
  templateUrl: './create-obliges.component.html',
  styleUrl: './create-obliges.component.css'
})
export class CreateObligesComponent {
  createForm: FormGroup;
  oblige: any;
  isFormModified: boolean;
  validateForm: FormRecord<FormControl<string>> = this.fb.record({});

  controlArray: Array<{ index: number; show: boolean }> = [];
  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{header: 1}, {header: 2}],
      [{list: 'ordered'}, {list: 'bullet'}],
      [{script: 'sub'}, {script: 'super'}],
      [{indent: '-1'}, {indent: '+1'}],
      [{direction: 'rtl'}],
      [{size: ['small', false, 'large', 'huge']}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      [{color: []}, {background: []}],
      [{font: []}],
      [{align: []}],
      ['clean'],
      ['link', 'image', 'video']
    ]
  };

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private obligeService: ObligesService,
    private message: NzMessageService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      nom: ['', [Validators.required, Validators.maxLength(10)]],
      nomOfficiel: ['', [Validators.required, Validators.maxLength(30)]],
      mentions: ['']
    });
  }

  createBasicMessage(): void {
    this.message.success('La création a été effectuée avec succès.', {
      nzDuration: 3000
    });
  }

  createOblige() {
    if (this.createForm.valid) {
      const payload = {...this.oblige};
      Object.keys(this.createForm.controls).forEach((key) => {
        if (this.createForm.controls[key]) {
          payload[key] = this.createForm.controls[key].value;
        }
      });
      this.obligeService
        .createOblige(payload)
        .subscribe((updatedOblige: any) => {
          this.oblige = updatedOblige;
          this.createBasicMessage();
          this.createForm.markAsPristine();
          this.router.navigate(['/obliges']);
        });
    } else {
      console.log("invalid Form");
      Object.values(this.createForm.controls).forEach(control => {
        if (control.invalid) {
          const payload = {...this.oblige};
          Object.keys(this.createForm.controls).forEach((key) => {
            if (this.createForm.controls[key]) {
              payload[key] = this.createForm.controls[key].value;
            }
          });
          console.log('payload :', payload);
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  checkFormChanges(): boolean {
    this.isFormModified = !this.createForm.pristine;
    return this.isFormModified;
  }

  goBack() {
    this.router.navigate(['/obliges']);
  }
}
