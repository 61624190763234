<div class="edit-statuts-container">
  <form [formGroup]="editForm" nz-form>
    <nz-form-item>
      <nz-form-label [nzSpan]="6" class="label" nzRequired>Intitulé</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <input class="main-search-input" formControlName="intitule" nz-input placeholder="Saisir l'intitulé du statut"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-control [nzOffset]="6" [nzSpan]="14">
      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            [disabled]="editForm.invalid || !editForm.dirty"
            class="circle-button small save-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Sauvegarder"
            (click)="onSubmit(id)"
          >
          <span class="icon">
            <span nz-icon nzType="save"></span>
          </span>
          </button>
          <button
            (click)="onRetour()"
            class="circle-button small cancel-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Annuler"
          >
          <span class="icon">
            <span nz-icon nzType="rollback"></span>
          </span>
          </button>
        </div>
      </div>

    </nz-form-control>
  </form>
</div>
