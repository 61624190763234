import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modal: NzModalService) {}

  showConfirmModal(title: string, content: string): Observable<boolean> {
    return new Observable(subscriber => {
      this.modal.confirm({
        nzTitle: title,
        nzContent: content,
        nzOnOk: () => {
          subscriber.next(true);
          subscriber.complete();
        },
        nzOnCancel: () => {
          subscriber.next(false);
          subscriber.complete();
        }
      });
    });
  }

}
