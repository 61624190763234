import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReglementairesService {
  constructor(private http: HttpClient) {}

  getAllReglementairesFilter(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/reglementaires/all/page=${page}&limit=${limit}`,
      query
    );
  }

  getReglementaireByNumero(numero: string) {
    return this.http.get(
      `${environment.apiUrl}/reglementaires/infobulle/${numero}`
    );
  }

  editReglementaire(numero: string, payload: any) {
    return this.http.put(
      `${environment.apiUrl}/reglementaires/${numero}`,
      payload
    );
  }

  delete(reglementaire_num: string) {
    return firstValueFrom(
      this.http.delete(
        `${environment.apiUrl}/reglementaires/${reglementaire_num}`
      )
    );
  }

  createReglementaire(payload: any) {
    return this.http.post(`${environment.apiUrl}/reglementaires`, payload);
  }
}
