import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BeneficiairesService {
  constructor(private http: HttpClient) { }

  GetBeneficiairebyId(id: number) {
    return this.http.get(
      `${environment.apiUrl}/beneficiaires/${id}`
    );
  }

  getBeneficiairesWithFilter(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/beneficiaires/page=${page}&limit=${limit}`,
      query
    );
  }

  getBeneficiaireById(beneficiaire_id: number) {
    return this.http.get(
      `${environment.apiUrl}/beneficiaires/${beneficiaire_id}`
    );
  }
  editBeneficiaire(beneficiaire_id: number, payload: any) {
    return this.http.put(
      `${environment.apiUrl}/beneficiaires/${beneficiaire_id}`, payload
    );
  }

  delete(beneficiaire_id: number) {
    return firstValueFrom(
      this.http.delete(`${environment.apiUrl}/beneficiaires/${beneficiaire_id}`)
    );
  }
  
  exportBeneficiaires(beneficiaire_ids: number[]) {
    return this.http.post(
      `${environment.apiUrl}/beneficiaires/export`,
      { ids: beneficiaire_ids },
      { responseType: 'blob' }
    );
  }

  exportAllBeneficiaires(query: any) {
    return this.http.post(
      `${environment.apiUrl}/beneficiaires/export-all`,
      query,
      {
        responseType: 'blob',
      }
    );
  }
}
