import { Component } from '@angular/core';
import {
  FormControl,
  FormRecord,
  NonNullableFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AuthService } from 'src/app/services/auth.service';
import { ObligesService } from 'src/app/services/obliges.service';

@Component({
  selector: 'app-list-obliges',
  templateUrl: './list-obliges.component.html',
  styleUrl: './list-obliges.component.css',
})
export class ListObligesComponent {
  pageIndex = 1;
  pageSize = 20;
  total_obliges: number = 0;
  client = 'Neoscope';
  isLoadingData = true;
  toogleFilters = false;

  projets: any[] = [];
  obliges: any[] = [];

  all_checked = false;
  nzShowArrow = true;
  indeterminate = false;
  checked_obliges = new Set<number>();
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});

  constructor(
    private obligesService: ObligesService,
    private fb: NonNullableFormBuilder,
    private router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    const stringControlNames = ['search_text'];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.checked_obliges.add(id);
    } else {
      this.checked_obliges.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    console.log(this.checked_obliges.size);
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.obliges.forEach((item) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.all_checked = this.obliges.every((item) =>
      this.checked_obliges.has(item.id)
    );
    this.indeterminate =
      this.obliges.some((item) => this.checked_obliges.has(item.id)) &&
      !this.all_checked;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.obligesService
      .getAllObligesFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.obliges = data.rows.reverse();
        this.total_obliges = data.count;
        this.isLoadingData = false;
      });
  }

  resetForm(): void {
    this.queryForm.reset();

    this.queryObliges();
  }

  cancel(): void {}

  async confirmDelete(): Promise<void> {
    for (const oblige of this.checked_obliges) {
      await this.obligesService.delete(oblige);
    }
    this.checked_obliges.clear();
    this.queryObliges();
  }

  async deleteOblige(id: number) {
    this.isLoadingData = true;
    await this.obligesService.delete(id);
    this.obliges = this.obliges.filter((o) => o.id != id);
    this.queryObliges();
  }

  queryObliges() {
    this.isLoadingData = true;
    this.obligesService
      .getAllObligesFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.obliges = data.rows.reverse();
        this.total_obliges = data.count;
        this.isLoadingData = false;
      });
  }

  createOblige() {
    this.router.navigate(['/obliges/create']);
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  canAction(action_name: string) {
    return this.authService.canAction(action_name);
  }
}
