<nz-content>
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div class="align-items" nz-row>
          <h2 class="element-details-title">
            {{ admin.first_name }} {{ admin.last_name }}
          </h2>
          <nz-tag
            [nzBordered]="false"
            [nzColor]="getStatus().color"
            class="status-tag"
          >
            <span nz-icon nzTheme="outline" nzType="tag"></span>
            <span>{{ getStatus().text }}</span>
          </nz-tag>
        </div>
      </div>
      <div class="action-buttons" nz-col nzSpan="8">
        <div nz-row nzJustify="end">
          <div *ngIf="canDelete('ADMINISTRATEURS')" class="margin-right" nz-col>
            <button
              (nzOnCancel)="cancel()"
              (nzOnConfirm)="confirmDelete()"
              [nzIcon]="popConfirmIcon"
              class="secondary-button mass-action-button"
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement cet admin ?"
              nzType="default"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
          </div>
          <div *ngIf="canEdit('ADMINISTRATEURS')" nz-col>
            <button
              class="secondary-button"
              nz-button
              nzType="default"
              routerLink="/administrateurs/edit/{{ admin.id }}"
            >
              <span nz-icon nzType="edit"></span>
              <span class="action-text">Éditer</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>

    <h4>
      <span nz-icon nzTheme="outline" nzType="solution"></span> Détails
      administrateur
    </h4>
    <div nz-row class="details-container">
      <nz-card class="box">
        <div nz-row class="element-detail-row">
          <div [nzSpan]="8" class="field-grey-label" nz-col>
            Nom d'utilisateur
          </div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="14" class="wrap" nz-col>{{ admin.username }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div [nzSpan]="8" class="field-grey-label" nz-col>Email</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="14" class="wrap" nz-col>
            {{ admin.email }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div [nzSpan]="8" class="field-grey-label" nz-col>Rôle</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="14" class="wrap" nz-col>
            {{ roleToLabel(admin.role) }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div [nzSpan]="8" class="field-grey-label" nz-col>ID CRM</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="14" class="wrap" nz-col>
            {{ admin.id_crm }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div [nzSpan]="8" class="field-grey-label" nz-col>Origine</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="14" class="wrap" nz-col>
            {{ client.name }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div [nzSpan]="8" class="field-grey-label" nz-col>Accès clients</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="14" class="wrap" nz-col>
            <span *ngFor="let client of accessibleClients; let i = index">
              {{ client.name
              }}<span *ngIf="i < accessibleClients.length - 1">, </span>
            </span>
          </div>
        </div>
      </nz-card>
    </div>
  </div>
  <ng-template #checkedTemplate
    ><span nz-icon nzType="check"></span
  ></ng-template>
  <ng-template #unCheckedTemplate
    ><span nz-icon nzType="close"></span
  ></ng-template>
  <ng-template #popConfirmIcon></ng-template>
</nz-content>
