import { Component } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ChatService } from 'src/app/services/chat.service';
import { ProjetsService } from 'src/app/services/projets.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-create-chat',
  templateUrl: './create-chat.component.html',
  styleUrl: './create-chat.component.css'
})
export class CreateChatComponent {
  createForm: FormGroup;
  isFormModified: boolean;
  discussion: any;
  users: any;
  projets: any = [];

  controlArray: Array<{ index: number; show: boolean }> = [];

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private chatService: ChatService,
    private projetsservice: ProjetsService,
    private message: NzMessageService,
    private usersService: UsersService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      partenaireId: ['', Validators.required],
      titre: ['', [Validators.maxLength(40), Validators.required]],
      projetId: [],
      vu: [false],
    });
    this.usersService.getUsersFilter(1, 100000, {}).subscribe((users: any) => {
      this.users = users.rows;
    });
    this.createForm.get('partenaireId')?.valueChanges.subscribe((userId) => {
      this.createForm.get('projetId')?.setValue(null);
      if (userId) {
        this.GetUserProjects(userId);
      } else {
        this.projets = [];
      }
    });
  }

  createBasicMessage(): void {
    this.message.success('La création a été effectuée avec succès.', {
      nzDuration: 3000
    });
  }

  createDiscussion() {
    if (this.createForm.valid) {
      const payload = { ...this.discussion };
      Object.keys(this.createForm.controls).forEach((key) => {
        if (this.createForm.controls[key]) {
          payload[key] = this.createForm.controls[key].value;
        }
      });
      this.chatService
        .createDiscussion(payload)
        .subscribe((Discussion: any) => {
          this.createBasicMessage();
          this.router.navigate(['/messagerie']);
        });
    } else {
      Object.values(this.createForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  checkFormChanges(): boolean {
    this.isFormModified = !this.createForm.pristine;
    return this.isFormModified;
  }

  goBack() {
    this.router.navigate(['/messagerie']);
  }

  GetUserProjects(user_id: number): any {
    this.projetsservice.GetUserProjects(user_id).subscribe((data) => {
      this.projets = data;
    })
  }
}
