import { Component, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-two-fact-auth',
  templateUrl: './two-fact-auth.component.html',
  styleUrls: ['./two-fact-auth.component.css'],
})
export class TwoFactAuthComponent implements OnInit {
  form: FormGroup;
  email: string | null;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private notification: NzNotificationService,
    private router: Router
  ) {
    this.form = this.fb.group({
      code: this.fb.array(this.createCodeControls()),
    });
  }

  get codeControls(): FormControl[] {
    return (this.form.get('code') as FormArray).controls as FormControl[];
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text');
      if (pastedText.length === 6 && !isNaN(Number(pastedText))) {
        for (let i = 0; i < pastedText.length; i++) {
          this.codeControls[i].setValue(pastedText[i]);
        }
      }
    }
  }

  onClick(event: MouseEvent, i: number): void {
    const clipboardData = navigator.clipboard;
    if (clipboardData) {
      clipboardData.readText().then((pastedText) => {
        if (pastedText.length === 6 && !isNaN(Number(pastedText))) {
          for (let i = 0; i < pastedText.length; i++) {
            this.codeControls[i].setValue(pastedText[i]);
          }
        }
      });
    }
  }

  ngOnInit() {
    const state = window.history.state;
    if (state && state.email) {
      this.email = state.email;
    } else {
      this.router.navigate(['/login']).then((r) => r);
    }
  }

  verifyCode() {
    const code = this.codeControls.map((control) => control.value).join('');
    this.loading = true;
    this.authService.verify2fa(code, this.email).subscribe({
      next: (res) => {
        this.authService.getCurrentUser().subscribe({
          next: () => {
            this.authService.isLoggedIn.next(true);
            this.router.navigate(['/']);
          },
          error: (err) => {
            throw err;
          },
        });

      },
      error: (err) => {
        this.loading = false;
        this.notification.error('Erreur', err.error.message, {
          nzClass: 'error',
          nzAnimate: true,
          nzStyle: {
            width: '350px',
            background: '#fdeded',
          },
        });
      },
    });
  }

  onInput(event: Event, i: number) {
    const input = event.target as HTMLInputElement;
    if (input.value.length === 1 && i < 5) {
      const nextInput = document.getElementById(
        (i + 1).toString()
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  onUpdate(event: KeyboardEvent, i: number) {
    const input = event.target as HTMLInputElement;
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (input.value === '' && i > 0) {
        const previousInput = document.getElementById(
          (i - 1).toString()
        ) as HTMLInputElement;
        if (previousInput) {
          previousInput.focus();
        }
      }
    } else {
      if(input.value.length === 0){
        this.codeControls[i].setValue(undefined);
      }
      else if (input.value.length > 1) {
        this.codeControls[i].setValue(input.value.slice(0, 1));
      }
    }
  }

  resendCode() {
    this.loading = true;
    for (let i = 0; i < this.codeControls.length; i++) {
      this.codeControls[i].setValue(undefined);
    }
    this.authService.resend2faCode(this.email).subscribe({
      next: (res) => {
        this.loading = false;
        this.notification.success('Succès', 'Code envoyé avec succès', {
          nzClass: 'success',
          nzAnimate: true,
          nzStyle: {
            width: '350px',
            background: '#f6ffed',
          },
        });
      },
      error: (err) => {
        this.loading = false;
        this.notification.error('Erreur', err.error.message, {
          nzClass: 'error',
          nzAnimate: true,
          nzStyle: {
            width: '350px',
            background: '#fdeded',
          },
        });
      },
    });
  }

  private createCodeControls(): FormControl[] {
    return Array.from({ length: 6 }, () => new FormControl(''));
  }
}
