import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocietesService {
  constructor(private http: HttpClient) {}

  getSocieteById(societe_id: number) {
    return this.http.get(`${environment.apiUrl}/societes/${societe_id}`);
  }

  getFilterSocietes(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/societes/page=${page}&limit=${limit}`,
      query
    );
  }

  getAllSocietesByCategoriePrix(code_prix: string) {
    return this.http.get(`${environment.apiUrl}/societes/prix=${code_prix}`);
  }

  editSociete(
    societe_id: number,
    societePayload: any,
    entreprises_travaux: any
  ) {
    const body = {
      ...societePayload,
      entreprises_travaux,
    };

    return this.http.patch(
      `${environment.apiUrl}/societes/edit/${societe_id}`,
      body
    );
  }

  deleteSociete(societe_id: number) {
    return firstValueFrom(
      this.http.delete(`${environment.apiUrl}/societes/delete/${societe_id}`)
    );
  }

  exportSocietes(project_ids: number[]) {
    return this.http.post(
      `${environment.apiUrl}/societes/export`,
      { ids: project_ids },
      { responseType: 'blob' }
    );
  }

  exportAllSocietes(query: any) {
    return this.http.post(`${environment.apiUrl}/societes/export-all`, query, {
      responseType: 'blob',
    });
  }

  addPartner(name: string, id_crm: string, id_societe: string) {
    return this.http.post(
      `${environment.apiUrl}/entreprise_travaux/create`,
      { name, id_crm, id_societe },
      {
        responseType: 'blob',
      }
    );
  }

  delete(id: number) {
    return firstValueFrom(
      this.http.delete(`${environment.apiUrl}/societes/delete/${id}`)
    );
  }

  crateSociete(payload: any) {
    return this.http.post(`${environment.apiUrl}/societes/create`, payload);
  }
}
