import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrixService {
  constructor(private http: HttpClient) {}

  getAllCategorieSociete(
    page: number,
    limit: number,
    client: string,
    query: any
  ) {
    return this.http.post(
      `${environment.apiUrl}/categorie-societes/all/page=${page}&limit=${limit}&client=${client}`,
      query
    );
  }

  deleteCategorie(categorie_code: string) {
    return firstValueFrom(
      this.http.delete(
        `${environment.apiUrl}/categorie-societes/${categorie_code}`
      )
    );
  }

  getCategorieByCode(categorie_code: string) {
    return this.http.get(
      `${environment.apiUrl}/categorie-societes/${categorie_code}`
    );
  }

  editCategorie(categorie_code: string, categorie: any) {
    return this.http.put(
      `${environment.apiUrl}/categorie-societes/edit/${categorie_code}`,
      categorie
    );
  }

  createCategorie(categorie: any) {
    return this.http.post(
      `${environment.apiUrl}/categorie-societes/create`,
      categorie
    );
  }

  SendMailMajPrix(code: any) {
    return this.http.post(`${environment.apiUrl}/categorie-societes/mail-maj-prix`, {
      code: code,
    });
  }
}
