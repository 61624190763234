import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormRecord,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ManagerService } from 'src/app/services/manager.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-create-management',
  templateUrl: './create-management.component.html',
  styleUrl: './create-management.component.css',
})
export class CreateManagementComponent {
  createForm: FormGroup;
  manager: any;
  isFormModified: boolean;
  users: any[];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private managerService: ManagerService,
    private message: NzMessageService,
    private router: Router,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.createForm = this.fb.group({
      managerId: ['', Validators.required],
      managedId: ['', Validators.required],
      etat_management: ['', Validators.required],
    });
    this.usersService.getUsersFilter(1, 100000, {}).subscribe((users: any) => {
      this.users = users.rows;
    });
  }

  createBasicMessage(): void {
    this.message.success('La création a été effectuée avec succès.', {
      nzDuration: 3000,
    });
  }

  createManager(): void {
    if (this.createForm.valid) {
      const managerId = this.createForm.controls['managerId'].value;
      const managedId = this.createForm.controls['managedId'].value;
      if (managerId === managedId) {
        this.message.error(
          'Le manager ne peut pas être le managé en même temps.'
        );
        return;
      }
      const payload = { ...this.manager };
      Object.keys(this.createForm.controls).forEach((key) => {
        if (this.createForm.controls[key]) {
          payload[key] = this.createForm.controls[key].value;
        }
      });
      this.managerService.createManager(payload).subscribe(
        (updatedManager: any) => {
          this.manager = updatedManager;
          this.createBasicMessage();
          this.createForm.markAsPristine();
          this.router.navigate(['/managers']);
        },
        () => {
          this.message.error(
            'Une erreur est survenue. Veuillez réessayer plus tard ou contacter le service informatique.'
          );
        }
      );
    } else {
      Object.values(this.createForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  checkFormChanges(): boolean {
    this.isFormModified = !this.createForm.pristine;
    return this.isFormModified;
  }

  goBack(): void {
    this.router.navigate(['/managers']);
  }
}
