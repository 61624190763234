<nz-content class="content-wrapper">
  <ng-template #cadenas><span nz-icon nzType="lock"></span></ng-template>
  <div class="form-container">
    <div class="title">Modification du département</div>
    <form
      [formGroup]="editForm"
      class="form-layout"
      nz-form
    >
      <div class="row-content" nz-row>
        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Code département</nz-form-label>
            <nz-form-control [nzSpan]="12" disabled="true" nz-col nzErrorTip="code est obligatoire">
              <nz-input-group [nzSuffix]="cadenas" class="edit-field">
                <input
                  class="disabled-input"
                  formControlName="code_departement"
                  nz-input
                  readonly
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Nom département</nz-form-label>
            <nz-form-control [nzSpan]="12" nz-col nzErrorTip="nom est obligatoire">
              <nz-input-group [nzSuffix]="cadenas" class="edit-field">
                <input
                  class="disabled-input"
                  formControlName="nom_departement"
                  nz-input
                  readonly
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Responsable résidentiel individuel</nz-form-label>
            <nz-form-control [nzSpan]="12" nz-col>
              <nz-select formControlName="responsable_residentiel_individuel">
                <nz-option
                  *ngFor="let admin of admins"
                  [nzLabel]="admin.first_name + ' ' + admin.last_name"
                  [nzValue]="admin.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>


        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Responsable résidentiel collectif</nz-form-label>
            <nz-form-control [nzSpan]="12" nz-col>
              <nz-select formControlName="responsable_residentiel_collectif">
                <nz-option
                  *ngFor="let admin of admins"
                  [nzLabel]="admin.first_name + ' ' + admin.last_name"
                  [nzValue]="admin.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Responsable tertiaire</nz-form-label>
            <nz-form-control [nzSpan]="12" nz-col>
              <nz-select formControlName="responsable_tertiaire">
                <nz-option
                  *ngFor="let admin of admins"
                  [nzLabel]="admin.first_name + ' ' + admin.last_name"
                  [nzValue]="admin.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Responsable industriel</nz-form-label>
            <nz-form-control [nzSpan]="12" nz-col>
              <nz-select
                formControlName="responsable_industriel"
              >
                <nz-option
                  *ngFor="let admin of admins"
                  [nzLabel]="admin.first_name + ' ' + admin.last_name"
                  [nzValue]="admin.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Responsable transport</nz-form-label>
            <nz-form-control [nzSpan]="12" nz-col>
              <nz-select
                formControlName="responsable_transport"
              >
                <nz-option
                  *ngFor="let admin of admins"
                  [nzLabel]="admin.first_name + ' ' + admin.last_name"
                  [nzValue]="admin.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Responsable agriculture</nz-form-label>
            <nz-form-control [nzSpan]="12" nz-col>
              <nz-select
                formControlName="responsable_agriculture"
              >
                <nz-option
                  *ngFor="let admin of admins"
                  [nzLabel]="admin.first_name + ' ' + admin.last_name"
                  [nzValue]="admin.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Responsable réseaux</nz-form-label>
            <nz-form-control [nzSpan]="12" nz-col>
              <nz-select
                formControlName="responsable_reseaux"
              >
                <nz-option
                  *ngFor="let admin of admins"
                  [nzLabel]="admin.first_name + ' ' + admin.last_name"
                  [nzValue]="admin.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>


      </div>

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            [disabled]="editForm.invalid || !editForm.dirty"
            class="circle-button small save-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Sauvegarder"
            (click)="editGestion()"
          >
          <span class="icon">
            <span nz-icon nzType="save"></span>
          </span>
          </button>
          <button
            (click)="goBack()"
            class="circle-button small cancel-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Annuler"
          >
          <span class="icon">
            <span nz-icon nzType="rollback"></span>
          </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
