<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit> </ng-template>
  <div class="form-container">
    <div class="title">Profile</div>
    <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
      <div class="profile">
        <img alt="logo" src="assets/icons/neovee-vert.png" />
        <button nz-button class="picturebutton" [nzType]="'primary'">
          <span>Changer l'image</span>
        </button>
        <button nz-button class="picturebutton" [nzType]="'primary'">
          <span>Supprimer l'image</span>
        </button>
      </div>

    </div>
    <form class="form-layout" nz-form [formGroup]="editForm">
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8">Prénom</nz-form-label>
            <nz-form-control nz-col nzErrorTip="Ce champ est obligatoire" [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input nz-input [(ngModel)]="currentUser.first_name" formControlName="first_name" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8">Nom </nz-form-label>
            <nz-form-control nz-col nzErrorTip="Ce champ est obligatoire" [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input nz-input [(ngModel)]="currentUser.last_name" formControlName="last_name" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
        <nz-form-item>
          <nz-form-label nzLabelWrap nz-col [nzSpan]="8">Username </nz-form-label>
          <nz-form-control nz-col nzErrorTip="Ce champ est obligatoire" [nzSpan]="14">
            <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
              <input nz-input [(ngModel)]="currentUser.username" formControlName="username" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
        <nz-form-item>
          
          <nz-form-control nz-col [nzOffset]="8" [nzSpan]="14">
            <button nz-button [nzType]="'primary'" (click)="showModal()">
              <span>Changer le mot de passe</span>
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
      

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button nz-button nzTooltipTitle="Sauvegarder" nzTooltipPlacement="leftTop" nz-tooltip
            class="circle-button small save-button" [disabled]="!checkFormChanges() || editForm.invalid" type="submit"
            (click)="editAdmin()">
            <span class="icon">
              <span nz-icon nzType="save"></span>
            </span>
          </button>
          <button nz-button nzTooltipTitle="Annuler" nzTooltipPlacement="leftTop" nz-tooltip
            class="circle-button small cancel-button" (click)="goBack()">
            <span class="icon">
              <span nz-icon nzType="rollback"></span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
<nz-modal [(nzVisible)]="isVisible" nzTitle="Changer mot de passe" [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()">
  <ng-container *nzModalContent>
    <div class="modal-container">
      <form nz-form [formGroup]="passwordForm">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzFor="password" nzLabelWrap>Mot de passe existant</nz-form-label>
              <nz-form-control [nzSpan]="16" nzErrorTip="Le mot de passe est obligatoire">
                <nz-input-group [nzSuffix]="suffixpassword">
                  <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="oldpassword" id="password" />
                  <ng-template #suffixpassword>
                    <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                      (click)="passwordVisible = !passwordVisible"></span>
                  </ng-template>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
  
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzFor="newpassword" nzLabelWrap>Nouveau mot de passe</nz-form-label>
              <nz-form-control [nzSpan]="16"
                nzErrorTip="Le mot de passe doit contenir au moins 8 caractères, dont au moins 1 caractère spécial.">
                <nz-input-group [nzSuffix]="suffixnewpassword">
                  <input [type]="newpasswordVisible ? 'text' : 'password'" nz-input formControlName="newpassword" id="newpassword" />
                  <ng-template #suffixnewpassword>
                    <span nz-icon [nzType]="newpasswordVisible ? 'eye-invisible' : 'eye'"
                      (click)="newpasswordVisible = !newpasswordVisible"></span>
                  </ng-template>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
  
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzFor="newpasswordconfirmed" nzLabelWrap>Confirmez le mot de passe</nz-form-label>
              <nz-form-control [nzSpan]="16" nzErrorTip="Les deux mots de passe doivent être identiques">
                <nz-input-group [nzSuffix]="suffixnewpasswordconfirmed">
                  <input [type]="newpasswordconfirmedVisible ? 'text' : 'password'" nz-input
                    formControlName="newpasswordconfirmed" id="newpasswordconfirmed" />
                  <ng-template #suffixnewpasswordconfirmed>
                    <span nz-icon [nzType]="newpasswordconfirmedVisible ? 'eye-invisible' : 'eye'"
                      (click)="newpasswordconfirmedVisible = !newpasswordconfirmedVisible"></span>
                  </ng-template>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Annuler</button>
    <button nz-button nzType="primary" [nzLoading]="uploading" (click)="ChangePassword()">Changer le mot de
      passe</button>
  </ng-template>
</nz-modal>