<nz-content>
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div class="align-items" nz-row>
          <h2 class="element-details-title">
            {{ oblige.nom }}
          </h2>
        </div>
      </div>
      <div class="action-buttons" nz-col nzSpan="8">
        <div nz-row nzJustify="end">
          <div class="margin-right" nz-col>
            <button
              (nzOnCancel)="cancel()"
              (nzOnConfirm)="confirmDelete()"
              *ngIf="canDelete('BENEFICIAIRES')"
              class="secondary-button mass-action-button"
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer ce bénéficiaire ?"
              nzType="default"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
            <button
              *ngIf="canEdit('BENEFICIAIRES')"
              class="secondary-button margin-left-10"
              nz-button
              nzType="default"
              routerLink="/obliges/edit/{{ oblige.id }}"
            >
              <span nz-icon nzType="edit"></span>
              Éditer
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>

    <h4 class="info-card-title">
      <span nz-icon nzTheme="outline" nzType="solution"></span> Détails
      obligé
    </h4>

    <div class="details-container" nz-row>
      <nz-card class="box">
        <div nz-row class="element-detail-row">
          <div [nzSpan]="6" class="field-grey-label" nz-col>Nom officiel</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>{{ oblige.nomOfficiel }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div [nzSpan]="6" class="field-grey-label" nz-col>Mention légale</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div
            [nzSpan]="17"
            class="wrap"
            nz-col
            [innerHTML]="oblige.mentions"
          ></div>
        </div>
      </nz-card>
    </div>
  </div>
  <ng-template #popConfirmIcon></ng-template>
</nz-content>
