<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form nz-form [formGroup]="queryForm" nz-col [nzSpan]="24">
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Rechercher dans les clients ..."
            (keyup.enter)="queryClientsNeoscope()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search" (click)="queryClientsNeoscope()"></span>
      </ng-template>
    </div>
    <br *ngIf="isLoadingData || clients_neoscope.length === 0" />

    <nz-table
      #fixedTable
      [nzData]="clients_neoscope"
      [nzFrontPagination]="false"
      [nzTotal]="total_clients_neoscope"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      (nzQueryParams)="onQueryParamsChange($event)"
      nzPaginationPosition="both"
      [nzShowTotal]="total_clients_template"
      [nzLoading]="isLoadingData"
      class="projet-list-table"
    >
      <ng-template #total_clients_template>
        <span>{{ total_clients_neoscope }} clients</span>
        <ng-template #popConfirmIcon> </ng-template>
      </ng-template>

      <thead class="liste-projets-head">
        <tr>
          <th>Nom</th>
          <th>Date</th>
          <th>CRM</th>
          <th>Status</th>
          <th nzRight nzWidth="90px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data">
          <td>
            <a routerLink="/clients-neoscope/details/{{ data.id }}">{{
              data.name
            }}</a>
          </td>
          <td>{{ data.created_at | date : "dd/MM/YYYY" }}</td>
          <td>{{ data.crm }}</td>
          <td>
            <nz-tag
              [nzColor]="getStatus(data).color"
              [nzBordered]="false"
              class="status-tag"
            >
              <span>{{ getStatus(data).text }}</span>
            </nz-tag>
          </td>
          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="action-buttons"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li
                  nz-menu-item
                  routerLink="/clients-neoscope/details/{{ data.id }}"
                >
                  <a
                    ><span nz-icon nzType="eye" nzTheme="outline"></span>
                    Détails</a
                  >
                </li>
                <li
                  nz-menu-item
                  routerLink="/clients-neoscope/edit/{{ data.id }}"
                >
                  <a
                    ><span nz-icon nzType="edit" nzTheme="outline"></span>
                    Éditer</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
