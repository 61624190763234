import {Component} from '@angular/core';

@Component({
  selector: 'app-unauthenticated-layout',
  templateUrl: './unauthenticated-layout.component.html',
  styleUrl: './unauthenticated-layout.component.css'
})
export class UnauthenticatedLayoutComponent {

}
