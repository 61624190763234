<div class="custom-container">
  <nz-card class="custom-card">
    <div class="logo-container">
      <img alt="Logo" class="logo-img" src="assets/icons/neovee-vert.png">
    </div>
    <form [formGroup]="form" class="custom-form">
      <fieldset>
        <div class="custom-form-field">
          <label class="custom-label" for="password">Nouveau Mot de passe</label>
          <nz-input-group [ngClass]="{
            'error-input': form.get('password')?.errors && form.get('password')?.touched
          }" [nzSuffix]="suffixTemplate" class="password-inp">
            <input
              [ngClass]="{
            'error-input': form.get('password')?.errors && form.get('password')?.touched
          }"

              [type]="passwordVisible ? 'text' : 'password'"
              formControlName="password"
              id="password"
              name="password"
              nz-input
              placeholder="******************"
            />
            <ng-template #suffixTemplate>
                  <span
                    (click)="passwordVisible = !passwordVisible"
                    [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                    nz-icon
                  ></span>
            </ng-template>
          </nz-input-group>

          <p
            *ngIf="
            form.get('password')?.errors && (form.get('password')?.touched)
          "
            class="error-message"
          >
            Le mot de passe doit contenir au moins 8 caractères, dont au moins 1 caractère spécial.
          </p>
        </div>
        <div class="custom-form-field">
          <label class="custom-label" for="confirmPass">
            Confirmer Mot de passe
          </label>
          <nz-input-group [ngClass]="{
            'error-input': form.get('confirmPassword')?.errors && form.get('confirmPassword')?.touched
          }" [nzSuffix]="suffixTemplate" class="password-inp">
            <input
              [ngClass]="{
            'error-input': form.get('confirmPassword')?.errors && form.get('confirmPassword')?.touched
          }"

              [type]="passwordVisible ? 'text' : 'password'"
              formControlName="confirmPassword"
              id="confirmPass"
              name="confirmPassword"
              nz-input
              placeholder="******************"
            />
            <ng-template #suffixTemplate>
                  <span
                    (click)="passwordVisible = !passwordVisible"
                    [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                    nz-icon
                  ></span>
            </ng-template>
          </nz-input-group>

          <p
            *ngIf="
            form.get('confirmPassword')?.errors && (form.get('confirmPassword')?.touched)
          "
            class="error-message"
          >
            Le mot de passe doit contenir au moins 8 caractères, dont au moins 1 caractère spécial.
          </p>
        </div>
      </fieldset>
      <div class="flex-container">
        <button
          (click)="send()"
          class="custom-button"
          nz-button
          type="submit"
        >
          Valider
        </button>
      </div>
    </form>
  </nz-card>
</div>

