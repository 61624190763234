import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DepartementService {

  constructor(private http: HttpClient) {
  }

  getAllDepartements() {
    return this.http.get(
      `${environment.apiUrl}/gestion-commerciale/all`
    );
  }

  getDepartementsByQuery(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/gestion-commerciale/page=${page}&limit=${limit}`,
      query
    );
  }

  queryDepartements(query: any) {
    return this.http.post(
      `${environment.apiUrl}/gestion-commerciale/query`,
      query
    );
  }

  getDepartementById(departement_id: number) {
    return this.http.get(
      `${environment.apiUrl}/gestion-commerciale/edit/${departement_id}`
    );
  }

  editDepartement(departement_id: string, payload: any) {
    return this.http.post(
      `${environment.apiUrl}/gestion-commerciale/edit/${departement_id}`,
      payload
    );
  }
}
