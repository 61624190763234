<nz-content>
  <div class="inner-content">
    <div class="search-panel" nz-row nzJustify="space-between">
      <form [formGroup]="queryForm" [nzSpan]="24" nz-col nz-form>
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          class="main-search"
          nzSize="large"
        >
          <input
            class="main-search-input"
            formControlName="search_text"
            nz-input
            placeholder="Rechercher dans les bandeaux ..."
            type="text"
            (keyup.enter)="onSearchChange()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>

    <br *ngIf="isLoadingData || filteredData.length === 0" />

    <nz-table
      #fixedTable
      [nzData]="filteredData"
      [nzLoading]="isLoadingData"
      [nzTotal]="totalBandeaus"
      class="bandeau-list-table"
      nzPaginationPosition="top"
      [nzShowTotal]="totalBandeaustemplate"
    >
      <ng-template #totalBandeaustemplate>
        <span
          >{{ totalBandeaus }}
          {{ totalBandeaus == 1 ? "bandeau" : "bandeaux" }}</span
        >
        <ng-template #popConfirmIcon> </ng-template>
      </ng-template>
      <thead class="liste-projets-head">
        <tr>
          <th
            *ngFor="let column of listOfColumns"
            [nzWidth]="column.name === 'Actions' ? '100px' : null"
            [nzAlign]="column.name === 'Actions' ? 'center' : null"
          >
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let bandeau of filteredData">
          <td>{{ bandeau.label }}</td>
          <td>{{ bandeau.plateforme }}</td>
          <td>
            <nz-tag *ngIf="bandeau.is_active" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!bandeau.is_active" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </td>
          <td>{{ bandeau.start_date | date : "dd/MM/yyyy - HH:mm" }}</td>
          <td>{{ bandeau.end_date | date : "dd/MM/yyyy - HH:mm" }}</td>
          <td *ngIf="canEdit('BANDEAU-INFO')" nzAlign="center">
            <button
              [nzDropdownMenu]="menu"
              nz-button
              nz-dropdown
              class="action-buttons"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li
                  (click)="editBandeau(bandeau.id, bandeau.label)"
                  nz-menu-item
                >
                  <a
                    ><span nz-icon nzTheme="outline" nzType="edit"></span>
                    Éditer</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
