import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

import { ColumnItem } from '../../columnItem.interface';

@Component({
  selector: 'app-status-table',
  templateUrl: './status-table.component.html',
  styleUrl: './status-table.component.css',
})
export class StatusTableComponent {
  @Input() columns: ColumnItem[] = [];
  @Input() data: any[] = [];
  @Input() isLoadingData: boolean;
  @Input() statutType: string;

  pageSize = 10;

  constructor(
    private router: Router,
    private readonly authService: AuthService
  ) {}

  async navigateToEdit(id: number, label: string): Promise<void> {
    await this.router.navigate([`/statuts-${this.statutType}/edit/${id}`], {
      state: { label: label, statutID: id },
    });
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }
}
