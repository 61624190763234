import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ManagerService {
    constructor(private http: HttpClient) { }
    getAllManagersFilter(page: number, limit: number, query: any) {
        return this.http.post(
            `${environment.apiUrl}/manager/page=${page}&limit=${limit}`,
            query
        );
    }

    getManagerById(id: number) {
        return this.http.get(`${environment.apiUrl}/manager/${id}`)
    }

    editManager(id: number, payload: any) {
        return this.http.put(`${environment.apiUrl}/manager/${id}`, payload);
    }

    delete(id: number) {
        return firstValueFrom(this.http.delete(`${environment.apiUrl}/manager/${id}`));
    }
    createManager(payload:any) {
        return this.http.post(`${environment.apiUrl}/manager/create`, payload)
    }
}