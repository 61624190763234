<nz-result
  nzStatus="404"
  nzTitle="404"
  nzSubTitle="Désolé(e), la page que vous avez visitée n'existe pas."
>
  <div nz-result-extra>
    <button nz-button nzType="primary" routerLink="/">
      Retour à l'accueil
    </button>
  </div>
</nz-result>
