import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormRecord,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminsService } from 'src/app/services/admins.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SocietesService } from 'src/app/services/societes.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.css'],
})
export class EditUsersComponent {
  pageIndex = 1;
  pageSize = 10;
  client = 'Neoscope';
  editForm: FormGroup;
  initialUserState: any;
  user: any;
  user_id: any;
  validateForm: FormRecord<FormControl<string>> = this.fb.record({});
  controlArray: Array<{ index: number; show: boolean }> = [];
  focusedFields: { [key: string]: boolean } = {};
  checked = true;
  isFormModified: boolean = false;
  societes: any[];
  chargesAffaires: any[];
  isFormSubmitting: boolean = false;

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private message: NzMessageService,
    private router: Router,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private societesService: SocietesService,
    private adminsService: AdminsService
  ) {}

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.user_id = this.route.snapshot.params['id'];
    try {
      this.usersService.getUserById(this.user_id).subscribe((user: any) => {
        this.authService.canAccessClientData(user.source);
        this.user = user;
        console.log(user);
        this.initialUserState = { ...user };
        this.loadingService.offLoading();
      });
    } catch (error) {
      console.error('Error fetching user', error);
    }

    this.societesService.getFilterSocietes(1, 100000, {}).subscribe((data: any) => {
      this.societes = data.rows;
    });

    this.getAllChargesAffaires();

    this.editForm = this.fb.group({
      civilite: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      idCrmContactPartenaire: [''],
      societe_id: ['', Validators.required],
      socialReason: [''],
      adresse: ['', Validators.required],
      codePostal: [
        '',
        [
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(5),
          Validators.minLength(5),
          Validators.required,
        ],
      ],
      ville: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      phone: ['', [Validators.maxLength(10), Validators.required]],
      isManager: [''],
      newsletter: [''],
    });

    Object.keys(this.editForm.controls).forEach((key) => {
      this.focusedFields[key] = false;
    });

    this.editForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    return !this.editForm.pristine;
  }

  editUser() {
    if (this.editForm.valid) {
      const payload: { [key: string]: any } = {};
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });

      this.usersService.editUser(this.user_id, payload).subscribe({
        next: (updatedUser: any) => {
          Object.keys(payload).forEach((key) => {
            this.user[key] = updatedUser[key];
          });
          this.isFormSubmitting = true;
          this.message.success(
            'Vos modifications ont été enregistrées avec succès.'
          );
          this.router.navigate(['/utilisateurs']);
        },
        error: (error: any) => {
          console.error('Error updating user:', error);
        },
      });
    } else {
      console.log('Form is invalid');
      Object.values(this.editForm.controls).forEach((control) => {
        if (control.invalid) {
          const payload = { ...this.user };
          Object.keys(this.editForm.controls).forEach((key) => {
            if (this.editForm.controls[key]) {
              payload[key] = this.editForm.controls[key].value;
            }
          });
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  cancelChanges() {
    this.editForm.patchValue({
      ...this.initialUserState,
    });
    this.router.navigate(['/utilisateurs']);
  }

  getAllChargesAffaires() {
    this.adminsService.getAllCommercials().subscribe((data: any) => {
      this.chargesAffaires = data.rows;
    });
  }
  
  getName(gestionnaire: any): string {
    return gestionnaire.first_name + ' ' + gestionnaire.last_name;
  }
}
