import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'siren',
})
export class SirenPipe implements PipeTransform {

  transform(value: string | number): string {
    if (!value) {
      return '';
    }

    const numStr = value.toString();

    return numStr.replace(/(\d{3})(?=\d)/g, '$1 ').trim();
  }

}
