import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frenchify',
})
export class FrenchifyPipe implements PipeTransform {
  transform(value: any): string {
    if (value === '' || value === undefined) {
      return '';
    }

    if (value === true) {
      return 'Oui';
    } else if (value === false) {
      return 'Non';
    } else {
      return value;
    }
  }
}
