import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ProjectloggingService {
    constructor(private http: HttpClient) { }
    getAllFilter(page: number, limit: number, query: any) {
        return this.http.post(
            `${environment.apiUrl}/logging/page=${page}&limit=${limit}`,
            query
        );
    }
}