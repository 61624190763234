import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminDashboardComponent {

  constructor(private router: Router){}
  panels = [
    {
      id: 1,
      active: true,
      name: 'Neovee',
      disabled: false
    },
    {
      id: 2,
      active: false,
      disabled: false,
      name: 'Client X'
    },
    {
      id: 3,
      active: false,
      disabled: true,
      name: 'Client Y'
    }
  ];
  
  goToClient(id: number){
    console.log('here')
    this.router.navigate(['/client', id])
  }
}
