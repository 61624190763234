<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form
        nz-form
        [formGroup]="queryForm"
        nz-col
        [nzSpan]="canEdit('PRIX') ? 18 : 21"
      >
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Rechercher dans les catégories de prix ..."
            (keyup.enter)="queryCategorieSociete()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search" (click)="queryCategorieSociete()"></span>
      </ng-template>
      <div class="padding-left-10" nz-col [nzSpan]="3" *ngIf="canEdit('PRIX')">
        <button
          nz-button
          nzType="primary"
          class="filters-toogle prix-button"
          routerLink="/categories-prix/new"
        >
          <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
          Ajouter
        </button>
      </div>
      <div class="padding-left-10" nz-col [nzSpan]="3">
        <button
          nz-button
          nzType="primary"
          class="filters-toogle prix-button"
          nz-popconfirm
          nzPopconfirmTitle="Êtes-vous sûr de vouloir mettre à jour les prix des projets de toutes les catégories ?"
          (nzOnConfirm)="majPrixAllCategorie()"
          (nzOnCancel)="cancel()"
          [disabled]="!canAction('MAJ_PRIX')"
        >
          <span nz-icon nzType="file-sync" nzTheme="outline"></span>
          MAJ Prix
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || categorie_societes.length === 0" />

    <nz-table
      *ngIf="isDesktop"
      #fixedTable
      [nzData]="categorie_societes"
      [nzFrontPagination]="false"
      [nzTotal]="total_categorie_societes"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      (nzQueryParams)="onQueryParamsChange($event)"
      nzPaginationPosition="both"
      [nzShowTotal]="total_categorie_societes_template"
      [nzScroll]="{ x: '700px' }"
      [nzLoading]="isLoadingData"
      class="projet-list-table"
    >
      <ng-template #total_categorie_societes_template>
        <button
          *ngIf="checked_categorie_societes.size > 0 && canDelete('PRIX')"
          nz-button
          nz-popconfirm
          nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement toutes les catégories de prix sélectionnées ?"
          nzType="default"
          class="secondary-button mass-action-button"
          (nzOnConfirm)="confirmDelete()"
          (nzOnCancel)="cancel()"
          [nzIcon]="popConfirmIcon"
        >
          <span nz-icon nzType="delete"></span>
          Supprimer
        </button>
        <!-- <button
          *ngIf="checked_categorie_societes.size > 0"
          nz-button
          nzType="default"
          class="secondary-button mass-action-button"
          (click)="exporter()"
        >
          <span nz-icon nzType="download"></span>
          Exporter
        </button> -->
        <span
          >{{ total_categorie_societes }}
          {{ total_categorie_societes == 1 ? "catégorie" : "catégories" }}</span
        >
        <ng-template #popConfirmIcon> </ng-template>
      </ng-template>

      <thead class="liste-projets-head">
        <tr>
          <th
            *ngIf="canDelete('PRIX')"
            nzWidth="30px"
            [(nzChecked)]="all_checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th nzWidth="150px">Code catégorie</th>
          <th nzWidth="150px">Nom catégorie</th>
          <th nzWidth="150px">Date de dernière mise à jour</th>

          <th nzRight nzWidth="90px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data">
          <td
            *ngIf="canDelete('PRIX')"
            [nzChecked]="checked_categorie_societes.has(data.code)"
            (nzCheckedChange)="onItemChecked(data.code, $event)"
          ></td>
          <td>
            <a routerLink="/categories-prix/{{ data.code }}">{{ data.code }}</a>
          </td>
          <td>
            <a routerLink="/categories-prix/{{ data.code }}">{{ data.nom }}</a>
          </td>
          <td>{{ data.date | date : "dd/MM/YYYY HH:mm" }}</td>

          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="action-buttons"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item routerLink="/categories-prix/{{ data.code }}">
                  <a
                    ><span nz-icon nzType="eye" nzTheme="outline"></span>
                    Détails</a
                  >
                </li>
                <li
                  nz-menu-item
                  routerLink="/categories-prix/edit/{{ data.code }}"
                  *ngIf="canEdit('PRIX')"
                >
                  <a
                    ><span nz-icon nzType="edit" nzTheme="outline"></span>
                    Éditer</a
                  >
                </li>
                <li
                  nz-menu-item
                  nz-popconfirm
                  nzPopconfirmTitle="Êtes-vous sûr de vouloir mettre à jour les prix des projets de la catégorie sélectionnée ?"
                  (nzOnConfirm)="majPrixCategorie(data.code)"
                  (nzOnCancel)="cancel()"
                  *ngIf="canAction('MAJ_PRIX')"
                >
                  <a
                    ><span nz-icon nzType="file-sync" nzTheme="outline"></span>
                    MAJ Prix</a
                  >
                </li>
                <li
                  nz-menu-item
                  nz-popconfirm
                  nzPopconfirmTitle="Êtes-vous sûr de vouloir envoyer le mail de mise à jour des prix de la catégorie sélectionnée ?"
                  (nzOnConfirm)="sendMailPrix(data.code)"
                  (nzOnCancel)="cancel()"
                  *ngIf="canAction('MAIL_PRIX')"
                >
                  <a
                    ><span nz-icon nzType="mail" nzTheme="outline"></span> Mail
                    Prix</a
                  >
                </li>
                <li
                  nz-menu-item
                  nz-popconfirm
                  nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement la catégorie de prix sélectionnée ?"
                  (nzOnConfirm)="deleteCategorie(data.code)"
                  (nzOnCancel)="cancel()"
                  *ngIf="canDelete('PRIX')"
                >
                  <a
                    ><span nz-icon nzType="delete" nzTheme="outline"></span>
                    Supprimer</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div *ngIf="!isDesktop">
      <div *ngFor="let categorie of categorie_societes">
        <nz-card class="nz-card">
          <div nz-row nzJustify="space-between" nzAlign="middle">
            <div nz-col [nzSpan]="20" class="title">
              {{ categorie.nom }}
            </div>
          </div>
          <div>
            <nz-tag nzColor="green" [nzBordered]="false" class="status-tag">
              <span nz-icon nzType="tag" nzTheme="outline"></span>
              <span>{{ categorie.code }}</span>
            </nz-tag>
          </div>
          <div>
            <nz-tag nzColor="gold" [nzBordered]="false" class="status-tag">
              <span nz-icon nzType="calendar" nzTheme="outline"></span>
              <span>{{ categorie.date | date : "dd/MM/YYYY HH:mm" }} </span>
            </nz-tag>
          </div>
          <div></div>
        </nz-card>
      </div>
    </div>
  </div>
</nz-content>
