import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  form: FormGroup;
  invalidLogin: boolean;
  loading: boolean = false;
  passwordVisible = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private notification: NzNotificationService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  login() {
    this.loading = true;
    this.authService
      .login(this.form.value.email, this.form.value.password)
      .subscribe({
        next: async (res: any) => {
          this.loading = false;
          if (res.requires2fa) {
            await this.router.navigate(['/verifyCode'], {
              state: { email: this.form.value.email },
            });
          } else {
            this.authService.getCurrentUser().subscribe({
              next: () => {
                this.authService.isLoggedIn.next(true);
                this.router.navigate(['/']);
              },
              error: (err) => {
                throw err;
              },
            });
          }
        },
        error: (err) => {
          this.invalidLogin = true;
          this.loading = false;
          this.notification.error('Erreur', err.error.message, {
            nzClass: 'error',
            nzAnimate: true,
            nzStyle: {
              width: '350px',
              background: '#fdeded',
            },
          });
        },
      });
  }
}
