import { Component, Input } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-file-tag',
  templateUrl: './file-tag.component.html',
  styleUrl: './file-tag.component.css'
})
export class FileTagComponent {
  @Input() fileType: string = 'default';
  @Input() fileName: string;
  @Input() fileUrl: string;
  fileIconType: string = 'default';

  constructor(
    private chatService: ChatService
  ) {
  }

  ngOnInit() {
    this.fileIconType = this.getFileIconType(this.fileType);
  }

  getFileIconType(fileType: string): string {
    switch (fileType) {
      case 'pdf':
        return 'file-pdf';
      case 'doc':
        return 'file-word';
      case 'xls':
        return 'file-excel';
      case 'ppt':
        return 'file-ppt';
      default:
        return 'file-text';
    }
  }

  downloadFile() {
    this.chatService
      .downloadFile(this.fileUrl)
      .subscribe((data) => {
        importedSaveAs(data, this.fileName);
      });
  }

}
