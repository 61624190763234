import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormRecord,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { switchMap } from 'rxjs';
import { AdminsService } from 'src/app/services/admins.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';
import { ObligesService } from 'src/app/services/obliges.service';
import { ProjetsService } from 'src/app/services/projets.service';
import { SocietesService } from 'src/app/services/societes.service';
import { UsersService } from 'src/app/services/users.service';
@Component({
  selector: 'app-edit-projet',
  templateUrl: './edit-projet.component.html',
  styleUrls: ['./edit-projet.component.css'],
})
export class EditProjetComponent implements OnInit {
  editForm: FormGroup;
  projet: any;
  projet_id: any;
  validateForm: FormRecord<FormControl<string>> = this.fb.record({});
  controlArray: Array<{ index: number; show: boolean }> = [];
  obliges: any;
  users: any;
  societes: any;
  isFormModified: boolean = false;
  statuts: any;
  controleurs: any;
  statuts_MPR: any;
  commercials: any;
  collaborateurs: any;
  isFormSubmitting: boolean = false;
  clients: any = [];

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private projetsService: ProjetsService,
    private societesService: SocietesService,
    private router: Router,
    private message: NzMessageService,
    private usersService: UsersService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private neoscopeService: NeoscopeService,
    private adminsService: AdminsService,
    private obligesService: ObligesService
  ) {}

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.projet_id = this.route.snapshot.params['id'];
    this.projetsService
      .getProjetById(this.projet_id)
      .subscribe((projet: any) => {
        this.authService.canAccessClientData(projet.source);
        this.projet = projet;
        this.loadingService.offLoading();
        this.usersService
          .getUsersFilter(1, 100000, {})
          .subscribe((users: any) => {
            this.users = users.rows;
            this.collaborateurs = this.users.filter((user: any) => {
              return user.id !== this.projet.user.id;
            });
          });
      });

    this.editForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(30)]],
      numeroDossier: ['', [Validators.required, Validators.maxLength(13)]],
      partenaire_id: ['', [Validators.required]],
      secteur: ['', [Validators.required]],
      beneficiaire: [''],
      superAdmin_id: ['', [Validators.required]],
      controleur_id: [''],
      oblige_id: ['', [Validators.required]],
      stage: ['', [Validators.required]],
      offre_automatique: [],
      mpr_id: ['', [Validators.required]],
      offre_file_name: [{ disabled: true, value: '' }],
      montant: [''],
      prixUnitaire: [''],
      resultat: [
        { value: '', disabled: true },
        [Validators.required, Validators.max(999)],
      ],
      clientPart: [{ value: '', disabled: true }, [Validators.required]],
      coursEmmy: [''],
      is_blocked: [false],
      societe_id: ['', [Validators.required]],
      commentaire: [''],
      source: ['', [Validators.required]],
      clientTaux: [
        { value: '', disabled: true },
        [Validators.required, Validators.min(0)],
      ],
      partenaireTaux: [
        { value: '', disabled: true },
        [Validators.required, Validators.min(0)],
      ],
      idProjetcrm: [''],
      idworkdrive: [{ disabled: true, value: '' }],
      primeRenovAmount: ['', [Validators.required, Validators.min(0)]],
      collaborateur_id: [''],
    });

    this.obligesService.getAllObliges().subscribe((obliges: any) => {
      this.obliges = obliges;
    });

    this.projetsService.getAllActiveStatuts().subscribe((statuts: any) => {
      this.statuts = statuts;
    });

    this.projetsService.getAllStatutsMPR().subscribe((statuts: any) => {
      this.statuts_MPR = statuts;
    });

    this.societesService
      .getFilterSocietes(1, 100000, {})
      .subscribe((data: any) => {
        this.societes = data.rows;
      });

    this.adminsService.getAllControleurs().subscribe((controleurs: any) => {
      this.controleurs = controleurs.rows;
    });

    this.adminsService.getAllCommercials().subscribe((commercials: any) => {
      this.commercials = commercials.rows;
    });

    this.neoscopeService
      .getClientsNeoscopeByIDs(this.authService.getUserClientsAccess())
      .subscribe((res: any) => {
        this.clients = res;
      });
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    this.isFormModified = !this.editForm.pristine;
    return this.isFormModified;
  }

  editProject() {
    if (this.editForm.valid) {
      this.isFormSubmitting = true;
      const payload = { ...this.projet };
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });
      console.log(payload);
      this.projetsService
        .editProject(this.projet_id, payload)
        .subscribe((updatedProjet: any) => {
          this.projet = updatedProjet;
          this.createBasicMessage();
          this.router.navigate(['/projets']);
        });
    } else {
      console.log('Form is invalid');
      console.log(this.editForm.controls);
      const payload: any = [];

      Object.keys(this.editForm.controls).forEach((key) => {
        console.log(key);
        console.log(this.editForm.controls[key].value);
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });
      console.log(payload);
      Object.values(this.editForm.controls).forEach((control) => {
        if (control.invalid) {
          console.log('invalid', control);
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  createBasicMessage(): void {
    this.message.success(
      'Vos modifications ont été enregistrées avec succès.',
      {
        nzDuration: 3000,
      }
    );
  }
  goBack() {
    this.router.navigate(['/projets']);
  }
}
