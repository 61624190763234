<div class="messages-container" #messageContainer>
  <div
    *ngFor="let message of messages"
    [ngClass]="{
      'current-user-message': message.auteur === currentUser.username,
      'non-user-message': message.auteur !== currentUser.username
    }"
    class="message"
  >
  <div class="avatar">
    <ng-container *ngIf="message.isFromPartenaire; else defaultAvatar">
      <nz-avatar
        nzIcon="user"
        nzSrc="https://new.dev.neoscope.eu/assets/images/userMadame.png"
      ></nz-avatar>
    </ng-container>
    <ng-template #defaultAvatar>
      <nz-avatar
        class="message-sender"
        nzText="{{ message.auteur.slice(0, 2).toUpperCase() }}"
        style="background-color: #87be4b"
      ></nz-avatar>
    </ng-template>
  </div>
    <div class="message-body">
      <div class="message-content">
        <div *ngIf="message.filename">
          <a (click)="download(message.filename)">{{ extractName(message.filename) }}</a>
        </div>
        <div class="message-text" [innerHTML]="message.contenu | linkify"></div>
      </div>
      <div class="message-time-wrapper">
        <span class="message-time">{{ message.createdAt | date: 'HH:mm' }}</span>
      </div>
    </div>

  </div>
</div>
