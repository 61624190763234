import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminsService } from 'src/app/services/admins.service';
import { AuthService } from 'src/app/services/auth.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';

@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrl: './create-admin.component.css',
})
export class CreateAdminComponent {
  createForm: FormGroup;
  isFormModified: boolean;
  passwordVisible = false;
  confirmpasswordVisible = false;
  clients: any[] = [];
  clients_access: any[];
  current_admin: any;

  constructor(
    private fb: FormBuilder,
    private adminService: AdminsService,
    private message: NzMessageService,
    private router: Router,
    private neoscopeService: NeoscopeService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.createForm = this.fb.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        username: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        id_crm: [''],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        role: ['', Validators.required],
        client_id: ['', Validators.required],
        clients_access: [[], Validators.required],
      },
      { validator: this.checkPasswords }
    );

    this.authService.getCurrentUser().subscribe({
      next: (res) => {
        this.current_admin = res;
        if (this.current_admin.role === 'ROLE_SUPER_ADMIN') {
          this.neoscopeService
            .getAllClientsNeoscope(1, 100, {})
            .subscribe((res: any) => {
              this.clients = res.rows;
            });
        } else {
          const clients_scope = this.authService.getUserClientsAccess();
          clients_scope.forEach((client_id: number) => {
            this.neoscopeService
              .getClientNeoscope(client_id)
              .subscribe((client) => {
                this.clients.push(client);
              });
          });
        }
      },
      error: (err) => {
        throw err;
      },
    });

    this.createForm.valueChanges.subscribe(() => {
      if (this.createForm.hasError('notSame')) {
        this.createForm.get('confirmPassword')?.setErrors({ notSame: true });
      } else {
        this.createForm.get('confirmPassword')?.setErrors(null);
      }
    });
  }

  createBasicMessage(): void {
    this.message.success('La création a été effectuée avec succès.', {
      nzDuration: 3000,
    });
  }

  createAdmin(): void {
    if (this.createForm.valid) {
      const payload = { ...this.createForm.value, is_active: false };
      delete payload.confirmPassword;
      this.adminService.createAdmin(payload).subscribe((newAdmin: any) => {
        this.createBasicMessage();
        this.createForm.markAsPristine();
        this.router.navigate(['/administrateurs']);
      });
    } else {
      console.log('Form is invalid');
      Object.values(this.createForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  checkFormChanges(): boolean {
    this.isFormModified = !this.createForm.pristine;
    return this.isFormModified;
  }

  goBack(): void {
    this.router.navigate(['/administrateurs']);
  }

  private checkPasswords(group: FormGroup) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { notSame: true };
  }
}
