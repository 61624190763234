import {HTTP_INTERCEPTORS, HttpInterceptorFn, HttpRequest} from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next) => {
  const cloneReq = request.clone({
    withCredentials: true
  });
  return next(cloneReq);
};



