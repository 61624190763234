import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormRecord,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminsService } from 'src/app/services/admins.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.css',
})
export class EditProfileComponent {
  editForm: FormGroup;
  passwordForm: FormGroup;
  oblige: any;
  oblige_id: number;
  isFormModified: boolean;
  currentUser: any;
  isFormSubmitting: boolean = false;
  isVisible: boolean = false;
  uploading: boolean = false;
  passwordVisible: boolean = false;
  newpasswordVisible: boolean = false;
  newpasswordconfirmedVisible: boolean = false;
  constructor(
    private authService: AuthService,
    private fb: NonNullableFormBuilder,
    private AdminsService: AdminsService,
    private message: NzMessageService,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.authService.getCurrentUser().subscribe({
      next: (res) => {
        this.currentUser = res;
        this.loadingService.offLoading();
      },
      error: (err) => {
        throw err;
      },
    });
    this.editForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      username: ['', Validators.required],
    });
    this.passwordForm = this.fb.group({
      oldpassword: ['', Validators.required],
      newpassword: [
        '',
        [
          Validators.required,
          this.hasSpecialCharacter,
          Validators.minLength(8),
        ],
      ],
      newpasswordconfirmed: ['', Validators.required],
    });
    this.passwordForm.valueChanges.subscribe(() => {
      this.passwordForm.get('newpassword')?.value ===
      this.passwordForm.get('newpasswordconfirmed')?.value
        ? this.passwordForm.get('newpasswordconfirmed')?.setErrors(null)
        : this.passwordForm
            .get('newpasswordconfirmed')
            ?.setErrors({ notSame: true });
    });
  }

  hasSpecialCharacter(control: any) {
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (specialCharacters.test(control.value)) {
      return null;
    }
    return { specialCharacter: true };
  }

  ChangePassword(): void {
    if (this.passwordForm.valid) {
      this.uploading = true;
      this.isVisible = false;
      this.authService
        .resetAdminPassword(
          this.passwordForm.get('oldpassword')?.value,
          this.passwordForm.get('newpassword')?.value
        )
        .subscribe(
          (result: any) => {
            this.createBasicMessage();
            this.passwordForm.reset();
          },
          (err: any) => {
            if (err.status === 401) {
              this.createWrongCredentialsError();
            } else {
              this.createFailureMessage();
              this.passwordForm.reset();
            }
          }
        );
      this.uploading = false;
    } else {
      Object.values(this.passwordForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  createBasicMessage(): void {
    this.message.success('Vos modifications ont été enregistrées avec succès.', {
      nzDuration: 3000,
    });
  }

  createFailureMessage(): void {
    this.message.error(
      'Une erreur est survenue. Veuillez réessayer plus tard ou contacter le service informatique.',
      {
        nzDuration: 3000,
      }
    );
  }

  createWrongCredentialsError(): void {
    this.message.error('mot de passe incorrect', {
      nzDuration: 3000,
    });
  }

  editAdmin(): void {
    if (this.editForm.valid) {
      const payload = { ...this.currentUser };
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });
      this.AdminsService.editAdmin(this.currentUser.id, payload).subscribe(
        (updatedAdmin: any) => {
          this.isFormSubmitting = true;
          this.currentUser = updatedAdmin;
          this.createBasicMessage();
        }
      );
    } else {
      Object.values(this.editForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    this.isFormModified = !this.editForm.pristine;
    return this.isFormModified;
  }
  goBack(): void {}

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
