import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-channel-header',
  templateUrl: './channel-header.component.html',
  styleUrl: './channel-header.component.css'
})
export class ChannelHeaderComponent {
  @Input() data: any;

}
