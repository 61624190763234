import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

import { ConfigService } from '../../../services/config.service';
import { ColumnItem } from '../../../utils/columnItem.interface';

@Component({
  selector: 'app-list-bandeau-info',
  templateUrl: './list-bandeau-info.component.html',
  styleUrls: ['./list-bandeau-info.component.css'],
})
export class ListBandeauInfoComponent implements OnInit {
  queryForm: FormGroup;
  data: any[] = [];
  filteredData: any[] = [];
  isLoadingData: boolean = true;
  totalBandeaus: number = 0;

  listOfColumns: ColumnItem[] = [
    {
      name: 'Intitulé',
      sortOrder: null,
      sortFn: (a: any, b: any) => a.id - b.id,
      sortDirections: ['ascend', 'descend', null],
    },
    {
      name: 'Client',
      sortOrder: null,
      sortFn: (a: any, b: any) => a.plateforme.localeCompare(b.plateforme),
      sortDirections: ['ascend', 'descend', null],
    },
    { name: 'Activé', sortOrder: null, sortFn: null, sortDirections: [null] },
    {
      name: "Date de début d'affichage",
      sortOrder: null,
      sortFn: (a: any, b: any) => a.start_date.localeCompare(b.start_date),
      sortDirections: ['ascend', 'descend', null],
    },
    {
      name: "Date de fin d'affichage",
      sortOrder: null,
      sortFn: (a: any, b: any) => a.end_date.localeCompare(b.end_date),
      sortDirections: ['ascend', 'descend', null],
    },
  ];

  constructor(
    private fb: FormBuilder,
    private configService: ConfigService,
    private router: Router,
    private readonly authService: AuthService
  ) {
    this.queryForm = this.fb.group({
      search_text: [''],
    });
  }

  ngOnInit(): void {
    this.loadAllData();

    if (this.canEdit('BANDEAU-INFO')) {
      this.listOfColumns.push({
        name: 'Actions',
        sortOrder: null,
        sortFn: null,
        sortDirections: [null],
      });
    }
  }

  loadAllData() {
    this.isLoadingData = true;
    this.configService.getAllBandeau().subscribe({
      next: (bandeaus: any) => {
        this.data = bandeaus.map((bandeau: any) => ({
          ...bandeau,
          checked: false,
        }));
        this.totalBandeaus = bandeaus.length;
        this.filteredData = this.data;
        this.isLoadingData = false;
      },
      error: () => {
        this.isLoadingData = false;
      },
    });
  }

  onSearchChange(): void {
    const search_text = this.queryForm.value.search_text;
    if (search_text && search_text !== '') {
      this.filteredData = this.data.filter((bandeau) => {
        return (
          bandeau.label.toLowerCase().includes(search_text) ||
          bandeau.plateforme.toLowerCase().includes(search_text)
        );
      });
    } else {
      this.filteredData = this.data;
    }
  }

  editBandeau(id: number, label: string) {
    this.router.navigate([`/bandeau-info/edit/${id}`]).then((r) => r);
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }
}
