<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit></ng-template>
  <div class="form-container">
    <div class="title">Ajouter une fiche</div>
    <form
      (ngSubmit)="createReglementaire()"
      [formGroup]="createForm"
      class="form-layout"
      nz-form
    >
      <div class="row-content" nz-row>
        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label
              [nzSpan]="8"
              nz-col
              nzFor="numero"
              nzLabelWrap
              nzRequired
            >Numéro (Réf. FOST)
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="Numéro est obligatoire"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input formControlName="numero" id="numero" nz-input/>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Titre
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="Titre est obligatoire"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input formControlName="titre" nz-input/>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row-content" nz-row>
        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Version FOST
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="Veuillez renseigner une version Fost Valide"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input formControlName="version_simulateur" nz-input/>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Date de la version
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="La date de la version est obligatoire"
            >
              <nz-date-picker
                class="full-width default-border-radius"
                formControlName="createdAt"
                nzFormat="dd/MM/yyyy"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div [nzSpan]="14" nz-col nzSm="12" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="8" nz-col nzLabelWrap
          >Contrôle sur site
          </nz-form-label
          >
          <nz-form-control [nzSpan]="14" nz-col>
            <nz-switch
              [nzCheckedChildren]="'Oui'"
              [nzUnCheckedChildren]="'Non'"
              formControlName="IsControlled"
            ></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>


      <div class="row-content" nz-row>
        <div [nzSpan]="24" nz-col nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="4" nz-col nzLabelWrap
            >Infobulle à afficher (150 caractères max)
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="19"
              nz-col
              nzErrorTip="Infobulle est obligatoire"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <textarea
                  formControlName="infobulle"
                  maxlength="150"
                  nz-input
                  rows="2"
                ></textarea>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="custom-quill-container">
        <nz-form-item>
          <nz-form-label [nzSpan]="4" nz-col nzLabelWrap>Informations obligatoires</nz-form-label>
          <nz-form-control>
            <div [nzSpan]="23" class="quill-wrapper"
                 nz-col>
              <quill-editor
                [modules]="quillModules"
                formControlName="infos_obligatoires"
                placeholder="Informations obligatoires ..."
              ></quill-editor>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            [disabled]="!checkFormChanges() || createForm.invalid"
            class="circle-button small save-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Sauvegarder"
            type="submit"
          >
            <span class="icon">
              <span nz-icon nzType="save"></span>
            </span>
          </button>
          <button
            (click)="goBack()"
            class="circle-button small cancel-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Annuler"
          >
            <span class="icon">
              <span nz-icon nzType="rollback"></span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
