import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

import { AppModule } from '../../../app.module';
import { SidebarService } from '../../../services/sidebar.service';

@Component({
  selector: 'app-authenticated-layout',
  templateUrl: './authenticated-layout.component.html',
  styleUrl: './authenticated-layout.component.css',
})
export class AuthenticatedLayoutComponent {
  constructor(
    protected sidebarService: SidebarService,
    protected readonly loadingService: LoadingService
  ) {}
}
