<div class="input-container">
  <span
    (click)="triggerFileInput()"
    class="paper-clip-icon"
    nz-icon
    nzTheme="outline"
    nzType="paper-clip">
  </span>
  <div class="search-bar">
    <nz-input-group [nzSuffix]="suffixIconSearch" class="main-search">
      <textarea
        (keyup.enter)="sendMessage()"
        [(ngModel)]="newMessage"
        class="main-search-textarea"
        nz-input
        placeholder="Ecrivez votre message"
      ></textarea>
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <span *ngIf="selectedFile" class="file-info">{{ selectedFile.name }}</span>
      <span (click)="sendMessage()" class="search-icon" nz-icon nzType="send"></span>
    </ng-template>
  </div>
  <input
    (change)="onFileSelected($event)"
    id="fileInput"
    style="display: none;"
    type="file"
  />
</div>
