import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, filter, firstValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpClient, private socket: Socket) {
    this.socket.ioSocket.on('error', (error: any) => {
      console.error('Socket error:', error);
      // Implement your error handling logic here
    });

    this.socket.ioSocket.on('connect_error', (error: any) => {
      console.error('Connection error:', error);
      // Handle connection errors, possibly retry or notify the user
    });

    this.socket.ioSocket.on('reconnect_error', (error: any) => {
      console.error('Reconnection error:', error);
      // Handle reconnection errors
    });
  }

  getAllDiscussionsFilter(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/chat/page=${page}&limit=${limit}`,
      query
    );
  }

  delete(id: number) {
    return firstValueFrom(this.http.delete(`${environment.apiUrl}/chat/${id}`));
  }

  getAllMessages(id: number) {
    return this.http.get(`${environment.apiUrl}/chat/${id}`);
  }

  joinRoom(room: string) {
    this.socket.emit('joinRoom', room);
  }

  leaveRoom(room: string) {
    this.socket.emit('leaveRoom', room);
  }

  sendMessage(roomname: string, message: string, file?: any) {
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const fileData = {
          originalname: file?.name,
          buffer: reader.result,
        };

        this.socket.emit('sendMessage', {
          message,
          room: roomname,
          file: fileData,
          isFromPartenaire: false,
          vu: false,
        }, (response: any) => {
          console.log('Server response:', response);
        });
      };
      console.log('reading');
      reader.readAsArrayBuffer(file);
    } else {
      this.socket.emit('sendMessage', {
        message,
        room: roomname,
        isFromPartenaire: false,
        vu: false,
      });
    }
  }

  downloadFile(filename: any) {
    return this.http.get(`${environment.apiUrl}/chat/file/${filename}`, {
      responseType: 'blob',
    });
  }

  createDiscussion(discussion: any) {
    return this.http.post(`${environment.apiUrl}/chat/create`, discussion);
  }

  onMessage(): Observable<any> {
    return this.socket.fromEvent('message');
  }

  // onMessages(): Observable<any> {
  //   return this.socket.fromEvent('messages');
  // }
}
