<div class="custom-container">
  <nz-card class="custom-card">
    <div class="logo-container">
      <img alt="Logo" class="logo-img" src="assets/icons/neovee-vert.png" />
    </div>
    <form [formGroup]="form" class="custom-form">
      <nz-spin [nzSpinning]="loading" class="loading">
        <fieldset>
          <div class="custom-form-field">
            <label class="custom-label" for="username">Email</label>
            <input
              [ngClass]="{
                'error-input':
                  (form.get('email')?.errors && form.get('email')?.touched) ||
                  invalidLogin
              }"
              class="custom-input"
              formControlName="email"
              id="username"
              name="email"
              nz-input
              placeholder="name@example.com"
              type="text"
            />

            <p
              *ngIf="form.get('email')?.errors && form.get('email')?.touched"
              class="error-message"
            >
              Veuillez entrer une adresse email valide
            </p>
          </div>
          <div class="custom-form-field">
            <label class="custom-label" for="password">Mot de passe</label>
            <nz-input-group
              [ngClass]="{
                'error-input':
                  (form.get('password')?.errors &&
                    form.get('password')?.touched) ||
                  invalidLogin
              }"
              [nzSuffix]="suffixTemplate"
              class="password-inp"
            >
              <input
                [ngClass]="{
                  'error-input':
                    (form.get('password')?.errors &&
                      form.get('password')?.touched) ||
                    invalidLogin
                }"
                [type]="passwordVisible ? 'text' : 'password'"
                formControlName="password"
                id="password"
                name="password"
                nz-input
                placeholder="******************"
              />
              <ng-template #suffixTemplate>
                <span
                  (click)="passwordVisible = !passwordVisible"
                  [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                  nz-icon
                ></span>
              </ng-template>
            </nz-input-group>
          </div>
        </fieldset>
        <div class="form-actions">
          <button
            (click)="login()"
            [disabled]="form.invalid"
            class="custom-button"
            nz-button
            type="submit"
          >
            Se connecter
          </button>
          <div class="forgot-password">
            <a class="forgot-password-link" routerLink="/forget-password"
              >Mot de passe oublié ?</a
            >
          </div>
        </div>
      </nz-spin>
    </form>
  </nz-card>
</div>
