<div class="container">
  <div class="card">
    <div class="card-body">
      <nz-spin [nzSize]="'large'" [nzSpinning]="loading">
        <span nz-icon nzTheme="outline" nzType="lock" style="visibility: initial; font-size: 36px"></span>
        <h3 class="title">Vérification de votre compte</h3>
        <p class="description">
          Nous avons envoyé un code de vérification à votre adresse e-mail. Veuillez entrer le code ci-dessous pour
          vérifier votre compte.
        </p>
        <form (ngSubmit)="verifyCode()" [formGroup]="form">
          <div class="input-group">
            <div *ngFor="let control of codeControls; let i = index" class="input-wrapper">
              <input
                (click)="onClick($event, i)"
                (input)="onInput($event, i)"
                (keyup)="onUpdate($event, i)"
                (paste)="onPaste($event)"
                [formControl]="control"
                class="code-input"
                id="{{ i }}"
                type="number"
                maxlength="1"
              />
            </div>
          </div>
          <button class="btn-verify" type="submit">Vérifier mon compte</button>
        </form>
        <p class="resend-code">
          Vous n'avez pas reçu le code ?
          <br/>
          <a (click)="resendCode()" class="link_text">Renvoyer le code</a>
        </p>
      </nz-spin>
    </div>
  </div>
</div>
