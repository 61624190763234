import {Component} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormRecord,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {LoadingService} from 'src/app/services/loading.service';
import {ReglementairesService} from 'src/app/services/reglementaires.service';

@Component({
  selector: 'app-edit-reglementaires',
  templateUrl: './edit-reglementaires.component.html',
  styleUrl: './edit-reglementaires.component.css',
})
export class EditReglementairesComponent {
  editForm: FormGroup;
  reglementaire: any;
  numero: string;
  isFormModified: boolean;
  reglementaireName: any;
  isFormSubmitting: boolean = false;

  controlArray: Array<{ index: number; show: boolean }> = [];
  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{header: 1}, {header: 2}],
      [{list: 'ordered'}, {list: 'bullet'}],
      [{script: 'sub'}, {script: 'super'}],
      [{indent: '-1'}, {indent: '+1'}],
      [{direction: 'rtl'}],
      [{size: ['small', false, 'large', 'huge']}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      [{color: []}, {background: []}],
      [{font: []}],
      [{align: []}],
      ['clean'],
      ['link', 'image', 'video']
    ]
  };

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private reglementairesService: ReglementairesService,
    private message: NzMessageService,
    private router: Router,
    private readonly loadingService: LoadingService
  ) {
  }

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.editForm = this.fb.group({
      titre: ['', Validators.required],
      version_simulateur: ['', [
        Validators.required,
        Validators.pattern(/^A\d{2}-\d{1,2}$/)
      ]],
      infobulle: [''],
      IsControlled: [, Validators.required],
      createdAt: [],
      infos_obligatoires: ['']
    });
    this.numero = this.route.snapshot.params['numero'];
    this.reglementairesService
      .getReglementaireByNumero(this.numero)
      .subscribe((reglementaire: any) => {
        this.reglementaire = reglementaire;
        this.loadingService.offLoading();
      });
  }

  createBasicMessage(): void {
    this.message.success('Vos modifications ont été enregistrées avec succès.', {
      nzDuration: 3000,
    });
  }

  editReglementaire() {
    if (this.editForm.valid) {
      const payload = {...this.reglementaire};
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });
      this.reglementairesService
        .editReglementaire(this.numero, payload)
        .subscribe((updatedReglementaire: any) => {
          this.isFormSubmitting = true;
          this.createBasicMessage();
          this.router.navigate(['/reglementaire']);
        });
    } else {
      console.log('Form is invalid');
      Object.values(this.editForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    return !this.editForm.pristine;
  }

  goBack() {
    this.router.navigate(['/reglementaire']);
  }
}
