<nz-content>
  <div class="inner-content">
    <div nz-row nzAlign="middle">
      <div nz-col nzSpan="21">
        <h2 class="categorie-title">
          {{ client_neoscope.name }}
        </h2>
        <nz-tag class="timestamp timestamp-tag" [nzBordered]="true">
          <span nz-icon nzType="clock-circle"></span>
          <span
            >Depuis :
            {{ client_neoscope.created_at | date : "dd/MM/YYYY" }}</span
          ></nz-tag
        >
        <nz-tag
          class="timestamp timestamp-tag"
          [nzColor]="getStatus(client_neoscope).color"
          [nzBordered]="false"
          class="status-tag"
        >
          <span>{{ getStatus(client_neoscope).text }}</span>
        </nz-tag>
      </div>
      <div nz-col nzSpan="3" class="align-text-right">
        <button
          nz-button
          nzType="default"
          class="secondary-button"
          routerLink="/clients-neoscope/edit/{{ client_neoscope.id }}"
        >
          <span nz-icon nzType="edit"></span>
          Éditer
        </button>
      </div>
    </div>
    <nz-tabset [nzAnimated]="true" [nzCentered]="true" >
      <nz-tab [nzTitle]="config_neoscope">
        <ng-template #config_neoscope>
          <span nz-icon nzType="global"></span>
          Configuration Neoscope
        </ng-template>
        <div *ngIf="active_tab === 'CONFIG_NEOSCOPE'">
          <div class="margin-top-20" nz-row nzGutter="22">
            <div nz-col nzSpan="14">
              <nz-card class="box">
                <h3>
                  <span nz-icon nzType="user" nzTheme="outline"></span>
                  Identification
                </h3>
                <div nz-row>
                  <div nz-col [nzSpan]="9" class="field-grey-label">
                    URL Neoscope
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="14" class="align-text-right">
                    {{
                      "https://" +
                        configuration_client.url_neoscope +
                        ".neoscope.eu"
                    }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="9" class="field-grey-label">Titre Neoscope</div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="14" class="align-text-right">
                    {{ configuration_client.title }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="9" class="field-grey-label">Tag Client</div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="14" class="align-text-right">
                    {{ client_neoscope.tag }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="9" class="field-grey-label">
                    Adresse
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="14" class="align-text-right">
                    {{ configuration_client.adresse }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="9" class="field-grey-label">
                    Téléphone
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="14" class="align-text-right">
                    {{ configuration_client.phone }}
                  </div>
                </div>
              </nz-card>
            </div>
            <div nz-col nzSpan="10">
              <nz-card class="box">
                <h3>
                  <span nz-icon nzType="fund-view" nzTheme="outline"></span>
                  Choix des couleurs
                </h3>
                <div nz-row>
                  <div nz-col [nzSpan]="11" class="field-grey-label">
                    Couleur principale
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="12" class="align-text-right">
                    <nz-color-picker
                      nzDisabled
                      nzShowText
                      nzSize="small"
                      [(ngModel)]="configuration_client.primary_color"
                    ></nz-color-picker>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="11" class="field-grey-label">
                    Couleur secondaire (textes)
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="12" class="align-text-right">
                    <nz-color-picker
                      nzDisabled
                      nzShowText
                      nzSize="small"
                      [(ngModel)]="configuration_client.secondary_color"
                    ></nz-color-picker>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="11" class="field-grey-label">
                    Couleur Header
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="12" class="align-text-right">
                    <nz-color-picker
                      nzDisabled
                      nzShowText
                      nzSize="small"
                      [(ngModel)]="configuration_client.header_bg_color"
                    ></nz-color-picker>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="11" class="field-grey-label">
                    Couleur Footer
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="12" class="align-text-right">
                    <nz-color-picker
                      nzDisabled
                      nzShowText
                      nzSize="small"
                      [(ngModel)]="configuration_client.footer_bg_color"
                    ></nz-color-picker>
                  </div>
                </div>
              </nz-card>
            </div>
          </div>
          <div class="margin-top-20" nz-row nzGutter="22">
            <div nz-col nzSpan="8">
              <nz-card class="box">
                <h3>
                  <span nz-icon nzType="file-image" nzTheme="outline"></span>
                  Logo
                </h3>
                <div nz-row>
                  <div nz-col [nzSpan]="24" class="center">
                    <img nz-image width="200px" [src]="logo" alt="" />
                  </div>
                </div>
              </nz-card>
            </div>
            <div nz-col nzSpan="8">
              <nz-card class="box">
                <h3>
                  <span nz-icon nzType="file-image" nzTheme="outline"></span>
                  Favicon
                </h3>
                <div nz-col [nzSpan]="24" class="center">
                  <img nz-image width="45px" [src]="favicon" alt="" />
                </div>
              </nz-card>
            </div>
            <div nz-col nzSpan="8">
              <nz-card class="box">
                <h3>
                  <span nz-icon nzType="file-image" nzTheme="outline"></span>
                  Icône PWA
                </h3>
                <div nz-col [nzSpan]="24" class="center">
                  <img nz-image width="45px" [src]="icon_pwa" alt="" />
                </div>
              </nz-card>
            </div>
          </div>   
          <div class="margin-top-20" nz-row nzGutter="22">
            <div nz-col nzSpan="24">
              <nz-card class="box">
                <h3>
                  <span nz-icon nzType="link" nzTheme="outline"></span> Liens
                  utiles
                </h3>
                <div nz-row>
                  <div nz-col [nzSpan]="5" class="field-grey-label">
                    Site Web
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="18" class="align-text-right">
                    {{ configuration_client.website }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="5" class="field-grey-label">
                    Lien Réseau Social
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="18" class="align-text-right">
                    {{ configuration_client.url_social_media }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="5" class="field-grey-label">Page CGU</div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="18" class="align-text-right">
                    {{ configuration_client.url_cgu }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="5" class="field-grey-label">Page CGV</div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="18" class="align-text-right">
                    {{ configuration_client.url_cgv }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="5" class="field-grey-label">
                    Page contact
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="18" class="align-text-right">
                    {{ configuration_client.url_contact }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="5" class="field-grey-label">
                    Page mentions légales
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="18" class="align-text-right">
                    {{ configuration_client.url_mentions_legales }}
                  </div>
                </div>
              </nz-card>
            </div>
          </div>
          <div class="margin-top-20" nz-row nzGutter="22">
            <div nz-col nzSpan="8">
              <nz-card class="box">
                <h3>
                  <span nz-icon nzType="file-image" nzTheme="outline"></span>
                  Configuration CRM
                </h3>
                <div nz-row>
                  <div nz-col [nzSpan]="11" class="field-grey-label">
                    CRM utilisé
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="12" class="align-text-right">
                    {{ client_neoscope.crm }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="11" class="field-grey-label">
                    ID CRM
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="12" class="align-text-right">
                    {{ client_neoscope.id_crm }}
                  </div>
                </div>
              </nz-card>
            </div>
            <div nz-col nzSpan="16">
              <nz-card class="box">
                <h3>
                  <span nz-icon nzType="file-text" nzTheme="outline"></span>
                  Texte Footer
                </h3>
                <div nz-row>
                  <div nz-col [nzSpan]="24">
                    {{ configuration_client.footer_text }}
                  </div>
                </div>
              </nz-card>
            </div>
          </div>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="obliges">
        <ng-template #obliges>
          <span nz-icon nzType="contacts"></span>
          Obligés
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="prix">
        <ng-template #prix>
          <span nz-icon nzType="euro"></span>
          Prix
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="admins">
        <ng-template #admins>
          <span nz-icon nzType="team"></span>
          Administrateurs
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="gestion_commerciale">
        <ng-template #gestion_commerciale>
          <span nz-icon nzType="apartment"></span>
          Gestion commerciale
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="statuts_cee">
        <ng-template #statuts_cee>
          <span nz-icon nzType="tags"></span>
          Statuts CEE & MPR
        </ng-template>
      </nz-tab>
    </nz-tabset>
  </div>
</nz-content>
