<nz-content class="content-wrapper">
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div nz-row class="align-items">
          <h2 class="element-details-title">
            {{ societe.name }}
          </h2>
        </div>
      </div>
      <div nz-col nzSpan="8" class="action-buttons">
        <div nz-row nzJustify="end">
          <div nz-col>
            <button
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement ce bug ?"
              nzType="default"
              (nzOnConfirm)="confirmDelete()"
              (nzOnCancel)="cancel()"
              [nzIcon]="popConfirmIcon"
              *ngIf="canDelete('SOCIETES')"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
            <button
              nz-button
              nzType="default"
              class="secondary-button"
              class="secondary-button mass-action-button margin-left-10"
              routerLink="/societes/edit/{{ societe.id }}"
              *ngIf="canEdit('SOCIETES')"
            >
              <span nz-icon nzType="edit"></span>
              Éditer
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>

    <h4>
      <span nz-icon nzType="solution" nzTheme="outline"></span> Détails société
    </h4>

    <div nz-row class="details-container">
      <div nz-col nzSpan="24" nzSm="24" nzXs="24">
        <nz-card class="box">
          <h4 class="info-card-title">
            <span nz-icon nzType="idcard" nzTheme="outline"></span>
            Identification
          </h4>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">
              Raison sociale
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">{{ societe?.name }}</div>
          </div>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">
              Secteur CEE principal
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">
              {{ societe?.secteur_principal }}
            </div>
          </div>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">
              Chargé d'affaires
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">
              <a routerLink="/administrateurs/{{ societe.gestionnaire?.id }}"
                >{{ societe.gestionnaire?.first_name }}
                {{ societe.gestionnaire?.last_name }}</a
              >
            </div>
          </div>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">
              Secteur d'activité
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">
              {{ societe?.secteur_activite }}
            </div>
          </div>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">SIRET</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">
              {{ societe?.siret | siret }}
            </div>
          </div>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">Client</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">{{ societe?.source }}</div>
          </div>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">
              N° client externe
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">
              {{ societe?.num_client_ext }}
            </div>
          </div>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">Classement</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">
              {{ societe?.classement }}
            </div>
          </div>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">ID CRM</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">
              <a
                href="https://crm.zoho.eu/crm/org20063788205/tab/CustomModule1/{{
                  societe?.idPartenaireCrm
                }}"
                target="_blank"
              >
                {{ societe?.idPartenaireCrm }}</a
              >
            </div>
          </div>
        </nz-card>
      </div>
    </div>
    <div nz-row class="details-container">
      <nz-card class="box">
        <h4 class="info-card-title">
          <span nz-icon nzType="setting" nzTheme="outline"></span> Paramétrage
        </h4>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Catégorie de prix
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <a routerLink="/categories-prix/{{ societe.categorie.code }}">
              {{ societe?.categorie?.nom }}</a
            >
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Obligé résidentiel hors Coup de pouce
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <a routerLink="/obliges/{{ societe.oblige?.id }}">{{
              societe.oblige?.nom
            }}</a>
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Coup de pouce chauffage résidentiel individuel
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <nz-tag *ngIf="societe?.isCPResidentielChauffage" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.isCPResidentielChauffage" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div
          nz-row
          class="element-detail-row"
          *ngIf="societe?.isCPResidentielChauffage"
        >
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Obligé Coup de pouce chauffage résidentiel individuel précaire
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <a routerLink="/obliges/{{ societe.oblige_precaire?.id }}">{{
              societe.oblige_precaire?.nom
            }}</a>
          </div>
        </div>
        <div
          nz-row
          class="element-detail-row"
          *ngIf="societe?.isCPResidentielChauffage"
        >
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Obligé Coup de pouce chauffage résidentiel individuel classique
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <a routerLink="/obliges/{{ societe.oblige_cdp?.id }}">{{
              societe.oblige_cdp?.nom
            }}</a>
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Coup de pouce rénovation globale résidentiel collectif
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <nz-tag *ngIf="societe?.isCPRenoGlobaleCollectif" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.isCPRenoGlobaleCollectif" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div
          nz-row
          class="element-detail-row"
          *ngIf="societe?.isCPRenoGlobaleCollectif"
        >
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Obligé Coup de pouce rénovation globale résidentiel collectif
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <a
            routerLink="/obliges/{{ societe.oblige_cdp_reno_globale_col?.id }}"
            >{{ societe.oblige_cdp_reno_globale_col?.nom }}</a
          >
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Coup de pouce chauffage tertiaire et résidentiel collectif
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <nz-tag *ngIf="societe?.isCPTertiaireChauffage" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.isCPTertiaireChauffage" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div
          nz-row
          class="element-detail-row"
          *ngIf="societe?.isCPTertiaireChauffage"
        >
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Obligé Coup de pouce tertiaire et résidentiel collectif
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <a
              routerLink="/obliges/{{ societe.oblige_cdp_bat_res_coll?.id }}"
              >{{ societe.oblige_cdp_bat_res_coll?.nom }}</a
            >
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Coup de pouce pilotage connecté
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <nz-tag *ngIf="societe?.isCPPilotageConnecte" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.isCPPilotageConnecte" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div
          nz-row
          class="element-detail-row"
          *ngIf="societe?.isCPPilotageConnecte"
        >
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Obligé Coup de pouce pilotage connecté
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <a
              routerLink="/obliges/{{
                societe.oblige_cdp_pilotage?.id
              }}"
              >{{ societe.oblige_cdp_pilotage?.nom }}</a
            >
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="11" class="field-grey-label">
            Accès partagé aux projets
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="12" class="wrap">
            <nz-tag *ngIf="societe?.shared_access" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.shared_access" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
      </nz-card>
    </div>
    <div nz-row class="details-container">
      <div nz-col nzSpan="24" nzSm="24" nzXs="24">
        <nz-card class="box">
          <h4>
            <span nz-icon nzType="profile" nzTheme="outline"></span> Profil
          </h4>
          <div nz-row class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">
              Apporteur d'affaires
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12" class="wrap">
              <nz-tag *ngIf="societe?.isApporteurAffaire" nzColor="success">
                <span>Oui</span>
              </nz-tag>
              <nz-tag *ngIf="!societe?.isApporteurAffaire" nzColor="error">
                <span>Non</span>
              </nz-tag>
            </div>
          </div>
          <div nz-row *ngIf="isApporteurAffaire" class="element-detail-row">
            <div nz-col [nzSpan]="11" class="field-grey-label">
              Entreprises travaux partenaires
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="12">
              <div *ngFor="let et of entreprises_travaux">
                {{ et.nom }}
              </div>
            </div>
          </div>
        </nz-card>
      </div>
    </div>
    <div nz-row class="details-container">
      <nz-card class="box">
        <h4 class="info-card-title">
          <span nz-icon nzType="team" nzTheme="outline"></span> Utilisateurs
        </h4>
        <ul nz-list [nzDataSource]="societe.users" nzSize="small">
          @for (user of societe.users; track user) {
          <li nz-list-item nzNoFlex>
            <ul nz-list-item-actions></ul>
            <a href="/utilisateurs/{{ user.id }}" target="_blank">{{
              user.firstname + " " + user.lastname
            }}</a>
          </li>
          }
        </ul>
      </nz-card>
    </div>
  </div>
  <ng-template #popConfirmIcon> </ng-template>
</nz-content>
