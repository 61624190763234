import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.css',
})
export class ForgetPasswordComponent {
  form: FormGroup;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private notification: NzNotificationService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  send() {
    this.isLoading = true;
    this.authService.sendResetLink(this.form.value.email).subscribe({
      next: (res) => {
        this.notification.success(
          'Succès',
          'Un lien de réinitialisation a été envoyé à votre adresse e-mail',
          {
            nzClass: 'success',
            nzAnimate: true,
            nzStyle: {
              width: '350px',
              background: '#f6ffed',
            },
          }
        );
        this.isLoading = false;
        this.router.navigate(['/login']);
      },
      error: (err) => {
        this.notification.error('Erreur', err.error.message, {
          nzClass: 'error',
          nzAnimate: true,
          nzStyle: {
            width: '350px',
            background: '#fdeded',
          },
        });
        this.isLoading = false;
      },
    });
  }
}
