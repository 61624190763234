import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";
import {switchMap} from "rxjs";

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.isAuthenticated().pipe(
    switchMap(isLoggedIn => {
      if (!isLoggedIn) {
        return router.navigate(['/login']);
      }
      return [true];
    })
  );


};
