import { Component, OnInit } from '@angular/core';
import { FormControl, FormRecord, NonNullableFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ManagerService } from 'src/app/services/manager.service';

@Component({
  selector: 'app-list-role-managers',
  templateUrl: './list-role-manager.component.html',
  styleUrl: './list-role-manager.component.css'
})
export class ListRoleManagersComponent implements OnInit {
  pageIndex = 1;
  pageSize = 20;
  totalManagers: number = 0;
  client = 'Neoscope';
  isLoadingData = true;
  toggleFilters = false;

  managers: any[] = [];
  allChecked = false;
  nzShowArrow = true;
  indeterminate = false;
  checkedManagers = new Set<number>();
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});

  constructor(
    private roleManagerService: ManagerService,
    private fb: NonNullableFormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    const stringControlNames = [
      'search_text',
    ];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });

    this.queryManagers();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.checkedManagers.add(id);
    } else {
      this.checkedManagers.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.managers.forEach((item) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.allChecked = this.managers.every((item) =>
      this.checkedManagers.has(item.id)
    );
    this.indeterminate =
      this.managers.some((item) => this.checkedManagers.has(item.id)) &&
      !this.allChecked;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.roleManagerService
      .getAllManagersFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.managers = data.rows;
        this.totalManagers = data.count;
        this.isLoadingData = false;
      });
  }

  resetForm(): void {
    this.queryForm.reset();
    this.queryManagers();
  }

  cancel(): void { }

  async confirmDelete(): Promise<void> {
    this.isLoadingData = true;
    for (const manager of this.checkedManagers) {
      await this.roleManagerService.delete(manager);
    }
    this.checkedManagers.clear();
    this.queryManagers();
  }

  async deleteManager(id: number) {
    this.isLoadingData = true;
    await this.roleManagerService.delete(id);
    this.managers = this.managers.filter((m) => m.id !== id)
    this.queryManagers();
  }

  queryManagers() {
    this.isLoadingData = true;
    this.roleManagerService
      .getAllManagersFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.managers = data.rows;
        this.totalManagers = data.count;
        this.isLoadingData = false;
      });
  }

  CreateManagement() {
    this.router.navigate(['managers/create'])
  }

}
