<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form
        nz-form
        [formGroup]="queryForm"
        nz-col
        [nzSpan]="canAction('AJOUTER_SOCIETE') ? 18 : 21"
      >
        <nz-input-group
          nz-col
          [nzSpan]="24"
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Rechercher dans les sociétés ..."
            (keyup.enter)="querySocietes()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search" (click)="querySocietes()"></span>
      </ng-template>
      <div
        class="filter-container"
        nz-col
        [nzSpan]="3"
        *ngIf="canAction('AJOUTER_SOCIETE')"
      >
        <button
          nz-button
          nzType="primary"
          class="filters-toogle prix-button"
          routerLink="/societes/create"
        >
          <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
          Ajouter
        </button>
      </div>
      <div class="filter-container" nz-col [nzSpan]="3">
        <button
          nz-button
          nzType="default"
          class="filters-toogle"
          (click)="toggleFilters = !toggleFilters"
        >
          <span nz-icon nzType="filter"></span>
          Filtres
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || societes.length === 0" />
    <div *ngIf="toggleFilters" class="filters-panel">
      <form nz-form [formGroup]="queryForm" class="ant-advanced-search-form">
        <div nz-row>
          <div nz-col nzSpan="20" nzSm="20" nzXs="24">
            <div nz-row class="filter-fields">
              <div nz-col [nzSpan]="5" nzSm="5" nzXs="24">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="categorie"
                      nzPlaceHolder="Catégorie de Prix"
                      formControlName="categorie"
                      [nzShowArrow]="nzShowArrow"
                      nzMode="multiple"
                      nzAllowClear="true"
                      nzSize="small"
                      [(ngModel)]="selected_categories"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                      ><nz-option
                        *ngFor="let categorie of categories"
                        [nzValue]="categorie.code"
                        [nzLabel]="categorie.code"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5" nzSm="5" nzXs="24">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="gestionnaire"
                      nzPlaceHolder="Chargé d'Affaires"
                      formControlName="gestionnaire"
                      [nzShowArrow]="nzShowArrow"
                      nzMode="multiple"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                      [(ngModel)]="selected_gestionnaires"
                    >
                      <nz-option
                        *ngFor="let commercial of commercials"
                        [nzValue]="commercial.id"
                        [nzLabel]="commercial.last_name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5" nzSm="5" nzXs="24">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="secteur"
                      nzPlaceHolder="Secteur"
                      [nzShowArrow]="nzShowArrow"
                      formControlName="secteur"
                      nzSize="small"
                      nzAllowClear="true"
                      nzMode="multiple"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        nzValue="Agriculture"
                        nzLabel="Agriculture"
                      ></nz-option>
                      <nz-option
                        nzValue="Industrie"
                        nzLabel="Industrie"
                      ></nz-option>
                      <nz-option
                        nzValue="Réseaux"
                        nzLabel="Réseaux"
                      ></nz-option>
                      <nz-option
                        nzValue="Résidentiel Individuel"
                        nzLabel="Résidentiel Individuel"
                      ></nz-option>
                      <nz-option
                        nzValue="Résidentiel Collectif"
                        nzLabel="Résidentiel Collectif"
                      ></nz-option>
                      <nz-option
                        nzValue="Tertiaire"
                        nzLabel="Tertiaire"
                      ></nz-option>
                      <nz-option
                        nzValue="Transport"
                        nzLabel="Transport"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div
                nz-col
                [nzSpan]="5"
                nzSm="5"
                nzXs="24"
                *ngIf="canAction('FILTER_BY_CLIENT')"
              >
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="source"
                      nzPlaceHolder="Client"
                      formControlName="source"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        *ngFor="let client of clients"
                        [nzValue]="client.tag"
                        [nzLabel]="client.tag"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div nz-col [nzSpan]="4" nzSm="4" nzXs="24" nzJustify="center">
            <div nz-row nzJustify="center">
              <button
                nz-button
                nzBlock
                nzType="default"
                nzShape="round"
                nzSize="small"
                class="filter-button blue-grey-button-color"
                (click)="resetForm()"
              >
                Effacer
              </button>
            </div>
            <div nz-row nzJustify="center" nzXs="24">
              <button
                nz-button
                nzBlock
                nzType="primary"
                nzShape="round"
                nzSize="small"
                (click)="querySocietes()"
                class="filter-button"
              >
                Appliquer
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <nz-table
      *ngIf="isDesktop"
      #fixedTable
      [nzData]="societes"
      [nzFrontPagination]="false"
      [nzTotal]="total_societes"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      nzPaginationPosition="both"
      [nzShowTotal]="total_societes_template"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzScroll]="{ x: '1400px' }"
      [nzLoading]="isLoadingData"
      class="societe-list-table"
    >
      <ng-template #total_societes_template>
        <button
          *ngIf="checked_societes.size > 0 && canDelete('SOCIETES')"
          nz-button
          nz-popconfirm
          nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement toutes les sociétés sélectionnées ?"
          nzType="default"
          class="secondary-button mass-action-button"
          (nzOnConfirm)="confirmDelete()"
          (nzOnCancel)="cancel()"
          [nzIcon]="popConfirmIcon"
        >
          <span nz-icon nzType="delete"></span>
          Supprimer
        </button>
        <button
          *ngIf="checked_societes.size > 0"
          nz-button
          nzType="default"
          class="secondary-button mass-action-button"
          (click)="exporter()"
        >
          <span nz-icon nzType="download"></span>
          Exporter
        </button>
        <span>{{ total_societes }} {{ total_societes==1 ? ' société' : ' sociétés' }} </span>
        <ng-template #popConfirmIcon> </ng-template>
      </ng-template>
      <thead>
        <tr>
          <th
            nzWidth="30px"
            [(nzChecked)]="checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th
            *ngFor="let column of listOfColumn"
            class="header-container"
            [nzWidth]="column.width"
          >
            <span class="table-header">{{ column.title }}</span>
          </th>
          <th nzRight nzWidth="100px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody class="tableContainer">
        <tr *ngFor="let societe of fixedTable.data">
          <td
            [nzChecked]="checked_societes.has(societe.id)"
            (nzCheckedChange)="onItemChecked(societe.id, $event)"
          ></td>
          <td>
            <a routerLink="/societes/{{ societe.id }}">{{ societe.name }}</a>
          </td>
          <td>
            {{ societe.gestionnaire?.last_name }}
          </td>
          <td>
            <a
              *ngIf="societe.categorie"
              routerLink="/categories-prix/{{ societe.categorie.code }}"
              >{{ societe.categorie?.code + " - " + societe.categorie?.nom }}</a
            >
          </td>
          <td>{{ societe.source }}</td>
          <td>{{ societe.secteur_principal }}</td>
          <td>{{ societe.users.length }}</td>
          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="action-buttons"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item routerLink="/societes/{{ societe.id }}">
                  <a
                    ><span nz-icon nzType="eye" nzTheme="outline"></span>
                    Détails</a
                  >
                </li>
                <li
                  nz-menu-item
                  routerLink="/societes/edit/{{ societe.id }}"
                  *ngIf="canEdit('SOCIETES')"
                >
                  <a
                    ><span nz-icon nzType="edit" nzTheme="outline"></span>
                    Éditer</a
                  >
                </li>
                <li nz-menu-item *ngIf="canDelete('SOCIETES')">
                  <a
                    nz-popconfirm
                    nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement cette société ?"
                    (nzOnConfirm)="delete(societe.id)"
                    (nzOnCancel)="cancel()"
                  >
                    <span nz-icon nzType="delete" nzTheme="outline"></span>
                    Supprimer</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div *ngIf="!isDesktop">
      <div *ngFor="let societe of societes">
        <nz-card class="nz-card" routerLink="/societes/{{ societe.id }}">
          <div nz-row nzJustify="space-between" nzAlign="middle">
            <div nz-col [nzSpan]="20" class="title">
              {{ societe.name }}
            </div>
            <div
              nz-col
              [nzSpan]="4"
              nz-icon
              nzType="setting"
              nzTheme="outline"
            ></div>
          </div>
          <div>
            <nz-tag nzColor="green" [nzBordered]="false" class="status-tag">
              <span nz-icon nzType="bank" nzTheme="outline"></span>
              <span>{{ societe.source }}</span>
            </nz-tag>
          </div>
          <div>
            <nz-tag nzColor="gold" [nzBordered]="false" class="status-tag">
              <span nz-icon nzType="tag" nzTheme="outline"></span>
              <span>{{ societe.secteur_principal }}</span>
            </nz-tag>
          </div>
        </nz-card>
      </div>
    </div>
  </div>
</nz-content>
