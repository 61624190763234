<nz-content>
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div nz-row class="align-items">
          <h2 class="element-details-title">{{ zohoBug.title }}</h2>
          <nz-tag
            [nzColor]="zohoBug.fixed ? 'green' : 'red'"
            [nzBordered]="false"
            class="status-tag"
          >
            <span nz-icon nzType="bug" nzTheme="outline"></span>
            <span>{{ zohoBug.fixed ? "Résolu" : "Non Résolu" }}</span>
          </nz-tag>
          <nz-tag class="timestamp" [nzBordered]="true">
            <span nz-icon nzType="clock-circle"></span>
            <span
              >Créé : {{ zohoBug.createdAt | date : "dd/MM/YYYY HH:mm" }}</span
            >
          </nz-tag>
        </div>
      </div>
      <div nz-col nzSpan="8" class="action-buttons">
        <div nz-row nzJustify="end">
          <div nz-col class="margin-right">
            <button
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement ce bug ?"
              nzType="default"
              class="secondary-button mass-action-button"
              (nzOnConfirm)="confirmDelete()"
              (nzOnCancel)="cancel()"
              [nzIcon]="popConfirmIcon"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
          </div>
          <div nz-col>
            <button
              nz-button
              nzType="default"
              class="secondary-button"
              *ngIf="!zohoBug.fixed"
              (click)="resoudre(zohoBug.id)"
            >
              <span nz-icon nzType="check-circle"></span>
              <span class="action-text">Résoudre</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>
    <h4>
      <span nz-icon nzType="file-text" nzTheme="outline"></span> Détails du bug
    </h4>
    <div nz-row class="details-container">
      <nz-card class="box">
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">ID</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ zohoBug.id }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Titre</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ zohoBug.title }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Lien</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ zohoBug.link }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Paramètres</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ zohoBug.parameters }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Réponse</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ zohoBug.response }}</div>
        </div>
      </nz-card>
    </div>
  </div>
  <ng-template #popConfirmIcon> </ng-template>
</nz-content>
