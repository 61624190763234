import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'siret',
})
export class SiretPipe implements PipeTransform {
  transform(value: string | number): string {
    if (!value) {
      return '';
    }

    const numStr = value.toString();

    return numStr.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, '$1 $2 $3 $4');
  }
}
