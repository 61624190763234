<div class="chat-container">
  <div class="main-content">
    <div class="message-section">
      <app-channel-header [data]="headerData" class="channel-header"></app-channel-header>
      <app-message-list [messages]="messages" class="message-list"></app-message-list>
      <app-message-input [roomname]="roomname" class="message-input"></app-message-input>
    </div>
    <app-directory [messages]="messages" class="sidebar"></app-directory>
  </div>
</div>
