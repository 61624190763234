import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  isCollapsed = false;

  constructor(private router: Router) {}

  toogle() {
    this.isCollapsed = !this.isCollapsed;
    if (this.router.url.includes('categories-prix/')) {
      setTimeout(() => {
        const tranchePrixComponent = document.querySelector('#tranche-prix');
        if (tranchePrixComponent) {
          const width: number = tranchePrixComponent
            ? tranchePrixComponent.getBoundingClientRect().width
            : 0;

          const tranchePrixDescriptions = document.getElementsByClassName(
            'ant-steps-item-description'
          );
          for (
            let i = 0;
            i < Array.from(tranchePrixDescriptions).length - 1;
            i++
          ) {
            const htmlElement = Array.from(tranchePrixDescriptions)[
              i
            ] as HTMLElement;
            htmlElement.style.transition = 'all .2s';
            htmlElement.style.minWidth = width + 'px';
            htmlElement.style.textAlign = 'center';
            
          }
        }
      }, 100);
    }
  }
}
