import { Component } from '@angular/core';
import { FormControl, FormRecord, NonNullableFormBuilder } from '@angular/forms';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ProjectloggingService } from 'src/app/services/projectlogging.service';

@Component({
  selector: 'app-list-journalisation',
  templateUrl: './list-journalisation.component.html',
  styleUrl: './list-journalisation.component.css'
})
export class ListJournalisationComponent {
  pageIndex = 1;
  pageSize = 20;
  total_logs: number = 0;
  client = 'Neoscope';
  isLoadingData = true;
  toogleFilters = false;
  nzShowArrow = true;
  indeterminate = false;
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});
  projectlogs:any[] = [];

  constructor(
    private fb: NonNullableFormBuilder,
    private projectlogservice: ProjectloggingService
  ) {}

  ngOnInit() {
    const stringControlNames = [
      'search_text',
    ];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.projectlogservice
      .getAllFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.projectlogs = data.rows;
        this.total_logs = data.count;
        this.isLoadingData = false;
      });
  }

  querylogs() {
    this.isLoadingData = true;
    this.projectlogservice
      .getAllFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.projectlogs = data.rows;
        this.total_logs = data.count;
        this.isLoadingData = false;
      }
      )
  }
}
