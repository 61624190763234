import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

import { NeoscopeService } from './neoscope.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = new BehaviorSubject<boolean>(false);
  private userPayload: any = null;

  clients_neoscope: any[] = [];

  constructor(
    private http: HttpClient,
    private readonly neoscopeService: NeoscopeService,
    private router: Router
  ) {
    this.loadAllClientsNeoscope();
  }

  async loadAllClientsNeoscope() {
    if (this.clients_neoscope.length === 0 && this.userPayload) {
      const res: any = await this.neoscopeService.getAllClientsNeoscopeSync(
        1,
        100,
        {}
      );
      this.clients_neoscope = res.rows;
    }
  }

  checkAuthStatuts() {
    return this.http
      .get(`${environment.apiUrl}/auth/status`, { withCredentials: true })
      .pipe(
        map((res: any) => {
          this.isLoggedIn.next(res.status as boolean);
          this.userPayload = res.user;
          return res as boolean;
        })
      );
  }

  getUserPayload() {
    return this.userPayload;
  }

  getUserClientsAccess() {
    return JSON.parse(this.userPayload.clients_access);
  }

  getCurrentUser() {
    return this.http
      .get(`${environment.apiUrl}/auth/me`, {
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          this.userPayload = res;
          return res;
        })
      );
  }

  isAuthenticated(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  login(email: string, password: string) {
    return this.http.post(
      `${environment.apiUrl}/auth/sign-in-admin`,
      { email, password },
      { withCredentials: true }
    );
  }

  verify2fa(code: string, email: string | null) {
    return this.http.post(
      `${environment.apiUrl}/auth/verify-2fa`,
      { code, email },
      { withCredentials: true }
    );
  }

  logout() {
    return this.http.post(`${environment.apiUrl}/auth/sign-out`, null, {
      withCredentials: true,
    });
  }

  sendResetLink(email: string) {
    return this.http.post(
      `${environment.apiUrl}/auth/forgot-admin-password`,
      { email },
      { withCredentials: true }
    );
  }

  resetPassword(token: string, newPassword: string) {
    return this.http.post(
      `${environment.apiUrl}/auth/reset-admin-password`,
      {
        token,
        newPassword,
      },
      { withCredentials: true }
    );
  }

  resetAdminPassword(oldPassword: string, newPassword: string) {
    return this.http.post(
      `${environment.apiUrl}/auth/new-admin-password`,
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      { withCredentials: true }
    );
  }

  resend2faCode(email: string | null) {
    return this.http.post(
      `${environment.apiUrl}/auth/resend-2fa`,
      { email },
      { withCredentials: true }
    );
  }

  canAccess(tab_name: string | undefined): boolean {
    switch (tab_name?.toUpperCase()) {
      case 'DASHBOARD':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
          'ROLE_OBSERVATEUR',
        ].includes(this.userPayload.role);
      case 'PROJETS':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
          'ROLE_OBSERVATEUR',
        ].includes(this.userPayload.role);
      case 'MES-PROJETS':
        return [
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'CORBEILLE':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
          'ROLE_OBSERVATEUR',
        ].includes(this.userPayload.role);
      case 'SOCIETES':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
          'ROLE_OBSERVATEUR',
        ].includes(this.userPayload.role);
      case 'UTILISATEURS':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
          'ROLE_OBSERVATEUR',
        ].includes(this.userPayload.role);
      case 'BENEFICIAIRES':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
          'ROLE_OBSERVATEUR',
        ].includes(this.userPayload.role);
      case 'MANAGERS':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
        ].includes(this.userPayload.role);
      case 'COMMUNICATIONS':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'MESSAGERIE':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'BANDEAU-INFO':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'PERSONNALISER-NEOSCOPE':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'REGLEMENTAIRE':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
          'ROLE_OBSERVATEUR',
        ].includes(this.userPayload.role);
      case 'OBLIGES':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'PRIX':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
        ].includes(this.userPayload.role);
      case 'ADMINISTRATEURS':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'GESTION-COMMERCIALE':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
        ].includes(this.userPayload.role);
      case 'STATUTS-CEE':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'STATUTS-MPR':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'HUB-DEVELOPPEUR':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'BUGS-ZOHO':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'JOURNALISATION':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'CLIENTS-NEOSCOPE':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
    }
    return false;
  }

  canEdit(tab_name: string, data?: any | null): boolean {
    switch (tab_name) {
      case 'PROJETS':
        return (
          ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_CHARGE_AFFAIRES'].includes(
            this.userPayload.role
          ) ||
          (this.userPayload.role === 'ROLE_CONTROLEUR_CEE_MPR' &&
            !['PEC', 'OD'].includes(data.statut.code) &&
            !['RJT', 'N', 'NEL', 'RFS'].includes(data.statut_mpr.code)) ||
          (this.userPayload.role === 'ROLE_CONTROLEUR_CEE' &&
            !['PEC', 'OD'].includes(data.statut.code) &&
            ['RJT', 'N', 'NEL', 'RFS'].includes(data.statut_mpr.code))
        );
      case 'MES_PROJETS':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'SOCIETES':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'UTILISATEURS':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'BENEFICIAIRES':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'MANAGERS':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
        ].includes(this.userPayload.role);
      case 'MESSAGERIE':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'BANDEAU-INFO':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'PERSONNALISER-NEOSCOPE':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'REGLEMENTAIRE':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'OBLIGES':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'PRIX':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'ADMINISTRATEURS':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'GESTION-COMMERCIALE':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'STATUTS':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'HUB-DEVELOPPEUR':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'BUGS-ZOHO':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'JOURNALISATION':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'CLIENTS-NEOSCOPE':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
    }
    return false;
  }

  canDelete(tab_name: string): boolean {
    switch (tab_name) {
      case 'PROJETS':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'SOCIETES':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'UTILISATEURS':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'BENEFICIAIRES':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'MANAGERS':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
        ].includes(this.userPayload.role);
      case 'MESSAGERIE':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'REGLEMENTAIRE':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'OBLIGES':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'PRIX':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'ADMINISTRATEURS':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'GESTION-COMMERCIALE':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'STATUTS-CEE':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'STATUTS-MPR':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'HUB-DEVELOPPEUR':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'BUGS-ZOHO':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'JOURNALISATION':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'CLIENTS-NEOSCOPE':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
    }
    return false;
  }

  canAction(action_name: string) {
    switch (action_name.toUpperCase()) {
      case 'BLOQUER_PROJET':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
        ].includes(this.userPayload.role);
      case 'RESTAURER_PROJET':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'UPLOAD_PROJET_DOCUMENT':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'CORBEILLE_PROJET':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
        ].includes(this.userPayload.role);
      case 'CORBEILLE_MES_PROJETS':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
          'ROLE_CONTROLEUR_CEE',
          'ROLE_CONTROLEUR_CEE_MPR',
        ].includes(this.userPayload.role);
      case 'MAJ_PRIX':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'MAIL_PRIX':
        return ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(
          this.userPayload.role
        );
      case 'MAJ_REGLEMENTAIRE':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'VALIDER_UTILISATEUR':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
        ].includes(this.userPayload.role);
      case 'REFUSER_UTILISATEUR':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
        ].includes(this.userPayload.role);
      case 'AJOUTER_OBLIGE':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'AJOUTER_REGLEMENTAIRE':
        return ['ROLE_SUPER_ADMIN'].includes(this.userPayload.role);
      case 'AJOUTER_SOCIETE':
        return [
          'ROLE_SUPER_ADMIN',
          'ROLE_ADMIN',
          'ROLE_CHARGE_AFFAIRES',
        ].includes(this.userPayload.role);
      case 'FILTER_BY_CLIENT':
        return JSON.parse(this.userPayload.clients_access).length > 1;
      case 'FILTER_BY_CONTROLEUR':
        return ['ROLE_ADMIN', 'ROLE_CHARGE_AFFAIRES'].includes(
          this.userPayload.role
        );
      case 'FILTER_BY_CHARGE_AFFAIRES':
        return ['ROLE_CONTROLEUR_CEE', 'ROLE_CONTROLEUR_CEE_MPR'].includes(
          this.userPayload.role
        );
    }
    return false;
  }

  async canAccessClientData(data_tag: string) {
    await this.loadAllClientsNeoscope();
    const admin_clients = this.clients_neoscope.filter((client) => {
      return this.getUserClientsAccess().includes(client.id);
    });
    const clients_tags = admin_clients.map((obj) => obj.tag);
    if (!clients_tags.includes(data_tag)) {
      this.router.navigate(['/access-denied']);
    }
  }
}
