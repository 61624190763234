import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient) { }

  previewDocument(document_id: number) {
    return this.http.get(
      `${environment.apiUrl}/documents/preview/` + document_id,
      { responseType: 'blob' }
    );
  }

  previewDocumentByName(filename: string, file_type: string) {
    return this.http.get(
      `${environment.apiUrl}/documents/preview/name=${filename}&type=${file_type}`,
      { responseType: 'blob' }
    );
  }

  uploadDocument(file: any, projet_id: number, document_type: string) {
    return this.http.post(
      `${environment.apiUrl}/documents/create/type=${document_type}&projet_id=${projet_id}`,
      file
    );

  }

}
