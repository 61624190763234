<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form
        nz-form
        [formGroup]="queryForm"
        nz-col
        [nzSpan]="canAction('AJOUTER_OBLIGE') ? 21 : 24"
      >
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Rechercher dans les obligés ..."
            (keyup.enter)="queryObliges()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search" (click)="queryObliges()"></span>
      </ng-template>
      <div
        class="padding-left-10"
        nz-col
        [nzSpan]="3"
        *ngIf="canAction('AJOUTER_OBLIGE')"
      >
        <button
          nz-button
          nzType="primary"
          class="add-button"
          (click)="createOblige()"
        >
          <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
          Ajouter
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || obliges.length === 0" />

    <nz-table
      #fixedTable
      [nzData]="obliges"
      [nzFrontPagination]="false"
      [nzTotal]="total_obliges"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      nzPaginationPosition="both"
      [nzShowTotal]="total_obliges_template"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzLoading]="isLoadingData"
      class="projet-list-table"
    >
      <ng-template #total_obliges_template>
        <span
          >{{ total_obliges }}
          {{ total_obliges == 1 ? " obligé" : " obligés" }}</span
        >
        <ng-template #popConfirmIcon> </ng-template>
      </ng-template>

      <thead class="liste-projets-head">
        <tr>
          <th>Nom abrégé</th>
          <th>Nom officiel</th>
          <th nzRight nzWidth="90px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data">
          <td>{{ data.nom }}</td>
          <td>{{ data.nomOfficiel }}</td>
          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="action-buttons"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item>
                  <a routerLink="/obliges/{{ data.id }}">
                    <span nz-icon nzType="eye" nzTheme="outline"></span>
                    Détails</a
                  >
                </li>
                <li
                  *ngIf="canEdit('OBLIGES')"
                  nz-menu-item
                  routerLink="/obliges/edit/{{ data.id }}"
                >
                  <a
                    ><span nz-icon nzType="edit" nzTheme="outline"></span>
                    Éditer</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
