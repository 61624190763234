import { Component } from '@angular/core';
import {
  FormControl,
  FormRecord,
  NonNullableFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NeoscopeService } from 'src/app/services/neoscope.service';

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrl: './list-clients.component.css',
})
export class ListClientsComponent {
  pageIndex = 1;
  pageSize = 20;
  total_clients_neoscope: number = 0;
  isLoadingData = true;

  clients_neoscope: any[] = [];

  nzShowArrow = true;
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});

  constructor(
    private fb: NonNullableFormBuilder,
    private router: Router,
    private neoscopeService: NeoscopeService
  ) {}

  ngOnInit() {
    const stringControlNames = ['search_text'];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.queryClientsNeoscope();
  }

  resetForm(): void {
    this.queryForm.reset();

    this.queryClientsNeoscope();
  }

  cancel(): void {}

  queryClientsNeoscope() {
    this.isLoadingData = true;
    this.neoscopeService
      .getAllClientsNeoscope(
        this.pageIndex,
        this.pageSize,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.clients_neoscope = data.rows;
        this.total_clients_neoscope = data.length;
        this.isLoadingData = false;
      });
  }

  getStatus(client: any) {
    if(client.is_active){
      return {
        color: 'green',
        text: 'Activé',
      };
    }
    else{
      return {
        color: 'red',
        text: 'Bloqué',
      }; 
    }
  }
}
