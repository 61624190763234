import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getUserById(user_id: number) {
    return this.http.get(`${environment.apiUrl}/users/${user_id}`);
  }

  getUsersFilter(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/users/page=${page}&limit=${limit}`,
      query
    );
  }

  delete(user_id: number) {
    return firstValueFrom(
      this.http.delete(`${environment.apiUrl}/users/${user_id}`)
    );
  }

  exportUsers(users_ids: number[]) {
    return this.http.post(
      `${environment.apiUrl}/users/export`,
      { ids: users_ids },
      { responseType: 'blob' }
    );
  }

  exportAllUsers(query: any) {
    return this.http.post(`${environment.apiUrl}/users/export-all`, query, {
      responseType: 'blob',
    });
  }

  editUser(user_id: number, payload: any) {
    return this.http.patch(
      `${environment.apiUrl}/users/edit/${user_id}`,
      payload
    );
  }

  validateUser(user_id: number) {
    return this.http.put(`${environment.apiUrl}/users/validate/${user_id}`, {});
  }

  refuseUser(user_id: number) {
    return this.http.put(`${environment.apiUrl}/users/refuse/${user_id}`, {});
  }
}
