<div [ngClass]="fileType" class="file-tag">
  <div class="icon-container">
    <span [nzType]="fileIconType" class="file-icon" nz-icon nzTheme="outline"></span>
  </div>
  <div class="file-info">
    <span class="file-name">{{ fileName }}</span>
    <span class="file-details">{{ fileType | uppercase }}</span>
  </div>
  <span (click)="downloadFile()" class="download-icon" nz-icon nzType="download"></span>
</div>
