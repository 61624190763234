<nz-header>
  <div>
    <div nz-row nzAlign="middle">
      <div nz-col nzSpan="16">
        <nz-breadcrumb>
          <nz-breadcrumb-item>
            <a routerLink="/admin/dashboard">
              <span nz-icon nzType="home"></span>
              <span>Accueil</span>
            </a>
          </nz-breadcrumb-item>
        </nz-breadcrumb>
      </div>
      <div nz-col nzSpan="8" class="align-text-right">
        <img
          src="assets/logos/neoscope.png"
          alt="Neoscope"
          class="logo-neoscope"
        />
      </div>
    </div>
  </div>
</nz-header>

<nz-content>
  <div class="inner-content">
    <nz-collapse nzExpandIconPosition="start" class="client-panels">
      <nz-collapse-panel
        *ngFor="let panel of panels"
        [nzHeader]="panelTitle"
        [nzExtra]="plusoptions"
      >
        <ng-template #panelTitle>
          <div nz-row nzAlign="middle" class="panel-title">
            <div nz-col nzSpan="24">
              <nz-avatar nzIcon="user" class="client-logo"></nz-avatar>
              <span class="client-name">{{ panel.name }}</span>
            </div>
          </div>
        </ng-template>
        <ng-template #plusoptions>
          <button
            nz-button
            nzType="primary"
            nzSize="large"
            nzShape="round"
            class="admin-access-button"
            (click)="$event.stopPropagation()"
            routerLink="/client/{{ panel.id }}"
          >
            Accéder en tant qu'admin
          </button>
          <span>Plus d'options</span>
        </ng-template>
        <div class="panel-content">
          <div nz-row nzAlign="middle" class="margin-20">
            <div nz-col nzSpan="6">Nombre de fiches accessibles : 45/150</div>
            <div nz-col nzSpan="6">
              <button
                nz-button
                nzType="primary"
                nzSize="large"
                nzShape="round"
                class="parametrer-button"
                routerLink="/client/{{ panel.id }}"
              >
                Paramétrer
              </button>
            </div>
            <div nz-col nzSpan="6">Nombre d’obligés : 2</div>
            <div nz-col nzSpan="6">
              <button
                nz-button
                nzType="primary"
                nzSize="large"
                nzShape="round"
                class="parametrer-button"
                routerLink="/client/{{ panel.id }}"
              >
                Paramétrer
              </button>
            </div>
          </div>
          <div nz-row nzAlign="middle" class="margin-20">
            <div nz-col nzSpan="6">Coup de pouce : 2/3</div>
            <div nz-col nzSpan="6">
              <button
                nz-button
                nzType="primary"
                nzSize="large"
                nzShape="round"
                class="parametrer-button"
                routerLink="/client/{{ panel.id }}"
              >
                Paramétrer
              </button>
            </div>
            <div nz-col nzSpan="6">Offre de financement : Oui</div>
            <div nz-col nzSpan="6">
              <button
                nz-button
                nzType="primary"
                nzSize="large"
                nzShape="round"
                class="parametrer-button"
                routerLink="/client/{{ panel.id }}"
              >
                Paramétrer
              </button>
            </div>
          </div>
          <div nz-row nzAlign="middle" class="margin-20">
            <div nz-col nzSpan="6">MaPrimeRenov' : Oui</div>
            <div nz-col nzSpan="6">
              <button
                nz-button
                nzType="primary"
                nzSize="large"
                nzShape="round"
                class="parametrer-button"
                routerLink="/client/{{ panel.id }}"
              >
                Paramétrer
              </button>
            </div>
            <div nz-col nzSpan="6">Automatisation : Oui</div>
            <div nz-col nzSpan="6">
              <button
                nz-button
                nzType="primary"
                nzSize="large"
                nzShape="round"
                class="parametrer-button"
                routerLink="/client/{{ panel.id }}"
              >
                Paramétrer
              </button>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</nz-content>
