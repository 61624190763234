import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
import { ChatService } from 'src/app/services/chat.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css'],
})
export class MessageListComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('messageContainer') private messageContainer: ElementRef;
  
  currentUser: any;
  
  @Input() messages: any[];
  loading: boolean = false;
  private previousMessagesLength: number = 0;

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authService.getCurrentUser().subscribe({
      next: (res) => {
        this.currentUser = res;
      },
      error: (err) => {
        throw err;
      },
    });
  }
  
  ngOnDestroy() {}

  ngAfterViewChecked() {
    if (this.messages.length > this.previousMessagesLength) {
      this.scrollToBottom();
      this.previousMessagesLength = this.messages.length;
    }
  }

  getMessageContentClass(message: any) {
    return message.auteur === this.currentUser.username
      ? 'current-user-message'
      : 'non-user-message';
  }

  extractName(file: any) {
    const filename = JSON.parse(file).filename;
    return filename;
  }

  download(file: any) {
    this.chatService
      .downloadFile(JSON.parse(file).s3filename)
      .subscribe((data) => {
        importedSaveAs(data, JSON.parse(file).filename);
      });
  }

  scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Scroll to bottom failed', err);
    }
  }
}