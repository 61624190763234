import {Component, Input, OnInit} from '@angular/core';
import {ChatService} from "../../../../../services/chat.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrl: './directory.component.css'
})
export class DirectoryComponent implements OnInit {
  @Input() messages: any[] = [];
  room: string;

  constructor(private router: Router, private route: ActivatedRoute, private chatService: ChatService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.room = params['id'];
    });

  }
}
