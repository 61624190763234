import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ZohoBugsService {
  constructor(private http: HttpClient) {}

  getAllzohoBugsFilter(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/zohobug/page=${page}&limit=${limit}`,
      query
    );
  }

  update(id: number, payload: any) {
    return this.http.put(`${environment.apiUrl}/zohobug/${id}`, payload);
  }

  getZohoBugyid(id: number) {
    return this.http.get(`${environment.apiUrl}/zohobug/${id}`);
  }
  
  delete(id: number) {
    return firstValueFrom(
      this.http.delete(`${environment.apiUrl}/zohobug/${id}`)
    );
  }
}
