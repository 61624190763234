import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-edit-statuts-mpr',
  templateUrl: './edit-statuts-mpr.component.html',
  styleUrl: './edit-statuts-mpr.component.css',
})
export class EditStatutsMprComponent implements OnInit {
  statutType: string = 'mpr';
  label: string;
  statutID: number;
  hasUnsavedChanges: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.upLoading();
    const state = window.history.state;
    if (state && state.label && state.statutID) {
      this.label = state.label;
      this.statutID = state.statutID;
      this.loadingService.offLoading();
    } else this.router.navigate([`/statuts-${this.statutType}`]).then((r) => r);
  }

  onFormStateChange(hasChanges: boolean): void {
    this.hasUnsavedChanges = hasChanges;
  }

  checkFormChanges(): boolean {
    return this.hasUnsavedChanges;
  }
}
