import { Component, Input, OnInit } from '@angular/core';

import { AuthService } from '../../../../../services/auth.service';
import { ChatService } from '../../../../../services/chat.service';

@Component({
  selector: 'app-message-input',
  templateUrl: './message-input.component.html',
  styleUrl: './message-input.component.css',
})
export class MessageInputComponent {
  newMessage: string = '';
  @Input() roomname: string;

  selectedFile: File | undefined;

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
  ) {}

  sendMessage() {
    this.chatService.sendMessage(
      this.roomname,
      this.newMessage,
      this.selectedFile,
    );
    this.newMessage = '';
    this.selectedFile = undefined;
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    console.log(input);
    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
      console.log(this.selectedFile);
    }
  }

  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }
}
