<nz-content>
  <div class="inner-content">
    <div nz-row nzAlign="middle">
      <div nz-col nzSpan="18">
        <h2 class="projet-title">
          <a
            href="https://crm.zoho.eu/crm/org20063788205/tab/Potentials/{{
              projet?.idProjetcrm
            }}"
            target="_blank"
            class="external-link"
            >{{ projet.numeroDossier }}</a
          >
          - {{ projet.name }}
        </h2>
        <nz-tag
          [nzColor]="getProjectStageTagColor(projet)"
          [nzBordered]="false"
          class="status-tag"
        >
          <span nz-icon nzType="hourglass" nzTheme="outline"></span>
          <span>{{ projet.statut.label }}</span>
        </nz-tag>
      </div>
      <div nz-col nzSpan="6" class="align-text-right">
        <button
          nz-button
          nzType="default"
          class="secondary-button"
          *ngIf="
            !projet.is_blocked &&
            !projet.offre &&
            !['OP', 'PP'].includes(projet.statut.code) &&
            canAction('BLOQUER_PROJET')
          "
          (click)="bloquerProjet()"
        >
          <span nz-icon nzType="stop"></span>
          Bloquer
        </button>
        <button
          nz-button
          nzType="default"
          class="secondary-button"
          *ngIf="
            projet.is_blocked &&
            !['OP', 'PP'].includes(projet.statut.code) &&
            canAction('BLOQUER_PROJET')
          "
          (click)="debloquerProjet()"
        >
          <span nz-icon nzType="check-circle"></span>
          Débloquer
        </button>
        <button
          nz-button
          nzType="default"
          class="secondary-button margin-left-10"
          routerLink="/projets/edit/{{ projet.id }}"
          *ngIf="!['PP'].includes(projet.statut.code) && canEdit('PROJETS')"
        >
          <span nz-icon nzType="edit"></span>
          Éditer
        </button>
        <button
          nz-button
          nzType="default"
          class="secondary-button margin-left-10"
          (click)="restoreProjet()"
          *ngIf="
            ['PP'].includes(projet.statut.code) && canAction('RESTAURER_PROJET')
          "
        >
          <span nz-icon nzType="edit"></span>
          Restaurer
        </button>
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzSpan]="20">
        <nz-tag [nzColor]="'green'" [nzBordered]="true" class="volume-tag">
          <span nz-icon nzType="trophy" nzTheme="outline"></span>
          <span>{{ projet.resultat | number : "1.3-3" : "fr" }} Mwh</span>
        </nz-tag>
        <nz-tag class="timestamp" [nzBordered]="true">
          <span nz-icon nzType="clock-circle"></span>
          <span
            >Créé :
            {{ projet.projetDateTime | date : "dd/MM/YYYY HH:mm" }}</span
          >
        </nz-tag>
        <nz-tag class="timestamp" [nzBordered]="true">
          <span nz-icon nzType="clock-circle"></span>
          <span
            >Modifié :
            {{ projet.lasModifiedBackProjet | date : "dd/MM/YYYY HH:mm" }}</span
          ></nz-tag
        >
      </div>
    </div>
    <nz-tabset [nzAnimated]="true" [nzCentered]="true" class="tabs-bar">
      <nz-tab [nzTitle]="global">
        <ng-template #global>
          <span nz-icon nzType="global"></span>
          Global
        </ng-template>
        <div *ngIf="active_tab === 'GLOBAL'">
          <h4>Informations Générales</h4>
          <div nz-row [nzGutter]="16">
            <div nz-col nzSpan="8">
              <nz-card class="box">
                <h4 class="info-card-title">
                  <span nz-icon nzType="percentage" nzTheme="outline"></span>
                  Valorisation du Projet
                </h4>
                <div nz-row>
                  <div nz-col [nzSpan]="14" class="field-grey-label">
                    Taux Bénéficiaire (€)
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="9" class="align-text-right">
                    {{ projet.clientTaux | number : "1.2-2" : "fr" }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="14" class="field-grey-label">
                    Taux Installateur (€)
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="9" class="align-text-right">
                    {{ projet.partenaireTaux | number : "1.2-2" : "fr" }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="14" class="field-grey-label">
                    Prime Bénéficiaire (€)
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="9" class="align-text-right">
                    {{ projet.clientPart | number : "1.2-2" : "fr" }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="14" class="field-grey-label">
                    Prime Installateur (€)
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="9" class="align-text-right">
                    {{ projet.partenairePart | number : "1.2-2" : "fr" }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="14" class="field-grey-label">
                    Prime CEE Totale (€)
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="9" class="align-text-right">
                    {{ projet.prixUnitaire | number : "1.2-2" : "fr" }}
                  </div>
                </div>
              </nz-card>
            </div>
            <div nz-col nzSpan="8">
              <nz-card class="box">
                <h4 class="info-card-title">
                  <span nz-icon nzType="team" nzTheme="outline"></span> Contacts
                </h4>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Société
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="13" class="align-text-right">
                    <a
                      *ngIf="projet.societe"
                      routerLink="/societes/{{ projet.societe?.id }}"
                      >{{ projet.societe?.name }}</a
                    >
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Responsable
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="13" class="align-text-right">
                    <a
                      *ngIf="projet.user"
                      routerLink="/utilisateurs/{{ projet.user?.id }}"
                      >{{ projet.user?.firstname }}
                      {{ projet.user?.lastname }}</a
                    >
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Gestionnaire
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="13" class="align-text-right">
                    <a
                      *ngIf="projet.admin"
                      routerLink="/administrateurs/{{ projet.admin?.id }}"
                      >{{
                        projet.admin?.first_name + " " + projet.admin?.last_name
                      }}</a
                    >
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Contrôleur
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="13" class="align-text-right">
                    <a
                      *ngIf="projet.controleur"
                      routerLink="/administrateurs/{{ projet.controleur?.id }}"
                      >{{
                        projet.controleur?.first_name +
                          " " +
                          projet.controleur?.last_name
                      }}</a
                    >
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Bénéficiaire
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="13" class="align-text-right">
                    <a
                      *ngIf="projet.beneficiaire"
                      routerLink="/beneficiaires/{{ projet.beneficiaire.id }}"
                      >{{ projet.beneficiaire.prenom }}
                      {{ projet.beneficiaire.nom }}</a
                    >
                  </div>
                </div>
              </nz-card>
            </div>
            <div nz-col nzSpan="8">
              <nz-card class="box">
                <h4 class="info-card-title">
                  <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                  Description
                </h4>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Obligé
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="13" class="align-text-right">
                    <a
                      *ngIf="projet.oblige"
                      routerLink="/obliges/{{ projet.oblige?.id }}"
                      >{{ projet.oblige?.nom }}</a
                    >
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Secteur
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="13" class="align-text-right">
                    {{ projet.secteur }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Plateforme
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="13" class="align-text-right">
                    {{ projet.source }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Département
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div nz-col [nzSpan]="13" class="align-text-right">
                    {{ projet.zip }} - {{ projet.departement }}
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzSpan]="10" class="field-grey-label">
                    Bonification
                  </div>
                  <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                  <div
                    nz-col
                    [nzSpan]="13"
                    class="align-text-right"
                    *ngIf="
                      projet.coupdepouce === 'Coup de Pouce Pilotage Connecte'
                    "
                  >
                    CdP Pilotage Connecté
                  </div>
                  <div
                    nz-col
                    [nzSpan]="13"
                    class="align-text-right"
                    *ngIf="
                      projet.coupdepouce ===
                      'Coup de Pouce Reno Globale Collectif'
                    "
                  >
                    CdP Réno Globale
                  </div>
                  <div
                    nz-col
                    [nzSpan]="13"
                    class="align-text-right"
                    *ngIf="
                      ['Coup de pouce Standard', 'Coup de pouce +'].includes(
                        projet.coupdepouce
                      )
                    "
                  >
                    CdP Chauffage Indiv
                  </div>
                  <div
                    nz-col
                    [nzSpan]="13"
                    class="align-text-right"
                    *ngIf="projet.coupdepouce === 'Coup de pouce'"
                  >
                    Coup de pouce chauffage tertiaire et résidentiel collectif
                  </div>
                  <div
                    nz-col
                    [nzSpan]="13"
                    class="align-text-right"
                    *ngIf="is_zni"
                  >
                    Bonification ZNI
                  </div>
                  <div
                    nz-col
                    [nzSpan]="13"
                    class="align-text-right"
                    *ngIf="!is_zni && !projet.isTypeCP"
                  >
                    Non
                  </div>
                </div>
              </nz-card>
            </div>
          </div>
          <br />
          <h4>Récapitulatif des fiches</h4>
          <div nz-row>
            <nz-table
              #fixedTable
              [nzFrontPagination]="false"
              nzPaginationPosition="both"
              [nzData]="fiches_cee"
              [nzShowPagination]="false"
              class="projet-list-table width-100"
            >
              <thead class="liste-projets-head">
                <tr>
                  <th>Fiche</th>
                  <th>Version Fiche</th>
                  <th>Volume Hors Bonification (Mwh)</th>
                  <th>Volume bonifié (Mwh)</th>
                  <th>Prix unitaire (€)</th>
                  <th>Prime (€)</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fiche of fixedTable.data">
                  <td>{{ fiche.op_code }}</td>
                  <td>{{ fiche.ficheVersion }}</td>
                  <td class="align-right">
                    {{
                      fiche.volume_hors_cdp
                        ? (fiche.volume_hors_cdp | number : "1.3-3" : "fr")
                        : (fiche.montant_energie | number : "1.3-3" : "fr")
                    }}
                  </td>
                  <td class="align-right">
                    {{ fiche.montant_energie | number : "1.3-3" : "fr" }}
                  </td>
                  <td class="align-right">
                    {{ fiche.prix | number : "1.2-2" : "fr" }}
                  </td>
                  <td class="align-right">
                    {{ fiche.primeUnitaireFiche | number : "1.2-2" : "fr" }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3" class="fiches-recap-footer">
                    RE-EVALUATION DU PROJET GLOBAL
                  </td>
                  <td class="align-right">
                    {{ projet.resultat | number : "1.3-3" : "fr" }}
                  </td>
                  <td class="align-right">
                    {{ projet.coursEmmy | number : "1.2-2" : "fr" }}
                  </td>
                  <td class="align-right">
                    {{ projet.prixUnitaire | number : "1.2-2" : "fr" }}
                  </td>
                </tr>
              </tfoot>
            </nz-table>
          </div>
        </div>
      </nz-tab>

      <nz-tab [nzTitle]="fiches">
        <ng-template #fiches>
          <span nz-icon nzType="profile"></span>
          Fiches
        </ng-template>
        <div *ngFor="let fiche of fiches_cee">
          <fiche [fiche]="fiche"></fiche>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="docs">
        <ng-template #docs>
          <span nz-icon nzType="copy"></span>
          Documents
        </ng-template>
        <nz-list>
          <nz-list-item *ngFor="let doc of documents">
            <nz-list-item-meta
              [nzAvatar]="document_avatar"
              nzDescription="{{ doc.type }}"
            >
              <nz-list-item-meta-title>
                {{ doc.name }}
              </nz-list-item-meta-title>
              <ng-template #document_avatar let-data="doc">
                <img
                  src="assets/icons/pdf.svg"
                  class="document-avatar"
                  *ngIf="getDocExtension(doc) === 'PDF'"
                />
                <img
                  src="assets/icons/zip.svg"
                  class="document-avatar"
                  *ngIf="getDocExtension(doc) === 'ZIP'"
                />
                <img
                  src="assets/icons/image.svg"
                  class="document-avatar"
                  *ngIf="!['PDF', 'ZIP'].includes(getDocExtension(doc))"
                />
              </ng-template>
            </nz-list-item-meta>
            <ul nz-list-item-actions>
              <nz-list-item-action *ngIf="getDocExtension(doc) !== 'ZIP'">
                <a (click)="previewDocument(doc)"
                  ><span nz-icon nzType="eye" nzTheme="outline"></span> Voir</a
                >
              </nz-list-item-action>
              <nz-list-item-action>
                <a (click)="downloadDocument(doc)"
                  ><span nz-icon nzType="download" nzTheme="outline"></span>
                  Télécharger</a
                >
              </nz-list-item-action>
            </ul>
          </nz-list-item>
          <nz-list-empty
            *ngIf="documents.length === 0"
            nzNoResult="Pas de document pour l'instant."
          />
        </nz-list>
        <div *ngIf="canAction('UPLOAD_PROJET_DOCUMENT')">
          <a
            nz-button
            nzType="link"
            class="upload-doc-button"
            (click)="showModal()"
          >
            <span class="upload-doc"> + Nouveau document </span>
          </a>
          <nz-modal
            [(nzVisible)]="isVisible"
            nzTitle="Nouveau document"
            [nzFooter]="modalFooter"
            (nzOnCancel)="handleCancel()"
          >
            <ng-container *nzModalContent>
              <nz-select
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="Type de document"
                [(ngModel)]="DocumentType"
              >
                <nz-option
                  *ngFor="let type of documentTypes"
                  [nzLabel]="type"
                  [nzValue]="type"
                ></nz-option>
              </nz-select>
              <br />
              <br />
              <nz-upload
                [nzBeforeUpload]="beforeUpload"
                [(nzFileList)]="fileList"
              >
                <button nz-button>
                  <span nz-icon nzType="upload"></span>
                  Choisir un fichier
                </button>
              </nz-upload>
            </ng-container>
            <ng-template #modalFooter>
              <button nz-button nzType="default" (click)="handleCancel()">
                Annuler
              </button>
              <button
                [disabled]="fileList.length === 0 || !DocumentType"
                nz-button
                nzType="primary"
                [nzLoading]="uploading"
                (click)="handleUpload()"
              >
                Ajouter
              </button>
            </ng-template>
          </nz-modal>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="mpr" [nzDisabled]="false">
        <ng-template #mpr>
          <span nz-icon nzType="bank"></span>
          Services Annexes
        </ng-template>
        <div nz-row [nzGutter]="16">
          <div nz-col nzSpan="8">
            <nz-card class="box">
              <h4 class="info-card-title">
                <span nz-icon nzType="euro-circle" nzTheme="outline"></span>
                MaPrimeRénov'
              </h4>
              <div nz-row>
                <div nz-col [nzSpan]="9" class="field-grey-label">Statut</div>
                <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                <div nz-col [nzSpan]="14" class="align-text-right">
                  {{ projet.statut_mpr?.label }}
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzSpan]="9" class="field-grey-label">
                  Montant (€)
                </div>
                <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                <div nz-col [nzSpan]="14" class="align-text-right">
                  {{
                    projet.primeRenovAmount
                      ? (projet.primeRenovAmount | number : "1.2-2" : "fr")
                      : (0 | number : "1.2-2" : "fr")
                  }}
                </div>
              </div>
              <div nz-row><br /></div>
            </nz-card>
          </div>
          <div nz-col nzSpan="8">
            <nz-card class="box">
              <h4 class="info-card-title">
                <span nz-icon nzType="credit-card" nzTheme="outline"></span>
                Paiement Anticipé
              </h4>
              <div nz-row>
                <div nz-col [nzSpan]="9" class="field-grey-label">Statut</div>
                <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                <div nz-col [nzSpan]="14" class="align-text-right">
                  <span *ngIf="projet.has_fast_cash === true">Accepté</span>
                  <span *ngIf="projet.has_fast_cash === false">Refusé</span>
                  <span *ngIf="projet.has_fast_cash === null">NC</span>
                </div>
              </div>
              <div nz-row><br /></div>
              <div nz-row><br /></div>
            </nz-card>
          </div>
          <div nz-col nzSpan="8">
            <nz-card class="box">
              <h4 class="info-card-title">
                <span nz-icon nzType="thunderbolt" nzTheme="outline"></span>
                Financement du reste à charge
              </h4>
              <div nz-row>
                <div nz-col [nzSpan]="9" class="field-grey-label">Statut</div>
                <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                <div nz-col [nzSpan]="14" class="align-text-right">
                  <span *ngIf="projet.acceptFinance === true">Accepté</span>
                  <span *ngIf="projet.acceptFinance === false">Refusé</span>
                  <span *ngIf="projet.acceptFinance === null">NC</span>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzSpan]="9" class="field-grey-label">
                  Montant (€)
                </div>
                <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                <div nz-col [nzSpan]="14" class="align-text-right">
                  {{
                    projet.beneficiaire && projet.acceptFinance
                      ? (projet.beneficiaire.montant_financement
                        | number : "1.2-2" : "fr")
                      : ""
                  }}
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzSpan]="9" class="field-grey-label">
                  Durée (années)
                </div>
                <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
                <div nz-col [nzSpan]="14" class="align-text-right">
                  {{
                    projet.beneficiaire && projet.acceptFinance
                      ? projet.beneficiaire.duree_financement
                      : ""
                  }}
                </div>
              </div>
            </nz-card>
          </div>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="chronologie">
        <ng-template #chronologie>
          <span nz-icon nzType="history"></span>
          Chronologie
        </ng-template>
        <div class="margin-top-20"></div>
        <nz-timeline nzMode="left">
          <nz-timeline-item [nzLabel]="timeline_label" *ngFor="let log of logs">
            <p>{{ log.action }}</p>
            <p class="field-grey-label">
              {{ log.date | date : "dd/MM/YYYY HH:mm" }}
            </p>
            <ng-template #timeline_label let-data="log">
              <span *ngIf="log.action_user">
                <a routerLink="/utilisateurs/{{ log.action_user.id }}">{{
                  log.action_user.firstname + " " + log.action_user.lastname
                }}</a>
              </span>
            </ng-template>
          </nz-timeline-item>
        </nz-timeline>
      </nz-tab>
    </nz-tabset>
  </div>
</nz-content>
