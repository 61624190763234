import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient) {
  }

  getAllBandeau() {
    return this.http.get(
      `${environment.apiUrl}/configurations/bandeaux/all`
    );
  }

  getBandeauById(id: number) {
    return this.http.get(`${environment.apiUrl}/configurations/bandeaux/${id}`);
  }

  editBandeau(bandeau_id: number, payload: any) {
    return this.http.post(`${environment.apiUrl}/configurations/bandeaux/update/${bandeau_id}`, payload);
  }

}
