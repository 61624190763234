import { Component } from '@angular/core';
import {
  FormControl,
  FormRecord,
  NonNullableFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AuthService } from 'src/app/services/auth.service';
import { ProjetsService } from 'src/app/services/projets.service';
import { ReglementairesService } from 'src/app/services/reglementaires.service';

@Component({
  selector: 'app-list-reglementaires',
  templateUrl: './list-reglementaires.component.html',
  styleUrl: './list-reglementaires.component.css',
})
export class ListReglementairesComponent {
  pageIndex = 1;
  pageSize = 15;
  total_reglementaires: number = 0;
  client = 'Neoscope';
  isLoadingData = true;

  reglementaires: any[] = [];
  nzShowArrow = true;
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});

  constructor(
    private reglementairesService: ReglementairesService,
    private projetsService: ProjetsService,
    private message: NzMessageService,
    private fb: NonNullableFormBuilder,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    const stringControlNames = ['search_text'];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
    this.queryReglementaires();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.queryReglementaires();
  }

  queryReglementaires() {
    this.isLoadingData = true;
    console.log(this.queryForm.value);
    this.reglementairesService
      .getAllReglementairesFilter(
        this.pageIndex,
        this.pageSize,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        console.log(data);
        this.reglementaires = data.rows;
        this.total_reglementaires = data.count;
        this.isLoadingData = false;
      });
  }

  resetForm(): void {
    this.queryForm.reset();
    this.queryReglementaires();
  }

  async deleteReglementaire(numero: string) {
    this.isLoadingData = true;
    await this.reglementairesService.delete(numero);
    this.reglementaires = this.reglementaires.filter(
      (r) => r.numero !== numero
    );
    this.queryReglementaires();
  }

  cancel(): void {}

  majProjets(numero: string) {
    this.isLoadingData = true;
    this.projetsService
      .majProjetsReglementaire(numero)
      .subscribe((res: any) => {
        this.isLoadingData = false;
        this.message.success(
          "La mise à jour réglementaire est en cours d'éxécution. Vous serez informée par e-mail dès que le script sera terminé."
        );
      });
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  canAction(action_name: string) {
    return this.authService.canAction(action_name);
  }
}
