<div class="scroll-container">
  <h4>
    Fichiers
    <span>
    <nz-tag [nzColor]="'#d9d9d9'" class="circular-tag">
      {{ fileCount }}
    </nz-tag>
  </span>
  </h4>
  <div *ngFor="let message of messages">
    <div *ngIf="message.filename">
      <app-file-tag [fileName]="extractName(message.filename)" [fileType]="extractFileType(message.filename)" [fileUrl]="extractUrl(message.filename)"></app-file-tag>
    </div>
  </div>
</div>
