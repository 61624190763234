import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ObligesService } from 'src/app/services/obliges.service';

@Component({
  selector: 'app-details-oblige',
  templateUrl: './details-oblige.component.html',
  styleUrl: './details-oblige.component.css'
})
export class DetailsObligeComponent {
  oblige_id: number;
  oblige: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private message: NzMessageService,
    private readonly authService: AuthService,
    private obligeService: ObligesService,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadingService.upLoading();
    this.oblige_id = this.route.snapshot.params['id'];
    this.obligeService
      .getObligeById(this.oblige_id)
      .subscribe((data: any) => {
        // this.authService.canAccessClientData(data.projet.source);
        this.oblige = data;
        this.loadingService.offLoading();
      });
  }
  async confirmDelete() {
    await this.obligeService.delete(this.oblige_id);
    this.createDeleteMessage();
    this.router.navigate(['/obliges']);
  }
  createDeleteMessage(): void {
    this.message.success('La suppression a été effectuée avec succès.', {
      nzDuration: 3000,
    });
  }
  cancel() {}

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }
}
