import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {Router} from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd/message';

import {ProjetsService} from "../../../services/projets.service";

@Component({
  selector: 'app-edit-statut',
  templateUrl: './edit-statut.component.html',
  styleUrl: './edit-statut.component.css'
})
export class EditStatutComponent {
  editForm: FormGroup;
  isFormSubmitting: boolean = false;
  @Input() statut: string;
  @Input() prevIntitule: string;
  @Input() id: number;
  @Output() formStateChange = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private router: Router, private projetService: ProjetsService, private message: NzMessageService) {

  }

  ngOnInit(): void {
    console.log(this.id)
    this.editForm = this.fb.group({
      intitule: [this.prevIntitule]
    });
    this.editForm.valueChanges.subscribe(() => {
      this.emitFormState();
    });
  }

  onSubmit(id: number): void {
    const payload = this.editForm.value.intitule;
    const editFunction = this.statut === 'mpr' ? this.projetService.editStatutMPR(id, payload) : this.projetService.editStatut(id, payload);
    editFunction.subscribe(() => {
      this.isFormSubmitting= true;
      this.formStateChange.emit(false);
      this.createBasicMessage();
      this.onRetour().then(r => r);
    });
  }

  createBasicMessage(): void {
    this.message.success('Vos modifications ont été enregistrées avec succès.', {
      nzDuration: 3000,
    });
  }

  async onRetour(): Promise<void> {
    await this.router.navigate([`/statuts-${this.statut}`])
  }

  private emitFormState(): void {
    const hasChanges = this.checkFormChanges();
    this.formStateChange.emit(hasChanges);
  }
  
  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    return !this.editForm.pristine;
  }

}
