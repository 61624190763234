<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form
        nz-form
        [formGroup]="queryForm"
        nz-col
        [nzSpan]="21"
        nzSm="21"
        nzXs="24"
      >
        <nz-input-group
          nz-col
          [nzSpan]="24"
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Rechercher dans les utilisateurs ..."
            (keyup.enter)="queryUsers()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search" (click)="queryUsers()"></span>
      </ng-template>
      <div class="filter-container" nz-col [nzSpan]="3">
        <button
          nz-button
          nzType="default"
          class="filters-toogle"
          (click)="toggleFilters = !toggleFilters"
        >
          <span nz-icon nzType="filter"></span>
          Filtres
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || users.length === 0" />
    <div *ngIf="toggleFilters" class="filters-panel">
      <form nz-form [formGroup]="queryForm" class="ant-advanced-search-form">
        <div nz-row>
          <div nz-col nzSpan="20">
            <div nz-row class="filter-fields">
              <div nz-col [nzSpan]="4" *ngIf="canAction('FILTER_BY_CLIENT')">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="source"
                      nzPlaceHolder="Client"
                      formControlName="source"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        *ngFor="let client of clients"
                        [nzValue]="client.tag"
                        [nzLabel]="client.tag"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="societe"
                      nzPlaceHolder="Société"
                      formControlName="societe"
                      [nzShowArrow]="nzShowArrow"
                      nzMode="multiple"
                      nzAllowClear="true"
                      nzSize="small"
                      [nzDropdownMatchSelectWidth]="false"
                      [(ngModel)]="selected_societes"
                    >
                      <nz-option
                        *ngFor="let societe of societes"
                        [nzValue]="societe.id"
                        [nzLabel]="societe.name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="gestionnaire"
                      nzPlaceHolder="Chargé d'Affaires"
                      formControlName="gestionnaire"
                      [nzShowArrow]="nzShowArrow"
                      nzMode="multiple"
                      nzAllowClear="true"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                      [(ngModel)]="selected_gestionnaires"
                    >
                      <nz-option
                        *ngFor="let gestionnaire of gestionnaires"
                        [nzValue]="gestionnaire.id"
                        [nzLabel]="gestionnaire.last_name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="6">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="activation"
                      nzPlaceHolder="Activation compte"
                      formControlName="activation"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option nzValue="1" nzLabel="Actif"></nz-option>
                      <nz-option nzValue="0" nzLabel="En attente"></nz-option>
                      <nz-option nzValue="2" nzLabel="Refusé"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="4" nzJustify="center">
            <div nz-row nzJustify="center">
              <button
                nz-button
                nzBlock
                nzType="default"
                nzShape="round"
                nzSize="small"
                class="filter-button blue-grey-button-color"
                (click)="resetForm()"
              >
                Effacer
              </button>
            </div>
            <div nz-row nzJustify="center">
              <button
                nz-button
                nzBlock
                nzType="primary"
                nzShape="round"
                nzSize="small"
                class="filter-button"
                (click)="queryUsers()"
              >
                Appliquer
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <nz-table
      *ngIf="isDesktop"
      #fixedTable
      [nzData]="users"
      [nzFrontPagination]="false"
      [nzTotal]="total_users"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      nzPaginationPosition="both"
      [nzShowTotal]="total_users_template"
      [nzScroll]="{ x: '2250px' }"
      [nzLoading]="isLoadingData"
      class="societe-list-table"
      (nzQueryParams)="onQueryParamsChange($event)"
    >
      <ng-template #total_users_template>
        <button
          *ngIf="checked_users.size > 0 && canDelete('UTILISATEURS')"
          nz-button
          nz-popconfirm
          nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement tous les utilisateurs sélectionnés ?"
          nzType="default"
          class="secondary-button mass-action-button"
          (nzOnConfirm)="confirmDelete()"
          (nzOnCancel)="cancel()"
          [nzIcon]="popConfirmIcon"
        >
          <span nz-icon nzType="delete"></span>
          Supprimer
        </button>
        <button
          *ngIf="checked_users.size > 0"
          nz-button
          nzType="default"
          class="secondary-button mass-action-button"
          (click)="exporter()"
        >
          <span nz-icon nzType="download"></span>
          Exporter
        </button>
        <span
          >{{ total_users }}
          {{ total_users === 1 ? " utilisateur" : " utilisateurs" }}</span
        >
        <ng-template #popConfirmIcon> </ng-template>
      </ng-template>
      <thead>
        <tr>
          <th
            nzWidth="30px"
            [(nzChecked)]="checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th
            *ngFor="let column of listOfColumn"
            class="header-container"
            [nzWidth]="column.width"
          >
            <span class="table-header">{{ column.title }}</span>
          </th>
          <th nzRight nzWidth="60px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody class="table-container">
        <tr *ngFor="let user of fixedTable.data">
          <td
            [nzChecked]="checked_users.has(user.id)"
            (nzCheckedChange)="onItemChecked(user.id, $event)"
          ></td>
          <td>
            <a routerLink="/utilisateurs/{{ user.id }}"
              >{{ user.firstname }} {{ user.lastname }}</a
            >
          </td>
          <td>
            <a
              *ngIf="user.societe"
              routerLink="/societes/{{ user.societe.id }}"
              >{{ user.societe.name }}</a
            >
          </td>
          <td>{{ user.phone }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.source }}</td>
          <td>
            <a routerLink="/administrateurs/{{ user.gestionnaire?.id }}">{{
              user.gestionnaire?.last_name
            }}</a>
          </td>
          <td>
            {{ user.registrationDateTime | date : "dd/MM/YYYY HH:mm" }}
          </td>
          <td>
            <nz-tag
              [nzColor]="getStatus(user).color"
              [nzBordered]="false"
              class="status-tag"
            >
              <span nz-icon nzType="tag" nzTheme="outline"></span>
              <span>{{ getStatus(user).text }}</span>
            </nz-tag>
          </td>
          <td>{{ user.isManager ? "Oui" : "Non" }}</td>
          <td>{{ user.nombreSimulation }}</td>
          <td>{{ user.nombreOffreCeeEmises }}</td>
          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="action-buttons"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item routerLink="/utilisateurs/{{ user.id }}">
                  <a
                    ><span nz-icon nzType="eye" nzTheme="outline"></span>
                    Détails</a
                  >
                </li>
                <li
                  nz-menu-item
                  (click)="validerUser(user.id)"
                  *ngIf="
                    user.is_active <= 0 &&
                    user.societe &&
                    canAction('VALIDER_UTILISATEUR')
                  "
                >
                  <a
                    ><span
                      nz-icon
                      nzType="check-circle"
                      nzTheme="outline"
                    ></span>
                    Valider</a
                  >
                </li>
                <li
                  nz-menu-item
                  (click)="refuserUser(user.id)"
                  *ngIf="
                    user.is_active >= 0 && canAction('REFUSER_UTILISATEUR')
                  "
                >
                  <a
                    ><span nz-icon nzType="stop" nzTheme="outline"></span>
                    Refuser</a
                  >
                </li>
                <li
                  nz-menu-item
                  routerLink="/utilisateurs/edit/{{ user.id }}"
                  *ngIf="canEdit('UTILISATEURS')"
                >
                  <a
                    ><span nz-icon nzType="edit" nzTheme="outline"></span>
                    Éditer</a
                  >
                </li>
                <li nz-menu-item *ngIf="canDelete('UTILISATEURS')">
                  <a
                    nz-popconfirm
                    nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement cet utilisateur ?"
                    (nzOnConfirm)="deleteUser(user.id)"
                    (nzOnCancel)="cancel()"
                  >
                    <span nz-icon nzType="delete" nzTheme="outline"></span>
                    Supprimer
                  </a>
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div *ngIf="!isDesktop">
      <div *ngFor="let user of users">
        <nz-card class="nz-card" routerLink="/utilisateurs/{{ user.id }}">
          <div nz-row nzJustify="space-between" nzAlign="middle">
            <div nz-col [nzSpan]="20" class="title">
              {{ user.firstname }} {{ user.lastname }}
            </div>
            <div
              nz-col
              [nzSpan]="4"
              nz-icon
              nzType="setting"
              nzTheme="outline"
            ></div>
          </div>
          <div>
            <nz-tag
              [nzColor]="getStatus(user).color"
              [nzBordered]="false"
              class="status-tag"
            >
              <span nz-icon nzType="tag" nzTheme="outline"></span>
              <span>{{ getStatus(user).text }}</span>
            </nz-tag>
          </div>
          <div>
            <nz-tag nzColor="gold" [nzBordered]="false" class="status-tag">
              <span nz-icon nzType="bank" nzTheme="outline"></span>
              <span>{{ user.societe?.name }}</span>
            </nz-tag>
          </div>
          <div>
            <nz-tag nzColor="default" [nzBordered]="false" class="status-tag">
              <span nz-icon nzType="user" nzTheme="outline"></span>
              <span>{{
                user.gestionnaire
                  ? user.gestionnaire.first_name +
                    " " +
                    user.gestionnaire.last_name
                  : ""
              }}</span>
            </nz-tag>
          </div>
        </nz-card>
      </div>
    </div>
  </div>
</nz-content>
