import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminsService } from 'src/app/services/admins.service';
import { LoadingService } from 'src/app/services/loading.service';

import { DepartementService } from '../../../services/departement.service';

@Component({
  selector: 'app-edit-gestion-commerciale',
  templateUrl: './edit-gestion-commerciale.component.html',
  styleUrl: './edit-gestion-commerciale.component.css',
})
export class EditGestionCommercialeComponent implements OnInit {
  editForm: FormGroup;
  departement: any;
  departement_id: number;
  admins: any[];
  isFormSubmitting: boolean = false;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private message: NzMessageService,
    private route: ActivatedRoute,
    private departService: DepartementService,
    private readonly loadingService: LoadingService,
    private adminsService: AdminsService
  ) {
    this.editForm = fb.group({
      code_departement: ['', Validators.required],
      nom_departement: ['', Validators.required],
      responsable_residentiel_individuel: [''],
      responsable_residentiel_collectif: [''],
      responsable_tertiaire: [''],
      responsable_industriel: [''],
      responsable_transport: [''],
      responsable_agriculture: [''],
      responsable_reseaux: [''],
    });
  }

  ngOnInit() {
    this.loadingService.upLoading();
    this.departement_id = this.route.snapshot.params['id'];
    this.loadAdmins();
    this.loadDepartement();
  }

  editGestion() {
    if (this.editForm.valid) {
      const payload = {
        code: this.editForm.value.code_departement,
        nom: this.editForm.value.nom_departement,
        responsable_residentiel_individuel:
          this.editForm.value.responsable_residentiel_individuel,
        responsable_residentiel_collectif:
          this.editForm.value.responsable_residentiel_collectif,
        responsable_tertiaire: this.editForm.value.responsable_tertiaire,
        responsable_industrie: this.editForm.value.responsable_industriel,
        responsable_transport: this.editForm.value.responsable_transport,
        responsable_agriculture: this.editForm.value.responsable_agriculture,
        responsable_reseaux: this.editForm.value.responsable_reseaux,
      };
      this.departService
        .editDepartement(this.departement_id.toString(), payload)
        .subscribe(() => {
          this.isFormSubmitting = true;
          this.createBasicMessage();
          this.router.navigate(['/gestion-commerciale']);
        });
    }
  }

  createBasicMessage(): void {
    this.message.success(
      'Vos modifications ont été enregistrées avec succès.',
      {
        nzDuration: 3000,
      }
    );
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    return !this.editForm.pristine;
  }

  goBack() {
    this.router.navigate(['/gestion-commerciale']);
  }

  private loadAdmins() {
    this.adminsService
      .getAllAdmins(1, 100000, {})
      .subscribe((admins: any) => {
        this.admins = admins.rows;
      });
  }

  private loadDepartement() {
    this.departService
      .getDepartementById(this.departement_id)
      .subscribe((departement: any) => {
        this.departement = departement;
        this.editForm.patchValue({
          code_departement: departement.code,
          nom_departement: departement.nom,
          responsable_residentiel_individuel:
            departement.responsable_residentiel_individuel,
          responsable_residentiel_collectif:
            departement.responsable_residentiel_collectif,
          responsable_tertiaire: departement.responsable_tertiaire,
          responsable_industriel: departement.responsable_industrie,
          responsable_transport: departement.responsable_transport,
          responsable_agriculture: departement.responsable_agriculture,
          responsable_reseaux: departement.responsable_reseaux,
        });
        this.loadingService.offLoading();
      });
  }
}
