<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit></ng-template>
  <div class="form-container">
    <div class="title">Modification de l'obligé {{ oblige?.name }}</div>
    <form
      [formGroup]="editForm"
      class="form-layout"
      nz-form
    >
      <div class="row-content" nz-row>
        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Nom abrégé</nz-form-label>
            <nz-form-control [nzSpan]="14" nz-col
                             nzErrorTip="Ce champ est obligatoire et ne doit pas dépasser 10 caractères">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  [(ngModel)]="oblige.nom"
                  formControlName="nom"
                  nz-input
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired>Nom officiel</nz-form-label>
            <nz-form-control [nzSpan]="14" nz-col
                             nzErrorTip="Ce champ est obligatoire et ne doit pas dépasser 30 caractères">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  [(ngModel)]="oblige.nomOfficiel"
                  formControlName="nomOfficiel"
                  nz-input
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="custom-quill-container">
        <nz-form-item>
          <nz-form-label [nzSpan]="4" nz-col nzLabelWrap>Mentions légales</nz-form-label>
          <nz-form-control>
            <div [nzSpan]="23" class="quill-wrapper"
                 nz-col>
              <quill-editor
                [modules]="quillModules"
                formControlName="mentions"
                placeholder="Mentions légales ..."
              ></quill-editor>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            (click)="editOblige()"
            [disabled]="!checkFormChanges() || editForm.invalid"
            class="circle-button small save-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Sauvegarder"
            type="submit"
          >
          <span class="icon">
            <span nz-icon nzType="save"></span>
          </span>
          </button>
          <button
            (click)="goBack()"
            class="circle-button small cancel-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Annuler"
          >
          <span class="icon">
            <span nz-icon nzType="rollback"></span>
          </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
