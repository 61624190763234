import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from 'src/app/services/document.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';

@Component({
  selector: 'app-details-client',
  templateUrl: './details-client.component.html',
  styleUrl: './details-client.component.css',
})
export class DetailsClientComponent {
  client_id: any;
  client_neoscope: any;
  configuration_client: any;
  logo: any;
  favicon: any;
  icon_pwa: any;
  active_tab: string = 'CONFIG_NEOSCOPE';

  constructor(
    private route: ActivatedRoute,
    private neoscopeService: NeoscopeService,
    private documentsService: DocumentService,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadingService.upLoading();
    this.client_id = this.route.snapshot.params['id'];
    this.neoscopeService
      .getClientNeoscope(this.client_id)
      .subscribe((client_neoscope: any) => {
        this.client_neoscope = client_neoscope;
      });
    this.neoscopeService
      .getClientsNeoscopeConfiguration(this.client_id)
      .subscribe((configuration_client: any) => {
        this.configuration_client = configuration_client;
        this.loadingService.offLoading();
        this.documentsService
          .previewDocumentByName(configuration_client.logo, 'logo')
          .subscribe((res) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.logo = reader.result;
            };
            reader.readAsDataURL(res);
          });
        this.documentsService
          .previewDocumentByName(configuration_client.favicon, 'favicon')
          .subscribe((res: any) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.favicon = reader.result;
            };
            reader.readAsDataURL(res);
          });
        if (configuration_client.icon_pwa) {
          this.documentsService
            .previewDocumentByName(configuration_client.icon_pwa, 'icon_pwa')
            .subscribe((res: any) => {
              const reader = new FileReader();
              reader.onload = () => {
                this.icon_pwa = reader.result;
              };
              reader.readAsDataURL(res);
            });
        }
      });
  }

  getStatus(client: any) {
    if (client.is_active) {
      return {
        color: 'green',
        text: 'Actif',
      };
    } else {
      return {
        color: 'red',
        text: 'En attente',
      };
    }
  }
}
