import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

import { ProjetsService } from '../../../services/projets.service';
import { ColumnItem } from '../../../utils/columnItem.interface';

@Component({
  selector: 'app-list-statuts-mpr',
  templateUrl: './list-statuts-mpr.component.html',
  styleUrl: './list-statuts-mpr.component.css',
})
export class ListStatutsMprComponent {
  queryForm: FormGroup;
  statutsMpr: any[] = [];
  filteredStatutsMpr: any[] = [];
  isLoadingData: boolean = false;
  statutType: string = 'mpr';
  listOfColumns: ColumnItem[] = [
    {
      name: 'ID',
      sortOrder: null,
      sortFn: (a: any, b: any) => a.id - b.id,
      sortDirections: ['ascend', 'descend', null],
    },
    {
      name: 'Code Statut',
      sortOrder: null,
      sortFn: (a: any, b: any) => a.code.localeCompare(b.code),
      sortDirections: ['ascend', 'descend', null],
    },
    {
      name: 'Intitulé',
      sortOrder: null,
      sortFn: (a: any, b: any) => a.label.localeCompare(b.label),
      sortDirections: ['ascend', 'descend', null],
    },
  ];

  constructor(
    private fb: FormBuilder,
    private projetsService: ProjetsService,
    private readonly authService: AuthService
  ) {
    this.queryForm = this.fb.group({
      search_text: [''],
    });
  }

  ngOnInit(): void {
    this.loadAllStatuts();
    this.queryForm
      .get('search_text')
      ?.valueChanges.pipe(debounceTime(100))
      .subscribe((value) => {
        this.onSearchChange(value);
      });

    if (this.canEdit('STATUTS')) {
      this.listOfColumns.push({
        name: 'Actions',
        sortOrder: null,
        sortFn: null,
        sortDirections: [null],
      });
    }
  }

  loadAllStatuts() {
    this.isLoadingData = true;
    this.projetsService.getAllStatutsMPR().subscribe({
      next: (statutsMpr: any) => {
        this.statutsMpr = statutsMpr;
        this.filteredStatutsMpr = this.statutsMpr;
        this.isLoadingData = false;
      },
      error: (error) => {
        this.isLoadingData = false;
      },
    });
  }

  onSearchChange(value: string): void {
    this.filteredStatutsMpr = this.statutsMpr.filter((statut) =>
      statut.label.toLowerCase().includes(value.toLowerCase())
    );
  }

  queryStatus() {
    const searchText = this.queryForm.get('search_text')?.value.toLowerCase();
    this.filteredStatutsMpr = this.statutsMpr.filter((statut) =>
      statut.label.toLowerCase().includes(searchText)
    );
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }
}
