import {ColumnItem} from "../../../../utils/columnItem.interface";

export const listOfColumnsGestionCommerciale: ColumnItem[] = [
  {
    name: 'Code département',
    sortOrder: null,
    sortFn: (a: any, b: any) => a.code - b.code,
    sortDirections: ['ascend', 'descend', null]
  },
  {
    name: 'Nom département',
    sortOrder: null,
    sortFn: (a: any, b: any) => a.nom.localeCompare(b.nom),
    sortDirections: ['ascend', 'descend', null]
  },
  {
    name: 'Responsable résidentiel individuel',
    sortOrder: null,
    sortFn: (a: any, b: any) => a.responsable_residentiel_individuel.localeCompare(b.responsable_residentiel_individuel),
    sortDirections: ['ascend', 'descend', null]
  }, {
    name: 'Responsable résidentiel collectif',
    sortOrder: null,
    sortFn: (a: any, b: any) => a.responsable_residentiel_collectif.localeCompare(b.responsable_residentiel_collectif),
    sortDirections: ['ascend', 'descend', null]
  }, {
    name: 'Responsable tertiaire',
    sortOrder: null,
    sortFn: (a: any, b: any) => a.responsable_tertiaire.localeCompare(b.responsable_tertiaire),
    sortDirections: ['ascend', 'descend', null]
  }, {
    name: 'Responsable industriel',
    sortOrder: null,
    sortFn: (a: any, b: any) => a.responsable_industrie.localeCompare(b.responsable_industrie),
    sortDirections: ['ascend', 'descend', null]
  }, {
    name: 'Responsable transport',
    sortOrder: null,
    sortFn: (a: any, b: any) => a.responsable_transport.localeCompare(b.responsable_transport),
    sortDirections: ['ascend', 'descend', null]
  }, {
    name: 'Responsable agriculture',
    sortOrder: null,
    sortFn: (a: any, b: any) => a.responsable_agriculture.localeCompare(b.responsable_agriculture),
    sortDirections: ['ascend', 'descend', null]
  }, {
    name: 'Responsable réseaux',
    sortOrder: null,
    sortFn: (a: any, b: any) => a.responsable_reseaux.localeCompare(b.responsable_reseaux),
    sortDirections: ['ascend', 'descend', null]
  },
];
