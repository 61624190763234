import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PrixService } from 'src/app/services/prix.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { SocietesService } from 'src/app/services/societes.service';

@Component({
  selector: 'app-details-prix',
  templateUrl: './details-prix.component.html',
  styleUrl: './details-prix.component.css',
})
export class DetailsPrixComponent {
  categorie_code: any;
  categorie_prix: any;
  totale_societe: number;
  constructor(
    private route: ActivatedRoute,
    private prixService: PrixService,
    private societeService: SocietesService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    protected readonly sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.loadingService.upLoading();
    this.categorie_code = this.route.snapshot.params['code'];
    this.prixService
      .getCategorieByCode(this.categorie_code)
      .subscribe((categorie_prix: any) => {
        this.categorie_prix = categorie_prix;
        this.loadingService.offLoading();
      });
    this.societeService
      .getAllSocietesByCategoriePrix(this.categorie_code)
      .subscribe((res: any) => {
        this.totale_societe = res.count;
      });
  }

  ngAfterViewInit() {
    const tranchePrixComponent = document.querySelector('#tranche-prix');
    const width: number = tranchePrixComponent
      ? tranchePrixComponent.getBoundingClientRect().width
      : 0;

    const tranchePrixDescriptions = document.getElementsByClassName(
      'ant-steps-item-description'
    );
    for (let i = 0; i < Array.from(tranchePrixDescriptions).length - 1; i++) {
      const htmlElement = Array.from(tranchePrixDescriptions)[i] as HTMLElement;
      htmlElement.style.transition = 'all .2s';
      htmlElement.style.minWidth = width + 'px';
      htmlElement.style.textAlign = 'center';
    }
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }
}
