<div class="scroll-container">
  <h4>
    Membres de l'équipe
    <span>
    <nz-tag [nzColor]="'#d9d9d9'" class="circular-tag">
        4
    </nz-tag>
  </span>
  </h4>
  <div class="list">
    <div *ngFor="let item of listData" class="list-item">
      <nz-avatar nzIcon="user" style="background-color:#87d068;"></nz-avatar>
      <div class="item-content">
        <p>{{ item.firstName + " " + item.lastName }}</p>
      </div>
    </div>
  </div>
</div>
