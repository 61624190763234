import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";
import {switchMap} from "rxjs";

export const guestGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router)
  return authService.isAuthenticated().pipe(
    switchMap(isLoggedIn => {
      if (isLoggedIn) {
        return router.navigate(['/']);
      }
      return [true];
    })
  );
};
