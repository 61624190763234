import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ObligesService {
  constructor(private http: HttpClient) {}

  getAllObliges() {
    return this.http.get(`${environment.apiUrl}/obliges/all`);
  }

  getAllObligesFilter(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/obliges/page=${page}&limit=${limit}`,
      query
    );
  }

  getObligeById(oblige_id: number) {
    return this.http.get(`${environment.apiUrl}/obliges/${oblige_id}`);
  }

  editOblige(oblige_id: number, payload: any) {
    return this.http.put(`${environment.apiUrl}/obliges/${oblige_id}`, payload);
  }

  createOblige(payload: any) {
    return this.http.post(`${environment.apiUrl}/obliges`, payload);
  }

  delete(oblige_id: number) {
    return firstValueFrom(
      this.http.delete(`${environment.apiUrl}/obliges/${oblige_id}`)
    );
  }
}
